import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getAuthenticated, getStaticContent, login, startSystemOutage } from '@inmarsat-itcloudservices/ui';
import { Store } from '@ngrx/store';
import path from 'ramda/es/path';
import { Observable, throwError } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { ROUTES, staticContent } from '@app/app.constants';
import { IState } from '@app/shared-store';

function isInvalidApiKey(error: HttpErrorResponse) {
  const fault = path(['error', 'fault', 'faultstring'], error);
  return fault === 'Invalid ApiKey';
}

function isPlannedOutage(error: HttpErrorResponse) {
  try {
    if (error.statusText === 'Service Temporarily Unavailable') {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
}

@Injectable({
  providedIn: 'root'
})
export class HttpRequestInteceptorError implements HttpInterceptor {
  public isAuthenticated$: Observable<boolean>;

  constructor(private readonly store: Store<IState>, private readonly router: Router) {
    this.isAuthenticated$ = this.store.select(getAuthenticated);
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((error) => this.handleError(error)));
  }

  public handleError(error: HttpErrorResponse): Observable<never> {
    return this.isAuthenticated$.pipe(
      first(),
      switchMap((isAuthenticated) => {
        if (isAuthenticated) {
          if (error.status === 401 && isInvalidApiKey(error)) {
            /**
             * In some cases it is because they are not configured
             * with access to the DUE application in this environment.
             *
             * Here, we detect these cases by examining the response body, and, if so, redirect them to
             * an error page instead.
             */
            void this.router.navigate([ROUTES.ERROR], {
              queryParams: {
                code: 'api_unavailable',
                description: getStaticContent('errors.invalid_api_key', staticContent)
              }
            });
          } else if (error.status === 403) {
            void this.router.navigate([ROUTES.ERROR], {
              queryParams: {
                code: 'not_authorized',
                description: getStaticContent('errors.not_authorized', staticContent)
              }
            });
          }
        } else if (error.status === 401) {
          this.store.dispatch(login({ redirectURl: environment.okta.postLogoutRedirectUri }));
        } else {
          void this.router.navigate([ROUTES.ERROR], {
            queryParams: {
              code: 'api_unavailable',
              description: getStaticContent('errors.api_unavailable', staticContent)
            }
          });
        }

        this.checkForOutage(error);
        return throwError(error);
      })
    );
  }

  public checkForOutage(error: HttpErrorResponse): void {
    if (error.status === 503 && isPlannedOutage(error)) {
      this.store.dispatch(startSystemOutage());
    }
  }
}
