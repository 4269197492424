import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  addNotificationItem,
  concatSpinner,
  createLoadTask,
  createSuccessNotification,
  getStaticContent,
  withSpinner
} from '@inmarsat-itcloudservices/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin, from, merge } from 'rxjs';
import { concatMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AddressApiService } from '@app/api/address/address-api.service';
import { CLEApiService } from '@app/api/cle/cle-api.service';
import { ContactApiService } from '@app/api/contact/contact-api.service';
import { MODAL_CONFIGURATION, ROUTES, staticContent } from '@app/app.constants';
import { AddReassignCorporateGroupModalComponent } from '@app/customers/customer-legal-entity/components/add-reassign-corporate-group-modal/add-reassign-corporate-group-modal.component';
import * as ChangeRequestActions from '@app/shared-store/change-request/change-request.actions';
import { IChangeRequestActions } from '@app/shared/models/change-request.model';
import * as StepperActions from '../stepper/stepper.action';
import * as CLEActions from './cle.actions';

@Injectable()
export class CLEsEffects {
  public loadCLEs$ = createLoadTask(
    ({ query }) => {
      return this.cleApi.getCLEs(query);
    },
    {
      actions: this.actions$,
      ofType: CLEActions.load,
      onSuccess: CLEActions.loadSuccess
    }
  );

  public loadCorporateGroup$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(CLEActions.cleDetailLoad),
      concatSpinner(({ legalEntityCode, parentCode }: any) => {
        const apiCalls: any = [this.cleApi.getCLE(legalEntityCode)];

        if (!(parentCode === undefined || parentCode === null)) {
          apiCalls.push(this.cleApi.getAssociatedCorporateGroup(parentCode));
        }
        // If url contains parentId, joincall to get corporate group and CLE in parallel
        // If url doesn't contain parentId, get parentCode from CLE call, then get corporate group info
        return merge(
          withSpinner(
            CLEActions.cleDetailLoad.type,
            forkJoin(apiCalls).pipe(
              concatMap((apiCallsResponse: any[]) => {
                if ((parentCode === undefined || parentCode === null) && apiCallsResponse[0]?.parentCode) {
                  return this.cleApi.getAssociatedCorporateGroup(apiCallsResponse[0]?.parentCode).pipe(
                    mergeMap((corporateResponse) => {
                      return [CLEActions.cleDetailLoadSuccess(apiCallsResponse[0], corporateResponse)];
                    })
                  );
                } else {
                  return [CLEActions.cleDetailLoadSuccess(apiCallsResponse[0], apiCallsResponse[1])];
                }
              })
            )
          )
        );
      })
    )
  );

  public createCleCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CLEActions.createCleCompany),
      concatSpinner((action) =>
        this.cleApi
          .createCleCompany(action.companyDetails)
          .pipe(
            mergeMap((legalEntityCode) => [
              CLEActions.createCleCompanySuccess(legalEntityCode),
              StepperActions.cleCreateIncrement()
            ])
          )
      )
    )
  );

  public addCleAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CLEActions.addCleAddress),
      concatSpinner((action) =>
        this.cleApi
          .addCleAddress(action.legalEntityCode, action.addressDetails)
          .pipe(
            mergeMap((mainAddressId) => [
              CLEActions.addCleAddressSuccess(mainAddressId),
              StepperActions.cleCreateIncrement()
            ])
          )
      )
    )
  );

  public addCleContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CLEActions.addCleContact),
      concatSpinner((action) =>
        this.cleApi
          .addCleContact(action.cleDetails, action.contactDetails)
          .pipe(
            concatMap((cleDetails) => [
              CLEActions.addCleContactSuccess(cleDetails.mainContactId),
              CLEActions.createCle(cleDetails)
            ])
          )
      )
    )
  );

  public createCle$ = createLoadTask(({ cleDetails }) => this.cleApi.createCle(cleDetails), {
    actions: this.actions$,
    ofType: CLEActions.createCle,
    onSuccess: CLEActions.createCleSuccess
  });

  public createCleSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CLEActions.createCleSuccess),
      switchMap(({ legalEntityCode }) =>
        from(this.router.navigate(['/', ROUTES.CUSTOMERS, ROUTES.CLE, legalEntityCode]))
      ),
      map(() =>
        addNotificationItem(createSuccessNotification(getStaticContent(`customers.cle.create.success`, staticContent)))
      )
    )
  );

  public updateCLEDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CLEActions.updateCLEDetails),
      switchMap((action) => {
        const apiCalls = [];
        let showChangeRequestMessage = false;
        let changeRequestPayload = null;
        if (action.cleDetails.deletedMediums) {
          action.cleDetails.deletedMediums.forEach((medium) => {
            apiCalls.push(this.contactApi.deleteMedium(medium?.id, medium?.mediumType));
          });
          delete action.cleDetails.deletedMediums;
        }

        if (action.cleDetails.changeRequest) {
          showChangeRequestMessage = true;
          changeRequestPayload = action.cleDetails.changeRequest;
          delete action.cleDetails.changeRequest;
        }

        if (Object.keys(action.cleDetails).length > 2) {
          apiCalls.push(this.cleApi.updateCLE(action.cleDetails));
        }

        // Payload contains name and CR status is pending approval
        // Payload contains name and CR status is send back

        const actions = !showChangeRequestMessage
          ? [
              CLEActions.cleDetailLoad(action.cleDetails.legalEntityCode, action.cleDetails.parentCode),
              addNotificationItem(
                createSuccessNotification(
                  getStaticContent('customers.cle.edit.notification.edit_cle_success', staticContent)
                )
              )
            ]
          : changeRequestPayload.action === IChangeRequestActions.RESUBMIT
          ? [
              ChangeRequestActions.sendResponseOfChangeRequest(changeRequestPayload),
              CLEActions.cleDetailLoad(action.cleDetails?.legalEntityCode, action.cleDetails?.parentCode)
            ]
          : [
              ChangeRequestActions.createChangeRequest(changeRequestPayload),
              CLEActions.cleDetailLoad(changeRequestPayload.entityId, action?.cleDetails?.parentCode)
            ];

        if (apiCalls.length === 0) {
          return actions;
        }

        return forkJoin(apiCalls).pipe(switchMap(() => actions));
      })
    )
  );

  public updateCLE$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CLEActions.updateCLE),
      switchMap(({ payload }) => {
        const apiCalls = [];
        let isNewAddress = false;
        let isNewContact = false;
        if (payload.address) {
          isNewAddress = payload.address.id === null;
          const address = payload.address;
          apiCalls.push(this.addressApi.updateAddress(payload.legalEntityCode, address));
        }

        if (payload.contact) {
          isNewContact = payload.contact.id === null;
          const contact = payload.contact;
          apiCalls.push(this.contactApi.updateContact(payload.legalEntityCode, contact));
        }

        delete payload.address;
        delete payload.contact;

        if (apiCalls.length === 0) {
          return [CLEActions.updateCLEDetails(payload, 'customers.cle.details.address_success')];
        }
        return merge(
          withSpinner(
            CLEActions.updateCLE.type,
            forkJoin(apiCalls).pipe(
              switchMap((apiCallsResponse: any[]) => {
                let cleDetails: any = payload;
                apiCallsResponse.forEach((response) => {
                  if (response && response.addressId && isNewAddress) {
                    cleDetails = {
                      ...cleDetails,
                      mainAddress: response.addressId
                    };
                  }
                  if (response && response.contactId && isNewContact) {
                    cleDetails = {
                      ...cleDetails,
                      mainContact: response.contactId
                    };
                  }
                });
                return [CLEActions.updateCLEDetails(cleDetails, 'customers.cle.details.contact_success')];
              })
            )
          )
        );
      })
    )
  );

  public openAddReassignCorporateGroupModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CLEActions.openAddReassignCorporateGroupModal),
        tap((addReassignCorporateGroup) => {
          return this.modalService.show(AddReassignCorporateGroupModalComponent, {
            ...MODAL_CONFIGURATION,
            initialState: { addReassignCorporateGroup }
          });
        })
      ),
    { dispatch: false }
  );

  public assignCorporateGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CLEActions.assignCorporateGroup),
      concatSpinner((action) =>
        this.cleApi
          .assignCorporateGroup(action.corporateGroupDetails)
          .pipe(
            switchMap(() => [
              addNotificationItem(
                createSuccessNotification(
                  getStaticContent(`customers.cle.modal.notification.assign_cle_success`, staticContent)
                )
              ),
              CLEActions.cleDetailLoad(
                action.corporateGroupDetails.legalEntityCode,
                action.corporateGroupDetails.parentCode
              ),
              CLEActions.assignCorporateGroupSuccess()
            ])
          )
      )
    )
  );

  public assignCorporateGroupSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CLEActions.assignCorporateGroupSuccess),
        tap(() => {
          return this.modalService.hide();
        })
      ),
    { dispatch: false }
  );

  public loadCleRelatedAccountList$ = createLoadTask(({ query }) => this.cleApi.getCLEAccountList(query), {
    actions: this.actions$,
    ofType: CLEActions.loadCleRelatedAccountList,
    onSuccess: CLEActions.loadCleRelatedAccountListSuccess
  });

  public loadCleRelatedSiteList$ = createLoadTask(({ query }) => this.cleApi.getCLESiteList(query), {
    actions: this.actions$,
    ofType: CLEActions.loadCleRelatedSiteList,
    onSuccess: CLEActions.loadCleRelatedSiteListSuccess
  });

  public prohibitCLE$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CLEActions.prohibitCLE),
      concatSpinner(({ legalEntityCode, isProhibited }) =>
        this.cleApi.prohibitCle(legalEntityCode, isProhibited).pipe(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          mergeMap((_) => [
            addNotificationItem(
              createSuccessNotification(
                getStaticContent('customers.cle.notification.compliance_updated', staticContent)
              )
            ),
            CLEActions.cleDetailLoad(legalEntityCode, undefined)
          ])
        )
      )
    )
  );

  public syncCustomerLegalEntity$ = createLoadTask(
    ({ legalEntityCode }) => this.cleApi.syncCustomerLegalEntity(legalEntityCode),
    {
      actions: this.actions$,
      ofType: CLEActions.syncCustomerLegalEntity,
      onSuccess: () =>
        addNotificationItem(
          createSuccessNotification(getStaticContent('customers.cle.edit.notification.sync_cle_success', staticContent))
        )
    }
  );

  public voidCLE$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CLEActions.voidCLE),
      concatSpinner(({ legalEntityCode }) =>
        this.cleApi.voidCLE(legalEntityCode).pipe(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          mergeMap((_) => [
            addNotificationItem(
              createSuccessNotification(
                getStaticContent('customers.cle.edit.notification.void_cle_success', staticContent)
              )
            ),
            CLEActions.cleDetailLoad(legalEntityCode, undefined)
          ])
        )
      )
    )
  );

  public deleteCle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CLEActions.deleteCLE),
      concatSpinner(({ cleCode, parentCode }) =>
        this.cleApi.deleteCLE(cleCode).pipe(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          mergeMap((_) => [
            addNotificationItem(
              createSuccessNotification(getStaticContent('customers.cle.edit.notification.deactive_cle', staticContent))
            ),
            CLEActions.cleDetailLoad(cleCode, parentCode)
          ])
        )
      )
    )
  );

  public loadContractManagmentByCLE$ = createLoadTask(
    ({ query, CLECode }) => this.cleApi.getContractManagementByCLE(query, CLECode),
    {
      actions: this.actions$,
      ofType: CLEActions.loadContractManagmentByCLE,
      onSuccess: CLEActions.loadContractManagmentByCLESuccess
    }
  );

  constructor(
    private readonly actions$: Actions,
    protected modalService: BsModalService,
    private readonly cleApi: CLEApiService,
    private readonly contactApi: ContactApiService,
    private readonly addressApi: AddressApiService,
    private readonly router: Router
  ) {}
}
