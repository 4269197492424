import { IBoundedQuery, IPagedResponse } from '@inmarsat-itcloudservices/ui';
import { createAction, props } from '@ngrx/store';

import { IOutboundFeed } from '@app/shared/models/outbound-feed.model';
import {
  IAccountDealerPayload,
  IAccountDetails,
  IAccountPartnerInfo,
  IAccountQuery,
  IAddAccountPayload,
  IAddSalesTeamMemberPayload,
  IMoveAccountPayload,
  IMoveAccountResponse,
  IMoveBPAccountPayload,
  ISalesOrg,
  IUpdateCloneAccountPayload,
  IShippingDetails
} from '@shared/models/account.model';
import { IAddress } from '@shared/models/address.model';
import { ICLEBaseDetails } from '@shared/models/cle.model';
import { IContact } from '@shared/models/contact.model';
import { IPricingAgreement } from '@shared/models/pricing-agreement.model';
import { ISiteConfig, ISiteDetail } from '@shared/models/site.model';
import { IOutboundFeedPayload, IOutboundFeedConfig } from 'src/app/shared/models/outbound-feed.model';
import { IChangeRequestQuery, IChangeRequest } from '@app/shared/models/change-request.model';

export const reset = createAction('[Account] Reset');

export const loadAllAccount = createAction('[Account] Load all account', (query: IAccountQuery, load = 'All') => ({
  query,
  load
}));

export const loadAllAccountSuccess = createAction(
  '[Account] Load all account success',
  (response: IPagedResponse<IAccountDetails, IAccountQuery>) => ({ response })
);

export const loadPostingAccount = createAction(
  '[Account] Load Posting account',
  (query: IAccountQuery, load = 'posting') => ({ query, load })
);

export const loadPostingAccountSuccess = createAction(
  '[Account] Load posting account success',
  (response: IPagedResponse<IAccountDetails, IAccountQuery>) => ({ response })
);

export const accountNotFound = createAction('[Account] Not found');

export const detailLoad = createAction('[Account] Detail load', (accountNumber: string, legalEntityCode: string) => ({
  accountNumber,
  legalEntityCode
}));

export const detailLoadSuccess = createAction(
  '[Account] Detail load success',
  (response: IAccountDetails, cleDetails: ICLEBaseDetails) => ({
    response,
    cleDetails
  })
);

export const addressLoad = createAction('[Account] Address load', (addressId: string) => ({
  addressId
}));

export const addressLoadSuccess = createAction('[Account] Address load success', (response: IAddress) => ({
  response
}));

export const editAccountDetails = createAction('[Account] Edit account', (editPanelName: string) => ({
  editPanelName
}));

export const cancelEditAccountDetails = createAction('[Account] Cancel edit account');

export const cancelCopyAccountDetails = createAction('[Account] Cancel clone account');

export const updateAccountDetails = createAction(
  '[Account] Update account details',
  (
    updatedAccountDetails: IAccountDetails,
    isStatusUpdate = false,
    needAddOBFsMessage = false,
    ChangeRequest = null
  ) => ({
    updatedAccountDetails,
    isStatusUpdate,
    needAddOBFsMessage,
    ChangeRequest
  })
);

export const updateAccountDetailsSuccess = createAction('[Account] Update account details success');

export const childAccountsLoad = createAction(
  '[Account] Child accounts load',
  (query: IAccountQuery, parentAccountNumber: string) => ({
    query,
    parentAccountNumber
  })
);

export const childAccountsLoadSuccess = createAction(
  '[Account] Child accounts load success',
  (response: IPagedResponse<IAccountDetails>) => ({
    response
  })
);

export const loadSiblingAccounts = createAction(
  '[Account] Load sibling accounts',
  (query: IAccountQuery, postingAccountNumber: string) => ({
    query,
    postingAccountNumber
  })
);

export const loadSiblingAccountsSuccess = createAction(
  '[Account] Load sibling account',
  (response: IPagedResponse<IAccountDetails>) => ({
    response
  })
);

export const accountBillingContactLoad = createAction('[Account] Contact load', (contactId: string) => ({
  contactId
}));

export const accountBillingContactLoadSuccess = createAction(
  '[Account] Contact load success',
  (response: IContact) => ({
    response
  })
);

export const loadPartnerByAccount = createAction('[Account] load account partner ', (accountCode: string) => ({
  accountCode
}));

export const loadPartnerByAccountSuccess = createAction(
  '[Account] load account partner by account success',
  (response: IAccountPartnerInfo) => ({
    response
  })
);

export const loadSiteDetailsByAccount = createAction('[Account] load account site ', (sapId: string) => ({
  sapId
}));

export const loadSiteDetailsByAccountSuccess = createAction(
  '[Account] load site details by account success',
  (response: IPagedResponse<ISiteDetail>) => ({
    response
  })
);

export const loadPricingLinksByAccount = createAction(
  '[Account] Load pricing links',
  (query: IBoundedQuery, accountNumber: string) => ({
    query,
    accountNumber
  })
);

export const loadPricingLinksByAccountSuccess = createAction(
  '[Account] Load pricing links success',
  (response: IPagedResponse<IPricingAgreement>) => ({
    response
  })
);

export const createAccount = createAction(
  '[Account] Create account',
  (payload: IAddAccountPayload, salesOrgPayload?: any) => ({
    payload,
    salesOrgPayload
  })
);

export const createAccountSuccess = createAction('[Account] Create account success', (response: string) => ({
  response
}));

export const associateSalesOrganisation = createAction(
  '[Account] Associate Sales Organisation',
  (payload: ISalesOrg[], legalEntityCode: string, accountNumber: string) => ({
    payload,
    legalEntityCode,
    accountNumber
  })
);

export const createAccountAddress = createAction(
  '[Account] Create address',
  (address: IAddress, legalEntityCode: string, accountContainsValidOBFs = false) => ({
    address,
    legalEntityCode,
    accountContainsValidOBFs
  })
);

export const createAccountAddressSuccess = createAction(
  '[Account] Create address success',
  (addressId: string, addressType: string) => ({
    addressId,
    addressType
  })
);

export const createAccountContact = createAction(
  '[Account] Create contact',
  (contact: IContact, legalEntityCode: string, accountContainsValidOBFs = false) => ({
    contact,
    legalEntityCode,
    accountContainsValidOBFs
  })
);

export const createAccountContactSuccess = createAction(
  '[Account] Create contact success',
  (contactId: string, contactType: string) => ({
    contactId,
    contactType
  })
);

export const updateCreatedAccountDetails = createAction(
  '[Account] Update create account',
  (accountDetails: IAccountDetails | IUpdateCloneAccountPayload) => ({
    accountDetails
  })
);

export const navigateToDetailsPageAfterupdatedAccountDetails = createAction(
  '[Account] Update account details and go to final review page',
  (accountDetails: IAccountDetails) => ({
    accountDetails
  })
);

export const copyBillingAddressAndContact = createAction('[Account] Copy billing address and contact to shipping');

export const assignSalesTeamMember = createAction(
  '[Account] Assign sales team',
  (payload: IAddSalesTeamMemberPayload) => ({
    payload
  })
);

export const assignSalesTeamMemberSuccess = createAction('[Account] Assign sales team success');

export const openAssignAccountDealersModal = createAction('[Account] Open assign account dealers modal');

export const loadDealerList = createAction('[Account] Dealers load', (search: string) => ({
  search
}));

export const loadDealerListSuccess = createAction(
  '[Account] Dealers load success',
  (response: IPagedResponse<IAccountDetails>) => ({
    response
  })
);

export const assignAccountDealer = createAction(
  '[Account] Assign account dealer',
  (payload: IAccountDealerPayload) => ({
    payload
  })
);

export const assignAccountDealerSuccess = createAction(
  '[Account] Assign account dealer success',
  (payload: IAccountDealerPayload) => ({
    payload
  })
);

export const initiateChildAccountCreation = createAction(
  '[Account] Initiate child account creation',
  (childAccountGroup: string, parentAccount: any) => ({
    childAccountGroup,
    parentAccount
  })
);

export const goToAccountDetails = createAction('[Account] Go to account details');

export const openAdditionalAccountInfoModal = createAction('[Account] Open additional account info modal');

export const loadContractPartnerInfo = createAction(
  '[Account] Contract Partner Information load',
  (search?: string) => ({
    search
  })
);

export const loadContractPartnerInfoSuccess = createAction(
  '[Account]  Contract Partner Information load success',
  (response) => ({
    response
  })
);

export const loadManagementCompanyInfo = createAction(
  '[Account] Management Company Information load',
  (search?: string) => ({
    search
  })
);

export const loadManagementCompanyInfoSuccess = createAction(
  '[Account]  Management Company Information load success',
  (response) => ({
    response
  })
);

export const loadShipOwnerList = createAction('[Account] Ship owner list load', (search: string) => ({
  search
}));

export const loadShipOwnerListSuccess = createAction(
  '[Account] Ship owner list load success',
  (response: IPagedResponse<IAccountDetails>) => ({
    response
  })
);

export const saveAdditionalInformation = createAction('[Account] Save additional information', (payload) => ({
  payload
}));

export const saveAdditionalInformationSuccess = createAction('[Account] Save additional information success');

export const createSiteForAccount = createAction(
  '[Account] Create site account',
  (payload: ISiteConfig, legalEntityCode: string, accountContainsValidOBFs = false) => ({
    payload,
    legalEntityCode,
    accountContainsValidOBFs
  })
);

export const createSiteForAccountSuccess = createAction('[Account] Create site For account success', (response) => ({
  response
}));

export const openDistributionPartnerDialog = createAction(
  '[Account] Open distribution partner dialog ',
  (payload, salesOrgForm?, salesTeamMember?) => ({
    payload,
    salesOrgForm,
    salesTeamMember
  })
);

export const updateAccountGroup = createAction('[Account] Update Account Group ', (payload) => ({
  payload
}));

export const updateCLEPersona = createAction(
  '[Account] Update cle persona',
  (baseDetails: ICLEBaseDetails, accountNumber: string) => ({
    baseDetails,
    accountNumber
  })
);

export const updateCLEPersonaSuccess = createAction('[Account] Update cle persona sucess');

export const updateAccountAddressAndContact = createAction(
  '[Account] Update account address and contact',
  (accountDetails, needAddOBFsMessage = false) => ({
    accountDetails,
    needAddOBFsMessage
  })
);

export const editAccountSalesOrg = createAction('[Account] Edit sales org', (parentAccount: IAccountDetails) => ({
  parentAccount
}));

export const resetCreateAccount = createAction('[Account] Create account reset');

export const resetParentAccount = createAction('[Account] Parent account reset');

export const resetCurrentAccount = createAction('[Account] Current account reset');

export const loadParentAccount = createAction('[Account] Load parent account', (parentAccountNumber: string) => ({
  parentAccountNumber
}));

export const loadParentAccountSuccess = createAction(
  '[Account] Load parent account success',
  (parentAccount: IAccountDetails) => ({
    parentAccount
  })
);

export const updateParentAndSiblingAccount = createAction(
  '[Account] Update parent and sibling account',
  (payload: boolean) => ({
    payload
  })
);

export const openChildAccountMoveModal = createAction('[Account] Open child account move modal');

export const loadParentAccounts = createAction('[Account] Load parent accounts', (query: any) => ({
  query
}));

export const loadParentAccountsSuccess = createAction(
  '[Account] Load parent accounts success',
  (response: IPagedResponse<IAccountDetails>) => ({
    response
  })
);

export const loadParentAccountsUnderSameCLE = createAction(
  '[Account] Load parent accounts under same cle',
  (query: any) => ({
    query
  })
);

export const loadParentAccountsUnderSameCLESuccess = createAction(
  '[Account] Load parent accounts under same CLE success',
  (response: IPagedResponse<IAccountDetails>) => ({
    response
  })
);

export const loadActiveChildAccounts = createAction(
  '[Account] Load active child accounts',
  (query: IAccountQuery, parentAccountNumber: string) => ({
    query,
    parentAccountNumber
  })
);

export const loadActiveChildAccountSuccess = createAction(
  '[Account] Load active child accounts success',
  (response: IPagedResponse<IAccountDetails>) => ({
    response
  })
);

export const deleteAccount = createAction('[Account] Delete account');

export const deleteAccountSuccess = createAction('[Account] Delete account success');

export const resetAccountMoveDetails = createAction('[Account] Reset posting account move details');

export const movePostingAccount = createAction(
  '[Account] Move Posting account',
  (payload: IMoveAccountPayload, dealerPayload?: any, newObfs?: IOutboundFeedPayload, inheritance = false) => ({
    payload,
    dealerPayload,
    newObfs,
    inheritance
  })
);

export const movePostingAccountSuccess = createAction(
  '[Account] Move posting account success',
  (response: IMoveAccountResponse) => ({
    response
  })
);

export const moveBPAccount = createAction(
  '[Account] Move BP Account',
  (
    payload: IMoveBPAccountPayload,
    newOBFs: IOutboundFeedPayload,
    inheritancePayload: IOutboundFeedConfig,
    partnerPayload?: IAccountDetails
  ) => ({
    payload,
    newOBFs,
    inheritancePayload,
    partnerPayload
  })
);

export const moveBPAccountSuccess = createAction('[Account] Move BP Account success', (response: any) => ({
  response
}));

export const openPostingAccountMoveModal = createAction('[Account] Open posting account move modal');

export const prohibitAccount = createAction(
  '[Account] Prohibit account',
  (accountNumber: string, isProhibited: boolean) => ({
    accountNumber,
    isProhibited
  })
);

export const prohibitAccountSuccess = createAction('[Account] Prohibit account success');

export const syncAccount = createAction('[Account] Sync', (accountNumber: string) => ({
  accountNumber
}));

export const syncAccountSuccess = createAction('[Account] Sync success]');

export const voidAccount = createAction('[Account] void', (accountNumber: string) => ({
  accountNumber
}));

export const voidAccountSuccess = createAction('[Account] void success]');

export const finalReviewAccount = createAction('[Account] Final Review account');

export const navigateToDetailsPageAfterupdatedAccountDetailsWithoutFinalReview = createAction(
  '[Account] Update account details and go to Details page',
  (accountDetails: IAccountDetails) => ({
    accountDetails
  })
);

export const cloneAccount = createAction('[Account] Clone account', (createPrimaryINMB?: boolean) => ({
  createPrimaryINMB
}));

export const cloneAccountInfo = createAction(
  '[Account] Clone account info',
  (payload: IAccountDetails, salesOrgPayload: any, contactAndAddressPayload?: any, siteInfo?: ISiteConfig) => ({
    payload,
    salesOrgPayload,
    contactAndAddressPayload,
    siteInfo
  })
);

export const cloneAccountInfoSuccess = createAction('[Account] Clone account success', (response: string) => ({
  response
}));

export const goToChildAccountCreation = createAction('[Account] Clone child account');

export const loadSiteContact = createAction(
  '[Account] Load associated site contact',
  (associatedSiteContactId: string) => ({
    associatedSiteContactId
  })
);

export const loadSiteContactSuccess = createAction(
  '[Account] Load associated site contact success',
  (contactResponse: IContact) => ({
    contactResponse
  })
);
export const loadSiteAddress = createAction('[Site] Load Site Address', (addressId: string) => ({
  addressId
}));

export const loadSiteAddressSuccess = createAction('[Site] Load Site Address success', (response: IAddress) => ({
  response
}));

export const creteSiteWithContactAndAddress = createAction(
  '[Account] Create site contact and address',
  (payload: ISiteConfig, accountNumber: string, legalEntityCode: string, contactAndAddress?: any) => ({
    payload,
    accountNumber,
    legalEntityCode,
    contactAndAddress
  })
);

export const creteSiteWithContactAndAddressSuccess = createAction(
  '[Account] Create site contact and address success',
  (response) => ({
    response
  })
);

// INMA must have INMB, when user clones INMA, portal need ask user to create primary INMB
export const cloneINMBAccount = createAction(
  '[Account]  Copy primary INMB account',
  (query: IAccountQuery, parentAccountNumber: string) => ({
    query,
    parentAccountNumber
  })
);

export const cloneINMBAccountSuccess = createAction(
  '[Account] Copy primary INMB account success',
  (response: IPagedResponse<IAccountDetails>) => ({
    response
  })
);

// Paste primary INMB account into current account details to create a new INMB
export const pastePrimaryINMBAAccount = createAction(
  '[Account]  Paste primary INMB account',
  (parentAccountInfo: any) => ({
    parentAccountInfo
  })
);

// Paste primary INMB account into current account details to create a new INMB
export const pastePrimaryINMBAAccountSuccess = createAction(
  '[Account]  Paste primary INMB account',
  (contact: any, address: any) => ({
    contact,
    address
  })
);

export const createOBFDuringAccountCreation = createAction(
  '[Account] Create Outbound Feed during account creation',
  props<{ obfs: IOutboundFeed[]; inheritancePayload: IOutboundFeedConfig; fullOBFs: IOutboundFeed[] }>()
);

export const createOBFDuringAccountCreationSuccess = createAction(
  '[Account] Create Outbound Feed during account creation success',
  (response: any) => ({
    response
  })
);

export const pullARAccountOBFS = createAction('[Account] Pull AR account OBFS', (query: any) => ({
  query
}));

export const pullARAccountOBFSSuccess = createAction(
  '[Account] Pull AR account OBFS success',
  (response: IPagedResponse<IOutboundFeed>) => ({
    response
  })
);

export const resetARAccountOBFs = createAction('[Account] Reset AR account OBFS success');

export const updateOBFDuringAccountCreation = createAction(
  '[Account] Update OutboundFeed during account creation',
  (updatedOBF: IOutboundFeed) => ({
    updatedOBF
  })
);

export const updateOBFDuringAccountCreationSuccess = createAction(
  '[Account] Update OutboundFeed during account creation success'
);

export const loadApprovedMovedToCLEOrAccount = createAction(
  '[Account] Pull MovedTo account or CLE',
  (query: IChangeRequestQuery) => ({
    query
  })
);

export const loadApprovedMovedToCLEOrAccountSuccess = createAction(
  '[Account] Pull MovedTo account or CLE success',
  (response: IPagedResponse<IChangeRequest>) => ({
    response
  })
);

export const loadShippingAddressAndContactById = createAction(
  '[Account] Load shipping address and contact by Id',
  (accountCode: string, shippingId: string, addressId: string | null, contactId: string | null) => ({
    accountCode,
    shippingId,
    addressId,
    contactId
  })
);

export const loadShippingAddressAndContactByIdSuccess = createAction(
  '[Account] Load shipping address and contact by Id success',
  (accountCode: string, shippingInfoId: string, shippingAddress: IAddress, shippingContact: IContact) => ({
    accountCode,
    shippingInfoId,
    shippingAddress,
    shippingContact
  })
);

export const deleteShippingAddressAndContact = createAction(
  '[Account] Delete shipping address and contact',
  (shippingInfo: IShippingDetails, accountNumber: string, CLENumber: string) => ({
    shippingInfo,
    accountNumber,
    CLENumber
  })
);

//Create shipping address and post call to create shipping info
export const createShippingAddress = createAction(
  '[Account] create shipping address',
  (payload: IAddress, accountNumber: string, cleNumber: string) => ({
    payload,
    accountNumber,
    cleNumber
  })
);
export const createShippingAddressSuccess = createAction('[Account] create shipping address success');

//Create shipping address and patch call to update shipping info
export const createShippingContact = createAction(
  '[Account] create shipping contact',
  (payload: IContact, shippingInfoId: IShippingDetails, accountNumber: string, cleNumber: string) => ({
    payload,
    shippingInfoId,
    accountNumber,
    cleNumber
  })
);

// Create shipping info - Shipping address/contact during account creation
export const createShippingInfo = createAction(
  '[Account] create shipping info',
  (payload: IShippingDetails, accountCode: string, cleNumber: string, updateAccountStepper = false) => ({
    payload,
    accountCode,
    cleNumber,
    updateAccountStepper
  })
);

export const openCreateShippingAddressModal = createAction('[Account] Create shipping address modal');

export const editShippingInfo = createAction('[Account] Edit shipping info');

export const editShippingInfoSuccess = createAction(
  '[Account] Edit shipping info success',
  (accountNumber: string, shippingInfoId: any) => ({
    accountNumber,
    shippingInfoId
  })
);
export const cancelEditShippingInfo = createAction('[Account] cencel edit shipping info');

export const updateShippingAddressAndContact = createAction(
  '[Account] Update shipping address and contact',
  (shippingInfo, accountNumber, legalEntityCode, shippingId) => ({
    shippingInfo,
    accountNumber,
    legalEntityCode,
    shippingId
  })
);

export const updateShippingInfo = createAction(
  '[Account] Update shipping Info',
  (updateShippingInfopayload: IShippingDetails, accountNumber: string, cleNumber: string) => ({
    updateShippingInfopayload,
    accountNumber,
    cleNumber
  })
);

export const resetSendChangeRequestOnINMAOrINMB = createAction('[Account] reset send change request on INMA or INMB');

export const sendChangeRequestOnINMB = createAction(
  '[Account] send change request on INMB',
  (payload: IAccountQuery, accountNumber: string) => ({
    payload,
    accountNumber
  })
);

export const sendChangeRequestOnINMA = createAction(
  '[Account] send change request on INMA',
  (payload: IChangeRequestQuery, INMAInfo: IAccountDetails) => ({
    payload,
    INMAInfo
  })
);

export const sendChangeRequestOnINMAOrINMBSuccess = createAction(
  '[Account] send change request on INMA or INMB Success',
  (sendRequest: any, accountInfo: IAccountDetails) => ({
    sendRequest,
    accountInfo
  })
);
