import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MinimalActivatedRouteSnapshot, MinimalRouterStateSnapshot } from '@ngrx/router-store';

export class CustomRouterStateSerializer {
  public serialize(routerState: RouterStateSnapshot): MinimalRouterStateSnapshot {
    return {
      root: this.serializeRoute(routerState.root),
      url: routerState.url
    };
  }

  private clean(snapshot: MinimalActivatedRouteSnapshot) {
    if (snapshot.data && snapshot.data.defaultParams) {
      snapshot.data.defaultParams = null;
    }

    snapshot.children.forEach((c) => this.clean(c));
  }

  private serializeRoute(route: ActivatedRouteSnapshot): MinimalActivatedRouteSnapshot {
    const children = route.children.map((c) => this.serializeRoute(c));
    return {
      children,
      params: route.params,
      data: {
        ...route.data,
        defaultParams: null
      },
      url: route.url,
      outlet: route.outlet,
      routeConfig: route.routeConfig
        ? {
            path: route.routeConfig.path,
            pathMatch: route.routeConfig.pathMatch,
            redirectTo: route.routeConfig.redirectTo,
            outlet: route.routeConfig.outlet
          }
        : null,
      queryParams: route.queryParams,
      fragment: route.fragment,
      firstChild: children[0]
    };
  }
}
