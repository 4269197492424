import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService, ErrorPageComponent } from '@inmarsat-itcloudservices/ui';
import { OktaAuthGuard } from '@okta/okta-angular';
import { ROUTES } from './app.constants';
import { LayoutComponent } from './core/components/layout/layout.component';

const routes: Routes = [
  {
    path: ROUTES.ERROR,
    component: ErrorPageComponent,
    data: {
      title: 'errors.title'
    }
  },
  {
    path: '',
    component: LayoutComponent,
    canActivateChild: [OktaAuthGuard, AuthGuardService],
    children: [
      // APP ROUTES
      {
        path: ROUTES.ROOT,
        loadChildren: () => import('./welcome/welcome.module').then((m) => m.WelcomeModule)
      },
      {
        path: ROUTES.CUSTOMERS,
        loadChildren: () => import('./customers/customer.module').then((m) => m.CustomerModule)
      },
      {
        path: ROUTES.PROFILE,
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule)
      },
      {
        path: ROUTES.SALES,
        loadChildren: () => import('./sales/sales.module').then((m) => m.SalesModule)
      },
      {
        path: ROUTES.ACCOUNTS,
        loadChildren: () => import('./account/account.module').then((m) => m.AccountModule)
      },
      {
        path: ROUTES.SITES,
        loadChildren: () => import('./site/site.module').then((m) => m.SiteModule)
      },
      {
        path: ROUTES.TERMINAL_GROUPS,
        loadChildren: () => import('./terminal-groups/terminal-groups.module').then((m) => m.TerminalGroupsModule)
      },
      {
        path: ROUTES.REFERENCE_DATA,
        loadChildren: () => import('./reference-data/reference-data.module').then((m) => m.ReferenceDataModule)
      },
      {
        path: ROUTES.BULK_UPDATE,
        loadChildren: () => import('./bulk-update/bulk-update.module').then((m) => m.BulkUpdateModule)
      },
      {
        path: ROUTES.OUTBOUND_FEED,
        loadChildren: () => import('./outbound-feed/outbound-feed.module').then((m) => m.OutboundFeedModule)
      },
      {
        path: ROUTES.HIERARCHY,
        loadChildren: () => import('./hierarchy-tree/hierarchy-tree.module').then((m) => m.HierarchyTreeModule)
      },
      {
        path: ROUTES.CHANGE_REQUEST,
        loadChildren: () => import('./change-request/change-request.module').then((m) => m.ChangeRequestModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: `/${ROUTES.NOT_FOUND}`
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'corrected',
      scrollPositionRestoration: 'top'
    })
  ],
  declarations: [],
  exports: [RouterModule]
})
export class AppRoutingModule {}
