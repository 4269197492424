import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getStaticContent, startSystemOutage } from '@inmarsat-itcloudservices/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { ROUTES, staticContent } from '@app/app.constants';

@Injectable()
export class SystemStatusEffects {
  public startSystemOutage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(startSystemOutage),
        tap(() => {
          void this.router.navigate([ROUTES.ERROR], {
            queryParams: {
              code: 'api_unavailable_due_to_maintenance',
              description: getStaticContent('errors.503', staticContent)
            }
          });
        })
      ),
    { dispatch: false }
  );

  constructor(private readonly actions$: Actions, private readonly router: Router) {}
}
