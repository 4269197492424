<ng-container [ngSwitch]="type">
  <inm-icon
    *ngSwitchCase="changeRequestStatus.PENDING_APPROVAL"
    class="icon align-icon"
    name="{{ iconNames.Clock }}"
    color="{{ iconColors.Orange }}"
  >
  </inm-icon>
  <inm-icon
    *ngSwitchCase="changeRequestStatus.REJECTED"
    class="icon align-icon"
    name="{{ iconNames.CloseCircled }}"
    color="{{ iconColors.Red }}"
  ></inm-icon>
  <inm-icon
    *ngSwitchCase="changeRequestStatus.SEND_BACK"
    class="icon align-icon"
    name="{{ iconNames.Edit }}"
    color="{{ iconColors.MidBlue }}"
  ></inm-icon>
  <inm-icon
    *ngSwitchCase="changeRequestStatus.APPROVED"
    class="icon align-icon"
    name="{{ iconNames.TickFilled }}"
    color="{{ iconColors.DarkGreen }}"
  ></inm-icon>
  <span class="ml-1">{{ type }}</span>
</ng-container>
