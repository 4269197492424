import { IEnvironment } from './evironment-types.js';
import { oktaBase } from './okta.base';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const version = require('../../package.json').version;

export const environment: IEnvironment = {
  production: true,
  name: 'stage',
  VERSION: version,
  translationPrefix: './assets/i18n/',
  STATIC_CONTENT_URL: './assets/i18n/en.json',
  page: '_offset',
  search: '_search',
  api: {
    cmd_url: 'https://management-customermaster-identity-stage.integration-nonprod.inmarsat.global/',
    refdata_url: 'https://management-legal-entity-identity-stage.integration-nonprod.inmarsat.global/',
    user_url: 'https://inmarsat-nonprod-intg.apigee.net/v1/userManagement',
    corporatedata_url: 'https://content-reference-corporate-stage.integration-nonprod.inmarsat.global',
    sales_url: 'https://customer-salespersonmaster-relationship-stage.integration-nonprod.inmarsat.global',
    terminaldata_url: 'https://customer-terminalgroupmaster-relationship-stage.integration-nonprod.inmarsat.global',
    pricingAgreement_url:
      'https://management-pricingagreementlink-relationship-stage.integration-nonprod.inmarsat.global',
    bulkUpload_url: 'https://management-bulkupdates-relationship-stage.integration-nonprod.inmarsat.global',
    attachment_url: 'https://management-attachmentmaster-relationship-stage.integration-nonprod.inmarsat.global',
    outboundFeed_url: 'https://invoice-outboundfeed-master-billing-stage.integration-nonprod.inmarsat.global',
    hierarchyTree_url: 'https://customer-hierarchy-retriever-relationship-stage.integration-nonprod.inmarsat.global',
    changeRequest_url: 'https://customer-requestmanager-relationship-stage.integration-nonprod.inmarsat.global',
    contract_url: 'https://inmarsat-nonprod-intg.apigee.net/v1/contractManagement/contract'
  },
  okta: {
    ...oktaBase,
    clientId: '0oa1octy24B8fRB2g0x7',
    issuer: 'https://login-stage.inmarsat.com/oauth2/ausm8g8wZzBpBdp0N0x5',
    redirectUri: 'https://cmd.stage.inmarsat.global/implicit/callback',
    postLogoutRedirectUri: 'https://cmd.stage.inmarsat.global/',
    pkce: true,
    scopes: ['openid']
  },
  mock_data: null
};
