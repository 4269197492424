import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  getStaticContent,
  STATIC_CONTENT_CONTEXT,
  STATIC_CONTENT_PAYLOAD,
  IReferenceOption,
  IconName,
  IconColor,
  getOBFFeedTypeOptions
} from '@inmarsat-itcloudservices/ui';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { DEFAULT_PAGE_SIZE, staticContent } from '@app/app.constants';
import { IState } from '@app/shared-store';
import * as AccountActions from '@shared-store/account/account.actions';
import {
  getAccountDetails,
  getDealer,
  getMoveAccountDetails,
  getMoveToCLEOrAccountDetails
} from '@shared-store/account/account.selectors';
import * as CLEActions from '@shared-store/cle/cle.actions';
import { getCLEPage } from '@shared-store/cle/cle.selectors';
import { AccountGroup, IAccountDetails, IMoveAccountPayload, IMoveAccountResponse } from '@shared/models/account.model';
import { ICLEQuery } from '@shared/models/cle.model';
import { MandatoryOutboundFeed } from '@app/shared/models/outbound-feed.model';
import {
  setupOutboundFeedPayload,
  combineOutboundFeedWithSameFeedFormatAndType
} from '../../../outbound-feed/outbound-feed-helpers';
import { AccountAddOutboundFeedStepComponent } from '../account-add-outbound-feed-step/account-add-outbound-feed-step.component';
import { getChildAccounts } from '../../../shared-store/account/account.selectors';

const TOOLIP_MESSAGE = getStaticContent('accounts.create.obf.tooltip_message', staticContent);

@Component({
  selector: 'inm-move-posting-account-modal',
  templateUrl: './move-posting-account-modal.component.html',
  styleUrls: ['./move-posting-account-modal.component.scss'],
  providers: [
    {
      provide: STATIC_CONTENT_CONTEXT,
      useValue: 'accounts.details.posting_account_move'
    },
    {
      provide: STATIC_CONTENT_PAYLOAD,
      useValue: staticContent
    }
  ]
})
export class MovePostingAccountModalComponent implements OnInit, OnDestroy {
  public moveAccountForm: FormGroup;

  @ViewChild('outboundFeedForm')
  public outboundFeedForm: AccountAddOutboundFeedStepComponent;

  public legalEntityList: {
    loaded: boolean;
    items: any[];
  };

  public expandedPanel = {
    accountMoveFrom: false,
    outboundFeedForm: false
  };

  public feedType: IReferenceOption[];

  public accountGroup = AccountGroup;

  public dealerList: any;

  public accountDetails: IAccountDetails;

  public moveAccount$: Observable<IMoveAccountResponse>;

  public readonly newLegalEntityCtrl = new FormControl(null, [Validators.required]);

  public readonly dealerCtrl = new FormControl(null);

  private readonly subs = new Subscription();

  public hasBPAccount = false;

  public inheritance = true;

  public readonly iconNames = IconName;

  public readonly iconColors = IconColor;

  public allowedTypesNewOutboundFeeds;

  public showErrorMessage = false;

  public associatedChangeRequestParams;

  public approvedCLE = {
    hasApprovedCLE: false,
    approvedCLECode: null
  };

  constructor(public bsModalRef: BsModalRef, private readonly store: Store<IState>) {
    this.moveAccountForm = new FormGroup({
      newLegalEntity: this.newLegalEntityCtrl,
      dealer: this.dealerCtrl
    });
    this.store.dispatch(AccountActions.resetAccountMoveDetails());
  }

  public ngOnInit(): void {
    this.moveAccount$ = this.store.select(getMoveAccountDetails);

    this.subscribeToAccountDetails();
    this.subscribeToCleList();
    this.getFeedTypeList();
    this.getChildAccount();
    this.getApprovedMovedTo();

    this.subs.add(
      this.store.select(getDealer).subscribe((dealerList) => {
        if (dealerList?.items) {
          let updatedDealerList;
          if (dealerList.items.length > 0) {
            updatedDealerList = {
              ...dealerList,
              items: dealerList.items.map((value) => {
                return {
                  ...value,
                  accountDetail: `${value.accountNumber}, ${value.name}`
                };
              })
            };
          }
          this.dealerList = updatedDealerList;
        }
        if (
          this.accountDetails.accountGroup === AccountGroup.INMA &&
          this.dealerList &&
          this.dealerList.items &&
          this.accountDetails &&
          this.accountDetails?.dealerCode
        ) {
          this.patchDefualtDealerCode();
        }
      })
    );
  }

  public searchLegalEntityDetails(search: string): void {
    const query: ICLEQuery = {
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
      legalEntityCode: search,
      legalName: search,
      multiSearch: true,
      active: true
    };
    this.store.dispatch(CLEActions.load(query));
  }

  public confirm(): void {
    if (
      this.moveAccountForm.valid &&
      (this.outboundFeedForm?.form?.valid || this.accountDetails.accountGroup === AccountGroup.INDE)
    ) {
      const payload: IMoveAccountPayload = {
        accountNumber: this.accountDetails.accountNumber,
        currentLegalEntityCode: this.accountDetails.legalEntityCode,
        newLegalEntityCode: this.newLegalEntityCtrl.value.legalEntityCode
      };
      let dealerPayload: any;
      if (
        this.accountDetails.accountGroup === AccountGroup.INMA &&
        this.dealerCtrl.value &&
        this.accountDetails?.dealerCode !== this.dealerCtrl.value.accountNumber
      ) {
        dealerPayload = {
          dealerCode: this.dealerCtrl.value ? this.dealerCtrl.value.accountNumber : null,
          accountNumber: this.accountDetails.accountNumber
        };
      }
      this.patchMovePostingAccountAction(payload, dealerPayload);
    } else {
      this.moveAccountForm.markAllAsTouched();
      this.outboundFeedForm.form.markAllAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  public patchMovePostingAccountAction(payload: IMoveAccountPayload, dealerPayload: any): void {
    let obfPayload = null;
    if (this.accountDetails.accountGroup !== AccountGroup.INDE) {
      obfPayload = setupOutboundFeedPayload(
        this.outboundFeedForm.form.getRawValue().obfs,
        this.feedType,
        this.accountDetails.accountNumber,
        this.accountDetails.name,
        this.accountDetails.accountNumber,
        this.newLegalEntityCtrl.value.legalEntityCode
      );
      this.allowedTypesNewOutboundFeeds = combineOutboundFeedWithSameFeedFormatAndType(obfPayload);
      if (this.toFindDuplicateOutboundFeed().length) {
        this.showErrorMessage = true;
      } else {
        this.showErrorMessage = false;
        this.store.dispatch(AccountActions.movePostingAccount(payload, dealerPayload, obfPayload, this.inheritance));
      }
    } else {
      this.showErrorMessage = false;
      this.store.dispatch(AccountActions.movePostingAccount(payload, dealerPayload, obfPayload, this.inheritance));
    }
  }

  public getSuccessMessage(moveAccountDetails: IMoveAccountResponse): void {
    let successMessage = getStaticContent(
      'accounts.details.posting_account_move.move_account_success',
      staticContent
    ).replace('{childAccountsMoved}', moveAccountDetails.childAccountsMoved);
    this.accountDetails.accountGroup === AccountGroup.INM7 ||
    this.accountDetails.accountGroup === AccountGroup.IM15 ||
    this.accountDetails.accountGroup === AccountGroup.IM16
      ? (successMessage = successMessage.replace('{sitesMoved}', moveAccountDetails.sitesMoved))
      : (successMessage = successMessage.replace(', {sitesMoved} site(s)', ''));
    return successMessage;
  }

  public searchDealer(search: string): void {
    this.store.dispatch(AccountActions.loadDealerList(search));
  }

  private subscribeToCleList(): void {
    this.subs.add(
      this.store.select(getCLEPage).subscribe((legalEntityList) => {
        const cleList = legalEntityList.items;
        if (cleList.length) {
          this.legalEntityList = {
            loaded: true,
            items:
              cleList.length > 0
                ? cleList.map((value) => {
                    return {
                      ...value,
                      cleDetail: `${value.legalEntityCode}, ${value.details.legalName}`
                    };
                  })
                : []
          };
        }

        if (this.approvedCLE.hasApprovedCLE && this.legalEntityList?.items.length > 0) {
          // Patch approval CLE, user can't change it to other CLE. Credit team needs to know the account moved to a new CLE.
          this.patchNewCLEValue();
        }
      })
    );
  }

  private subscribeToAccountDetails(): void {
    this.subs.add(
      this.store.select(getAccountDetails).subscribe((accountDetails) => {
        if (accountDetails) {
          this.accountDetails = accountDetails;

          this.newLegalEntityCtrl.setValidators([
            Validators.required,
            this.invalidPostingAccountSelection(this.accountDetails.legalEntityCode)
          ]);
          if (accountDetails.accountGroup === AccountGroup.INMA) {
            this.dealerCtrl.setValidators([Validators.required]);
          }
          this.accountDetails.dealerCode
            ? this.searchDealer(this.accountDetails.dealerCode)
            : this.searchDealer(undefined);
        }
      })
    );
  }

  private invalidPostingAccountSelection(legalEntityCode: string): ValidatorFn {
    return (control: FormControl) => {
      let validation = null;
      if (control.value) {
        const cleCode = control.value.legalEntityCode;
        if (cleCode === legalEntityCode) {
          validation = {
            sameFolderSelected: legalEntityCode
          };
        }
      }
      return validation;
    };
  }

  private patchDefualtDealerCode() {
    this.moveAccountForm.patchValue({
      dealer: this.dealerList?.items[0]
    });
  }

  private patchNewCLEValue() {
    this.moveAccountForm.patchValue({
      newLegalEntity: this.legalEntityList?.items[0]
    });
  }

  public expandPanelAction(row: any): void {
    this.expandedPanel[row] = !this.expandedPanel[row];
  }

  public getTooltipText(): string {
    if (this.outboundFeedForm?.form?.valid && !this.checkMandatoryOutboundFeedsValue()) {
      return TOOLIP_MESSAGE;
    }
  }

  // Account must have credit note and invoice OBFs.
  public checkMandatoryOutboundFeedsValue(): boolean {
    return (
      this.accountDetails.accountGroup === AccountGroup.INDE ||
      (this.outboundFeedForm?.form
        .getRawValue()
        .obfs.some((vendor) => vendor['feedFormat'] === MandatoryOutboundFeed.INVOICE) &&
        this.outboundFeedForm?.form
          .getRawValue()
          .obfs.some((vendor) => vendor['feedFormat'] === MandatoryOutboundFeed.CREDIT_NOTE))
    );
  }

  public getFeedTypeList(): void {
    this.subs.add(
      this.store.select(getOBFFeedTypeOptions).subscribe((feedTypeList) => {
        if (feedTypeList) {
          this.feedType = feedTypeList;
        }
      })
    );
  }

  // Inheritance function: Enable copy_obf_to_bp checkbox to allow setup inheritance if AR contains BP.
  public getChildAccount(): any {
    this.subs.add(
      this.store.select(getChildAccounts).subscribe((result) => {
        if (result.totalCount === 1) {
          // needs to check if the record is AR or BP.
          result.items[0].accountNumber === this.accountDetails.accountNumber
            ? (this.hasBPAccount = false)
            : (this.hasBPAccount = true);
        } else {
          result.totalCount ? (this.hasBPAccount = true) : (this.hasBPAccount = false);
        }
      })
    );
  }

  public inheritanceToAllBPAccount(): void {
    this.inheritance = !this.inheritance;
  }

  public toFindDuplicateOutboundFeed(): any[] {
    let duplicates = [];
    Object.keys(this.allowedTypesNewOutboundFeeds).forEach((key) => {
      if (this.allowedTypesNewOutboundFeeds[key].length > 1) {
        const combineDuplicateOutboundFeed = (
          Array.from(new Set(this.allowedTypesNewOutboundFeeds[key].map(JSON.stringify))) as any
        ).map(JSON.parse);

        duplicates =
          !duplicates?.length && combineDuplicateOutboundFeed.length === this.allowedTypesNewOutboundFeeds[key].length
            ? []
            : combineDuplicateOutboundFeed;
      }
    });
    return duplicates;
  }

  public getApprovedMovedTo(): void {
    this.subs.add(
      this.store.select(getMoveToCLEOrAccountDetails).subscribe((approvedMovedTo) => {
        if (approvedMovedTo) {
          if (approvedMovedTo.count) {
            this.approvedCLE.hasApprovedCLE = true;
            this.approvedCLE.approvedCLECode = approvedMovedTo?.assets[0]?.movedTo;
            this.searchLegalEntityDetails(this.approvedCLE.approvedCLECode);
          } else {
            this.approvedCLE.hasApprovedCLE = false;
            const query = {
              offset: 0,
              limit: DEFAULT_PAGE_SIZE,
              active: true
            };
            this.store.dispatch(CLEActions.load(query));
          }
        }
      })
    );
  }
}
