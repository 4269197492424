import { IPagingQueryParams, ISortQuery } from '@inmarsat-itcloudservices/ui';
import { IAddress } from './address.model';
import { IContact } from './contact.model';
import { IGeneralDetails, IIdRef, IValidFor } from './refs';

export interface ISiteQuery extends ISortQuery, IPagingQueryParams {
  siteId?: string;
  name?: string;
  code?: number;
  type?: string;
  sapId?: string | number;
  status?: SiteStatus;
  legalEntityCode?: string;
  legalEntityName?: string;
  sortBy?: string;
}

export enum SiteCategory {
  Air = 'Air',
  Land = 'Land',
  Sea = 'Sea'
}

export interface ISiteBase {
  type: SiteCategory;
  name?: string;
  code?: string;
  emergencyContact?: string;
  emergencyAddress?: string;
  shippingDetails?: IAddress;
  billingContact?: string;
  contacts?: string[];
  address?: string;
  contactDetails?: IContact;
  otherContactDetails?: IContact;
  addressDetails?: IAddress;
  status?: SiteStatus;
  legalEntityCode?: string;
}

export type ISiteDetail = ISiteBase & ISiteTypes;

export type ISiteTypes = IAircraftDetails & ISeaDetails & ILandDetails;

export interface IAircraftDetails {
  tailNumber?: string;
  icaoNumber?: string;
  yearOfManufacture?: string;
  airlineName?: string;
  aircraftModel?: string;
  aircraftClass?: string;
  country?: string;
  countryLabel?: string;
}

export interface ISeaDetails {
  systemSiteIds?: {
    sap?: string;
  };
  yearOfManufacture?: string;
  imoNumber?: string;
  mmsi?: string;
  callSign?: string;
  grossTonnage?: number;
  homePort?: string;
  portOfRegistry?: string;
  personsOnBoard?: number;
  seaGoing?: string;
  selfPropelled?: string;
  vesselMarket?: string;
  vesselSector?: string;
  country?: string;
  countryLabel?: string;
  subType?: string;
}

export interface ILandDetails {
  country?: string;
  countryLabel?: string;
  landCategory?: string;
}

export enum SiteSearchOptions {
  siteName = 'Name',
  siteType = 'Type',
  siteCode = 'Code',
  siteSAPID = 'SAP ID '
}

export enum SiteStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Draft = 'Draft'
}

export interface ISiteDetails {
  name: string;
  code: number;
  type: string;
  status: string;
  distributionChannel: string;
  systemSiteIds: ISystemSiteIds;
}

export interface ISystemSiteIds {
  sap: number;
}

export interface ISiteList {
  loaded: boolean;
  items: ISiteDetails[];
  totalCount: number;
  query?: ISiteQuery;
  currentPage?: number;
}

export enum ContactMediumType {
  WorkEmail = 'Work Email',
  PersonalEmail = 'Personal Email',
  Fax = 'Fax',
  SMTP = 'SMTP',
  WorkPhone = 'Work Phone',
  HomePhone = 'Home Phone',
  MobilePhone = 'Mobile Phone',
  WebAddress = 'Web Address'
}

export interface ISiteAddress {
  id: string;
  line1: string;
  line2: string;
  line3: string;
  city: string;
  country: string;
  postalCode: string;
}

export interface ISiteContact {
  id: string;
  name: string;
  surname: string;
  medium: { type: ContactMediumType; value: string }[];
}

export interface ISiteCustomer {
  id: string;
  name: string;
  contact: ISiteContact[];
  address: ISiteAddress[];
  primaryContactId: string;
  primaryAddressId: string;
}

interface ISiteTypeBase {
  id: string;
  name: string;
  serviceCount: string;
  hasActiveSuspendedService: boolean;
  folderId: string;
  folderName: string;
  readonly status: SiteStatus;
  customerSiteRef?: string;
}

export interface ISiteSummaryBase extends ISiteTypeBase {
  customerId: string;
  addressId: string;
  contact: IIdRef[];
  validFor: IValidFor;
}

export interface ISiteDetailsBase extends ISiteTypeBase {
  contact: ISiteContact[];
  address: ISiteAddress[];
  emergencyAddressId: string;
  emergencyContactId: string;
  customer: ISiteCustomer;
  generalDetails: IGeneralDetails;
}

export interface ISiteConfigBase {
  code: string;
  name?: string;
  folderId?: string;
  validFor?: IValidFor;
  contacts?: IIdRef[];
  addressId?: string;
  emergencyContactId?: string;
  emergencyAddressId?: string;
  readonly status?: SiteStatus;
  customerSiteRef?: string;
  type?: SiteCategory;
  distributionChannel: string;
  systemSiteIds?: ISystemSiteIds;
}

interface IAirSite {
  category: SiteCategory.Air;
  categoryDetails: {
    aircraft: IAircraftDetails;
  };
}

export type IAirSiteDetails = ISiteDetailsBase & IAirSite;
export type IAirSiteSummary = ISiteSummaryBase & IAirSite;
export type IAirSiteConfig = ISiteConfigBase & IAirSite;

interface ISeaSite {
  category: SiteCategory.Sea;
  categoryDetails: {
    vessel: ISeaDetails;
  };
}

export type ISeaSiteDetails = ISiteDetailsBase & ISeaSite;
export type ISeaSiteSummary = ISiteSummaryBase & ISeaSite;
export type ISeaSiteConfig = ISiteConfigBase & ISeaSite;

interface ILandSite {
  category: SiteCategory.Land;
  categoryDetails: {
    land: ILandDetails;
  };
}

export type ILandSiteDetails = ISiteDetailsBase & ILandSite;
export type ILandSiteSummary = ISiteSummaryBase & ILandSite;
export type ILandSiteConfig = ISiteConfigBase & ILandSite;

export type ISiteTypesDetails = IAirSiteDetails | ISeaSiteDetails | ILandSiteDetails;
export type ISiteSummary = IAirSiteSummary | ISeaSiteSummary | ILandSiteSummary;
export type ISiteConfig = IAirSiteConfig | ISeaSiteConfig | ILandSiteConfig;

export enum EditSitePanel {
  SITE_DETAILS = 'Edit',
  ADDRESS = 'Address',
  CONTACT = 'Contact'
}
