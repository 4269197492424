import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { addNotificationItem, isErrorNotification } from '@inmarsat-itcloudservices/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

@Injectable()
export class NotificationsEffects {
  public redirectAfterError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addNotificationItem),
      map(({ notification }) => notification),
      filter(isErrorNotification),
      switchMap((notification) => {
        if (notification.redirect) {
          // We need to re-dispatch the notification after redirecting, as all notifications are cleared on nav.
          return from(this.router.navigate(notification.redirect)).pipe(
            map(() =>
              addNotificationItem({
                ...notification,
                redirect: null
              })
            )
          );
        }

        return EMPTY;
      })
    )
  );

  constructor(private readonly actions$: Actions, private readonly router: Router) {}
}
