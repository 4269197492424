import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  addNotificationItem,
  concatSpinner,
  ConfirmType,
  createLoadTask,
  createSuccessNotification,
  DialogService,
  getStaticContent,
  setupErrorNotification,
  withSpinner,
  createWarningNotification
} from '@inmarsat-itcloudservices/ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EMPTY, forkJoin, from, merge, of } from 'rxjs';
import { exhaustMap, map, mergeMap, switchMap, tap, withLatestFrom, concatMap, catchError } from 'rxjs/operators';
import { OutboundFeedApiService } from '@app/api/outbound-feed/outbound-feed-api.service';
import { AdditionalAccountInfoModalComponent } from '@app/account/components/additional-account-info-modal/additional-account-info-modal.component';
import { AssignAccountDealersModalComponent } from '@app/account/components/assign-account-dealers-modal/assign-account-dealers-modal.component';
import { ChildAccountMoveModalComponent } from '@app/account/components/child-account-move-modal/child-account-move-modal.component';
import { MovePostingAccountModalComponent } from '@app/account/components/move-posting-account-modal/move-posting-account-modal.component';
import { AccountApiService } from '@app/api/account/account-api.service';
import { AddressApiService } from '@app/api/address/address-api.service';
import { CLEApiService } from '@app/api/cle/cle-api.service';
import { ContactApiService } from '@app/api/contact/contact-api.service';
import { PricingAgreementApiService } from '@app/api/pricing-agreement/pricing-agreement-api.service';
import { SiteApiService } from '@app/api/site/site-api.service';
import {
  MODAL_CONFIGURATION,
  ROUTES,
  staticContent,
  DEFAULT_SUB_TABLE_PAGE_SIZE,
  DIALOG_MODAL_CLASS
} from '@app/app.constants';
import {
  AccountGroup,
  AccountStatus,
  IShippingDetails,
  IShippingAddressAndContactStatus
} from '@shared/models/account.model';
import { ISiteConfig, SiteCategory } from '@shared/models/site.model';
import { OutboundFeedStatus } from '@app/shared/models/outbound-feed.model';
import * as ChangeRequestActions from '@app/shared-store/change-request/change-request.actions';
import {
  IChangeRequestOptions,
  IChangeRequestActions,
  IChangeRequestQuery
} from '@app/shared/models/change-request.model';
import { ChangeRequestApiService } from '@app/api/change-request/change-request-api.service';
import { ICLEBaseDetails } from '@app/shared/models/cle.model';
import { ContactType } from '@app/shared/models/contact.model';
import { AccountCreateShippingAddressComponent } from '@app/account/components/account-create-shipping-address/account-create-shipping-address.component';
import { IState } from '..';
import * as SiteActions from '.././site/site.action';
import * as AccountActions from '../account/account.actions';
import * as OutboundFeedActions from '../outbound-feed/outbound-feed.actions';
import { getCleBaseDetail } from '../cle/cle.selectors';
import * as StepperActions from '../stepper/stepper.action';
import { getSalesOrgsPayload } from './account.reducer';
import {
  getAccountDetails,
  getAccountState,
  getCreatedAccountState,
  getParentAccount,
  getSiblingAccounts,
  getSiteDetailsByAccount,
  getIsAccountEditing,
  getCloneAccountState,
  getCloneSiteInfoState,
  getCloneAccountNumberState,
  getCopyAccountState
} from './account.selectors';

@Injectable()
export class AccountsEffects {
  public loadCorporateGroup$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.detailLoad),
      concatSpinner(({ accountNumber, legalEntityCode }: any) => {
        const apiCalls: any = [this.accountApi.getAccountDetails(accountNumber)];

        if (legalEntityCode !== undefined) {
          apiCalls.push(this.cleApi.getCLE(legalEntityCode));
        }
        // If url contains parentId, joincall to get account and CLE in parallel
        // If url doesn't contain parentId, get parentCode from CLE call, then get corporate group info
        return merge(
          withSpinner(
            AccountActions.detailLoad.type,
            forkJoin(apiCalls).pipe(
              concatMap((apiCallsResponse: any[]) => {
                if (legalEntityCode === undefined && apiCallsResponse[0]?.legalEntityCode) {
                  return this.cleApi.getCLE(apiCallsResponse[0]?.legalEntityCode).pipe(
                    mergeMap((cleResponse: ICLEBaseDetails) => {
                      return [AccountActions.detailLoadSuccess(apiCallsResponse[0], cleResponse)];
                    })
                  );
                } else {
                  return [AccountActions.detailLoadSuccess(apiCallsResponse[0], apiCallsResponse[1])];
                }
              }),
              catchError((err: HttpErrorResponse) => {
                if (err instanceof HttpErrorResponse && err.status === 404) {
                  return of(
                    addNotificationItem(
                      createWarningNotification(getStaticContent('accounts.details.account_not_found', staticContent))
                    )
                  );
                }
                return of(addNotificationItem(setupErrorNotification(err, AccountActions.detailLoad.type)));
              })
            )
          )
        );
      })
    )
  );

  public loadAccountAddressInformation$ = createLoadTask(({ addressId }) => this.addressApi.getAddress(addressId), {
    actions: this.actions$,
    ofType: AccountActions.addressLoad,
    onSuccess: AccountActions.addressLoadSuccess
  });

  public loadAccountList$ = createLoadTask(({ query, load }) => this.accountApi.getAccountList(query, load), {
    actions: this.actions$,
    ofType: AccountActions.loadAllAccount,
    onSuccess: AccountActions.loadAllAccountSuccess,
    onError: (error, { type }) =>
      error instanceof HttpErrorResponse && error.status === 404
        ? AccountActions.accountNotFound()
        : addNotificationItem(setupErrorNotification(error, type))
  });

  public loadPostingAccountList$ = createLoadTask(({ query }) => this.accountApi.getAccountList(query, 'posting'), {
    actions: this.actions$,
    ofType: AccountActions.loadPostingAccount,
    onSuccess: AccountActions.loadPostingAccountSuccess,
    /* eslint-disable sonarjs/no-identical-functions */
    onError: (error, { type }) =>
      error instanceof HttpErrorResponse && error.status === 404
        ? AccountActions.accountNotFound()
        : addNotificationItem(setupErrorNotification(error, type))
  });

  public updatedAccountDetails$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.updateAccountDetails),
      withLatestFrom(
        this.store.select(getAccountDetails),
        this.store.select(getIsAccountEditing),
        this.store.select(getCopyAccountState)
      ),
      switchMap(
        ([
          { updatedAccountDetails, isStatusUpdate, needAddOBFsMessage, ChangeRequest },
          currentAccount,
          editAccount,
          cloneAccount
        ]) => {
          let actions = [];
          let containsChangeRequestAction = false;
          if (updatedAccountDetails !== null) {
            if ((updatedAccountDetails as any).changeRequest) {
              containsChangeRequestAction = true;
              actions = this.reviseAccountChangeRequestActions(actions, updatedAccountDetails);
              delete (updatedAccountDetails as any).changeRequest;
            }

            actions = this.basicUpdateAccountAction(
              actions,
              currentAccount,
              updatedAccountDetails,
              isStatusUpdate,
              cloneAccount,
              needAddOBFsMessage,
              editAccount
            );

            if (
              Object.keys(updatedAccountDetails)
                .filter((x) => x !== 'accountGroup')
                .filter((x) => x !== 'accountNumber').length
            ) {
              const updatedAccountDetailsPayload = updatedAccountDetails;
              return this.accountApi.updateAccountDetails(updatedAccountDetails).pipe(
                withLatestFrom(
                  this.store.select(getAccountDetails),
                  this.store.select(getSiteDetailsByAccount),
                  this.store.select(getCopyAccountState)
                ),
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                switchMap(([_, currentAccountDetails, siteByAccount, copyAccountFlow]) => {
                  if (
                    currentAccountDetails?.accountGroup === AccountGroup.INMB &&
                    !copyAccountFlow &&
                    !containsChangeRequestAction
                  ) {
                    // INMB must have one primary BP, Otherwise BGAN dashboard won't work.  Only when user change primaryBilling from false to true, then the current primary BP's value should change from true to false;
                    const hasPrimaryBillingInPayload = updatedAccountDetails?.primaryBilling;
                    return [AccountActions.updateParentAndSiblingAccount(hasPrimaryBillingInPayload)];
                  }

                  actions = this.updateSiteAssociatdWithAccountActions(
                    actions,
                    currentAccountDetails,
                    updatedAccountDetailsPayload,
                    siteByAccount
                  );
                  return actions;
                })
              );
            }
          } else {
            actions = this.postAccountChangeRequest(actions, ChangeRequest);
          }

          return of(actions).pipe(
            concatMap(() => {
              return actions;
            })
          );
        }
      )
    )
  );

  public updateParentAndSiblingAccount$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.updateParentAndSiblingAccount),
      withLatestFrom(
        this.store.select(getAccountDetails),
        this.store.select(getParentAccount),
        this.store.select(getSiblingAccounts)
      ),
      switchMap(([hasPrimaryBillingInPayload, currentAccountDetails, parentAccount, siblingAccounts]) => {
        const loads: any[] = [];
        // Only when Update INMB account status to Draft, then Update INMA account status to Draft
        if (
          parentAccount &&
          parentAccount.status === AccountStatus.INCOMPLETE &&
          currentAccountDetails?.status === AccountStatus.DRAFT
        ) {
          const parentAccountPaylaod: any = {
            status: AccountStatus.DRAFT,
            accountNumber: parentAccount.accountNumber
          };
          loads.push(this.accountApi.updateAccountDetails(parentAccountPaylaod));
        }

        if (
          hasPrimaryBillingInPayload.payload &&
          siblingAccounts.length > 0 &&
          siblingAccounts[0].accountNumber !== currentAccountDetails.accountNumber
        ) {
          const siblingAccountPayload: any = {
            accountNumber: siblingAccounts[0].accountNumber, // Assuming there is only one account with primaryBilling as true
            primaryBilling: false
          };
          loads.push(this.accountApi.updateAccountDetails(siblingAccountPayload));
        }

        const actions = [
          AccountActions.detailLoad(currentAccountDetails.accountNumber, currentAccountDetails.legalEntityCode),
          addNotificationItem(
            createSuccessNotification(getStaticContent('accounts.notification.edit_account_details', staticContent))
          ),
          AccountActions.updateAccountDetailsSuccess()
        ];

        if (loads.length === 0) {
          return actions;
        }
        return merge(
          withSpinner(
            AccountActions.updateParentAndSiblingAccount.type,
            forkJoin(loads).pipe(
              mergeMap(() => {
                return actions;
              })
            )
          )
        );
      })
    )
  );

  public loadChildAccount$ = createLoadTask(
    ({ query, parentAccountNumber }) => this.accountApi.getChildAccounts(query, parentAccountNumber),
    {
      actions: this.actions$,
      ofType: AccountActions.childAccountsLoad,
      onSuccess: AccountActions.childAccountsLoadSuccess
    }
  );

  public loadAccountBillingContact$ = createLoadTask(({ contactId }) => this.contactApi.getContact(contactId), {
    actions: this.actions$,
    ofType: AccountActions.accountBillingContactLoad,
    onSuccess: AccountActions.accountBillingContactLoadSuccess
  });

  public loadSiteDetailsByAccount$ = createLoadTask(({ sapId }) => this.sitesApi.getSiteDetailsByAccount(sapId), {
    actions: this.actions$,
    ofType: AccountActions.loadSiteDetailsByAccount,
    onSuccess: AccountActions.loadSiteDetailsByAccountSuccess
  });

  public loadPartnerInfoByAccount$ = createLoadTask(
    ({ accountCode }) => this.accountApi.getAccountPartnerInfo(accountCode),
    {
      actions: this.actions$,
      ofType: AccountActions.loadPartnerByAccount,
      onSuccess: AccountActions.loadPartnerByAccountSuccess
    }
  );

  public loadPricingLinksByAccount$ = createLoadTask(
    ({ query, accountNumber }) => this.pricingAgreementApi.getPricingAgreementsByAccount(query, accountNumber),
    {
      actions: this.actions$,
      ofType: AccountActions.loadPricingLinksByAccount,
      onSuccess: AccountActions.loadPricingLinksByAccountSuccess
    }
  );

  public createAccount$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.createAccount),
      concatSpinner((action: any) =>
        this.accountApi.createAccount(action.payload).pipe(
          withLatestFrom(this.store.select(getCleBaseDetail), this.store.select(getCreatedAccountState)),
          mergeMap(([accountNumber, cleBaseDetails, salesOrgPayload]) => {
            const actions: any[] = [
              AccountActions.createAccountSuccess(accountNumber),
              AccountActions.updateCLEPersona(cleBaseDetails, accountNumber)
            ];
            if (salesOrgPayload && salesOrgPayload.associatedSalesOrg) {
              actions.push(
                AccountActions.associateSalesOrganisation(
                  getSalesOrgsPayload(accountNumber, salesOrgPayload.associatedSalesOrg),
                  cleBaseDetails.legalEntityCode,
                  accountNumber
                )
              );
            }
            salesOrgPayload?.addAccountDetails?.accountGroup === AccountGroup.INDE
              ? actions.push(StepperActions.accountUpdateStep(2))
              : actions.push(StepperActions.accountCreateIncrement());
            return actions;
          })
        )
      )
    )
  );

  public cloneINMBAccount$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.cloneINMBAccount),
      concatSpinner((action: any) =>
        this.accountApi.getChildAccounts(action.query, action.parentAccountNumber).pipe(
          mergeMap((response) => {
            const actions: any[] = [AccountActions.cloneINMBAccountSuccess(response)];
            return actions;
          })
        )
      )
    )
  );

  public updateCLEPersona$ = createLoadTask(
    ({ baseDetails, accountNumber }) => this.cleApi.updateCLEPersona(baseDetails, accountNumber),
    {
      actions: this.actions$,
      ofType: AccountActions.updateCLEPersona,
      onSuccess: AccountActions.updateCLEPersonaSuccess
    }
  );

  public associateSalesOrganisation$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.associateSalesOrganisation),
      concatSpinner(({ payload, legalEntityCode, accountNumber }: any) =>
        this.accountApi.associateSalesOrganisation(payload, legalEntityCode, accountNumber).pipe(
          withLatestFrom(this.store.select(getCreatedAccountState), this.store.select(getCopyAccountState)),
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          mergeMap(([_, createdAccount, cloneAccount]) => {
            if (createdAccount.addAccountDetails.accountGroup === AccountGroup.INM6 && !cloneAccount) {
              return [AccountActions.openAdditionalAccountInfoModal()];
            }
            return EMPTY;
          })
        )
      )
    )
  );

  public assignSalesTeamMember$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.assignSalesTeamMember),
      concatSpinner((action) =>
        this.accountApi.assignSalesTeamMemberDetails(action.payload).pipe(
          withLatestFrom(this.store.select(getCreatedAccountState)),
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          mergeMap(([_, createdAccount]) => [
            AccountActions.assignSalesTeamMemberSuccess(),

            createdAccount.addAccountDetails.accountGroup === AccountGroup.INMA
              ? AccountActions.openAssignAccountDealersModal()
              : StepperActions.accountCreateIncrement()
          ])
        )
      )
    )
  );

  public assignSalesTeamMemberSuccess$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.finalReviewAccount),
      withLatestFrom(
        this.store.select(getCreatedAccountState),
        this.store.select(getParentAccount),
        this.store.select(getCloneAccountState)
      ),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      exhaustMap(([_, createdAccountState, parentAccountState, cloneAccount]) => {
        const finalReviewActionsList = this.getAfterFinalReviewActions(createdAccountState, cloneAccount);
        if (finalReviewActionsList && finalReviewActionsList.length) {
          return finalReviewActionsList;
        }

        const title = 'accounts.create.add_child_confirm_dialog.title';
        let message: string;

        if (createdAccountState.addAccountDetails.accountGroup === AccountGroup.INM7) {
          /* eslint-disable @typescript-eslint/restrict-template-expressions */
          message = `${getStaticContent(
            'accounts.create.add_child_confirm_dialog.INM7_account_code',
            staticContent
          )}  ${createdAccountState.accountNumber.concat('\n')}
          ${getStaticContent('accounts.create.add_child_confirm_dialog.INM7_account_name', staticContent)} ${
            createdAccountState.addAccountDetails.name
          }`;
        } else {
          message = getStaticContent('accounts.create.add_child_confirm_dialog.message', staticContent);
        }
        /* eslint-enable @typescript-eslint/restrict-template-expressions */
        const [confirmLabel, cancelLabel]: string[] = [
          'accounts.create.add_child_confirm_dialog.yes',
          'accounts.create.add_child_confirm_dialog.no'
        ];

        return this.dialog
          .confirm({
            message,
            title: getStaticContent(title, staticContent),
            confirmLabel: getStaticContent(confirmLabel, staticContent),
            cancelLabel: getStaticContent(cancelLabel, staticContent),
            allowCancel: true
          })
          .pipe(
            switchMap((confirmed) => {
              let payload: any = {
                ...createdAccountState.addAccountDetails,
                ...createdAccountState.assignSalesTeamMembers,
                address: createdAccountState.billingAddress,
                contact: createdAccountState.billingContact,
                salesOrgs: createdAccountState.associatedSalesOrg,
                accountNumber: createdAccountState.accountNumber //  for accountGroup doesn't neeed assign sales teams
              };
              const accountGroup = createdAccountState.addAccountDetails.accountGroup;
              if (parentAccountState && accountGroup === AccountGroup.INM1) {
                payload = parentAccountState;
              }
              const childAccountGroup = this.getChildAccountGroup(accountGroup);
              const confirm =
                accountGroup === AccountGroup.INM7 || accountGroup === AccountGroup.INM1
                  ? cloneAccount
                    ? [
                        AccountActions.initiateChildAccountCreation(childAccountGroup, payload),
                        StepperActions.accountCreateReset(),
                        AccountActions.goToChildAccountCreation()
                      ]
                    : [
                        AccountActions.initiateChildAccountCreation(childAccountGroup, payload),
                        StepperActions.accountCreateReset()
                      ]
                  : [StepperActions.accountCreateIncrement()];

              const noConfirm = [StepperActions.accountUpdateStep(3), AccountActions.goToAccountDetails()];
              return confirmed ? confirm : noConfirm;
            })
          );
      })
    )
  );

  public createAccountAddress$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.createAccountAddress),
      concatSpinner((action: any) =>
        this.addressApi.createAddress(action.address, action.legalEntityCode).pipe(
          withLatestFrom(this.store.select(getCreatedAccountState), this.store.select(getAccountDetails)),
          mergeMap(([data, createdAccount, accountDetails]) => {
            if (action.address.type === 'Site') {
              let payload: any = {
                accountNumber: createdAccount.accountNumber
                  ? createdAccount.accountNumber
                  : createdAccount.site.siteDetails.systemSiteIds.sap
              };
              if (
                (accountDetails?.managementParty &&
                  accountDetails?.contractParty &&
                  accountDetails?.dealerCode &&
                  (createdAccount?.outboundFeed?.outboundFeed?.length || action.accountContainsValidOBFs) &&
                  !createdAccount.accountNumber) ||
                createdAccount.accountNumber
              ) {
                payload = { ...payload, status: AccountStatus.DRAFT };
              }
              const sitePayload = {
                code: createdAccount.site.siteDetails.code,
                address: data.mainAddressId
              };

              return [
                SiteActions.updateSiteDetails(sitePayload),
                AccountActions.updateCreatedAccountDetails(payload),
                AccountActions.createAccountAddressSuccess(data.mainAddressId, action.address.type),
                createdAccount.addAccountDetails
                  ? StepperActions.accountUpdateStep(2)
                  : AccountActions.navigateToDetailsPageAfterupdatedAccountDetailsWithoutFinalReview(payload)
              ];
            }
            return [
              AccountActions.createAccountAddressSuccess(data.mainAddressId, action.address.type),
              StepperActions.accountCreateIncrement()
            ];
          })
        )
      )
    )
  );

  public createAccountContact$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.createAccountContact),
      concatSpinner((action: any) =>
        this.contactApi.createContact(action.contact, action.legalEntityCode).pipe(
          withLatestFrom(
            this.store.select(getCreatedAccountState),
            this.store.select(getParentAccount),
            this.store.select(getAccountDetails)
          ),
          mergeMap(([data, createdAccount, parentAccount, accountDetails]) => {
            const actions = [];
            actions.push(AccountActions.createAccountContactSuccess(data.mainContactId, action.contact.type));

            let payload: any;
            payload = this.getBillingPayload(action, createdAccount, parentAccount, data, actions);
            if (action.contact.type === ContactType.EMERGENCY) {
              payload = {
                accountNumber: createdAccount.accountNumber
                  ? createdAccount.accountNumber
                  : createdAccount.site.siteDetails.systemSiteIds?.sap
              };

              if (this.checkChangeAccountStatus(accountDetails, createdAccount, action.accountContainsValidOBFs)) {
                payload = { ...payload, status: AccountStatus.DRAFT };
              }
              const emergencyAddressPayload = this.getEmergencyAddressSitePayload(
                createdAccount.site.siteDetails,
                createdAccount?.siteEmergencyAddress?.id
              );
              const emergencyContactPayload = this.getEmergencySitePayload(
                createdAccount.site.siteDetails,
                data.mainContactId
              );
              const sitePaylaod = { ...emergencyAddressPayload, ...emergencyContactPayload };
              actions.push(SiteActions.updateSiteDetails(sitePaylaod));
              actions.push(AccountActions.updateCreatedAccountDetails(payload));
              createdAccount.addAccountDetails
                ? actions.push(StepperActions.accountCreateIncrement())
                : actions.push(
                    AccountActions.navigateToDetailsPageAfterupdatedAccountDetailsWithoutFinalReview(payload)
                  );
            } else if (action.contact.type === ContactType.SHIPPING) {
              delete payload.accountNumber;
              actions.push(AccountActions.createShippingInfo(payload, createdAccount.accountNumber, null, true));
            } else {
              actions.push(AccountActions.updateCreatedAccountDetails(payload));
            }
            return actions;
          })
        )
      )
    )
  );

  public openAssignAccountDealersModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountActions.openAssignAccountDealersModal),
        tap(() => {
          return this.modalService.show(AssignAccountDealersModalComponent, {
            ...MODAL_CONFIGURATION
          });
        })
      ),
    { dispatch: false }
  );

  public loadDealerList$ = createLoadTask(({ search }) => this.accountApi.getAccountDealersDetail(search), {
    actions: this.actions$,
    ofType: AccountActions.loadDealerList,
    onSuccess: AccountActions.loadDealerListSuccess
  });

  public assignAccountDealer$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.assignAccountDealer),
      tap(() => this.modalService.hide()),
      concatSpinner((action: any) =>
        this.accountApi.assignAccountDealerDetails(action.payload).pipe(
          withLatestFrom(this.store.select(getCreatedAccountState)),
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          mergeMap(([_, createdAccount]) => {
            if (createdAccount.addAccountDetails.accountGroup === AccountGroup.INMA) {
              return [StepperActions.accountCreateIncrement()];
            }
            return [StepperActions.accountCreateReset()];
          })
        )
      )
    )
  );

  public updateCreatedAccountDetails$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.updateCreatedAccountDetails),
      concatSpinner(({ accountDetails }: any) => {
        return this.accountApi.updateAccountDetails(accountDetails).pipe(mergeMap(() => EMPTY));
      })
    )
  );

  public createShippingInfo$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.createShippingInfo),
      concatSpinner(({ payload, accountCode, cleNumber, updateAccountStepper }: any) => {
        return this.accountApi
          .createShippingInfo(payload, accountCode)
          .pipe(
            mergeMap(() =>
              updateAccountStepper
                ? of(StepperActions.accountCreateIncrement())
                : of(AccountActions.detailLoad(accountCode, cleNumber))
            )
          );
      })
    )
  );

  public navigateToDetailsPageAfterupdatedAccountDetails$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.navigateToDetailsPageAfterupdatedAccountDetails),
      concatSpinner(({ accountDetails }: any) => {
        return this.accountApi
          .updateAccountDetails(accountDetails)
          .pipe(mergeMap(() => of(AccountActions.finalReviewAccount())));
      })
    )
  );

  public navigateToDetailsPageAfterupdatedAccountDetailsWithoutFinalReview$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.navigateToDetailsPageAfterupdatedAccountDetailsWithoutFinalReview),
      concatSpinner(({ accountDetails }: any) => {
        return this.accountApi
          .updateAccountDetails(accountDetails)
          .pipe(mergeMap(() => of(AccountActions.goToAccountDetails())));
      })
    )
  );

  public goToAccountDetails$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.goToAccountDetails),
      withLatestFrom(this.store.select(getAccountState)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, accountState]) => {
        let accountToNavigate;
        accountToNavigate = accountState.createAccount.accountNumber
          ? accountState.createAccount.accountNumber
          : accountState.accountDetails.accountNumber;
        if (
          accountState.createAccount &&
          accountState.createAccount?.addAccountDetails &&
          accountState.createAccount?.addAccountDetails.accountGroup === AccountGroup.INMB
        ) {
          accountToNavigate = accountState.parentAccount?.accountNumber
            ? accountState.parentAccount?.accountNumber
            : accountState?.cloneAccount?.accountNumber;
        }

        const action =
          accountState?.cloneAccount?.isAccountCopied && accountState?.cloneAccount?.accountGroup !== AccountGroup.INDE
            ? addNotificationItem(
                createSuccessNotification(
                  getStaticContent(`accounts.create.clone_none_INDE_notification`, staticContent)
                )
              )
            : accountState?.cloneAccount?.isAccountCopied &&
              accountState?.cloneAccount?.accountGroup === AccountGroup.INDE
            ? addNotificationItem(
                createSuccessNotification(getStaticContent(`accounts.create.clone_INDE_notification`, staticContent))
              )
            : accountState?.createAccount?.site?.siteDetails && !accountState.createAccount?.outboundFeed
            ? addNotificationItem(
                createSuccessNotification(
                  getStaticContent(`accounts.create.create_site_success_notification`, staticContent)
                )
              )
            : addNotificationItem(
                createSuccessNotification(getStaticContent(`accounts.create.success_notification`, staticContent))
              );
        if (accountState?.createAccount?.addAccountDetails?.legalEntityCode) {
          return from(
            this.router.navigate(['/', ROUTES.ACCOUNTS, accountToNavigate], {
              state: { shouldByPassRouteGuard: true },
              // query params
              queryParams: { parentId: accountState.createAccount.addAccountDetails.legalEntityCode }
            })
          ).pipe(map(() => action));
        }
        return from(
          this.router.navigate(['/', ROUTES.ACCOUNTS, accountToNavigate], {
            state: { shouldByPassRouteGuard: true },
            // query params
            queryParams: { parentId: accountState.accountDetails.legalEntityCode }
          })
        ).pipe(map(() => action));
      })
    )
  );

  public openAdditionalAccountInfoModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountActions.openAdditionalAccountInfoModal),
        tap(() => {
          return this.modalService.show(AdditionalAccountInfoModalComponent, {
            ...MODAL_CONFIGURATION
          });
        })
      ),
    { dispatch: false }
  );

  public loadContractPartnerInfo$ = createLoadTask(({ search }) => this.accountApi.getContractPartnerInfo(search), {
    actions: this.actions$,
    ofType: AccountActions.loadContractPartnerInfo,
    onSuccess: AccountActions.loadContractPartnerInfoSuccess
  });

  public loadManagementCompanyInfo$ = createLoadTask(({ search }) => this.accountApi.getManagementCompanyInfo(search), {
    actions: this.actions$,
    ofType: AccountActions.loadManagementCompanyInfo,
    onSuccess: AccountActions.loadManagementCompanyInfoSuccess
  });

  public loadShipOwnerList$ = createLoadTask(({ search }) => this.accountApi.getAccountShipOwnerDetail(search), {
    actions: this.actions$,
    ofType: AccountActions.loadShipOwnerList,
    onSuccess: AccountActions.loadShipOwnerListSuccess
  });

  public saveAdditionalInformation$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.saveAdditionalInformation),
      concatSpinner((action: any) =>
        this.accountApi
          .saveAdditionalInformation(action.payload)
          .pipe(mergeMap(() => [AccountActions.saveAdditionalInformationSuccess()]))
      )
    )
  );

  public saveAdditionalInformationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountActions.saveAdditionalInformationSuccess),
        tap(() => {
          return this.modalService.hide();
        })
      ),
    { dispatch: false }
  );

  public createSiteForAccount$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.createSiteForAccount),
      concatSpinner((action: any) =>
        this.sitesApi.createSite(action.payload, action.legalEntityCode).pipe(
          withLatestFrom(this.store.select(getCreatedAccountState), this.store.select(getAccountDetails)),
          mergeMap(([code, createdAccount, accountDetails]) => {
            const payload = action.payload;
            const type = payload.type;
            return this.getSiteAction(
              code,
              type,
              payload,
              createdAccount,
              accountDetails,
              action.accountContainsValidOBFs
            );
          })
        )
      )
    )
  );

  public creteSiteWithContactAndAddress$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.creteSiteWithContactAndAddress),
      concatSpinner(({ payload, accountNumber, legalEntityCode, contactAndAddress }: any) => {
        const apiCalls = [];
        const sitype = payload.type;
        apiCalls.push(this.sitesApi.createSite(payload, legalEntityCode));

        if (contactAndAddress.address) {
          const address = contactAndAddress.address;
          apiCalls.push(this.addressApi.updateAddress(legalEntityCode, address));
        }
        if (contactAndAddress.contact) {
          const contact = contactAndAddress.contact;
          apiCalls.push(this.contactApi.updateContact(legalEntityCode, contact));
        }

        return forkJoin(apiCalls).pipe(
          switchMap((apiCallsResponse: any[]) => {
            let sitePayload: any = {};
            apiCallsResponse.forEach((response) => {
              sitePayload = this.getCreteSiteWithContactAndAddressPayload(sitePayload, response, sitype);
            });
            if (sitePayload.address || sitePayload.emergencyAddress || sitePayload.emergencyContact) {
              return [
                SiteActions.updateSiteDetails(sitePayload),
                AccountActions.updateCreatedAccountDetails({ accountNumber }),
                AccountActions.goToAccountDetails()
              ];
            } else {
              return [
                AccountActions.updateCreatedAccountDetails({ accountNumber }),
                AccountActions.goToAccountDetails()
              ];
            }
          })
        );
      })
    )
  );

  public openDistributionPartnerDialog$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.openDistributionPartnerDialog),
      switchMap(({ payload, salesOrgForm }) => {
        let titleToDisplay: string;
        if (
          payload.customerSubtypes &&
          payload.accountGroup === AccountGroup.INM1 &&
          payload.customerSubtypes.includes('Distribution Partner') &&
          !payload.dpId
        ) {
          titleToDisplay = getStaticContent('accounts.create.distribution_partner_dialog.title', staticContent);
        } else if (payload.customerSubtypes.includes('Distribution Partner')) {
          titleToDisplay = getStaticContent(
            'accounts.create.account.form_fields.distribution_modal.title',
            staticContent
          );
        } else if (payload.customerSubtypes.includes('Service Provider')) {
          titleToDisplay = getStaticContent(
            'accounts.create.account.form_fields.solution_provider_modal.title',
            staticContent
          );
        }

        return this.dialog
          .confirm({
            type: ConfirmType.Warning,
            title: titleToDisplay,
            confirmLabel: getStaticContent('buttons.next', staticContent),
            cancelLabel: getStaticContent('accounts.create.distribution_partner_dialog.backToEdit', staticContent),
            allowCancel: true
          })
          .pipe(
            switchMap((confirmed) =>
              confirmed
                ? payload.isEdit
                  ? delete payload.isEdit && [AccountActions.updateAccountDetails(payload)]
                  : [AccountActions.createAccount(payload, salesOrgForm)]
                : EMPTY
            )
          );
      })
    )
  );

  public updateAccountAddressAndContact$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.updateAccountAddressAndContact),
      concatSpinner(({ accountDetails, needAddOBFsMessage }: any) => {
        let apiCalls = [];

        if (accountDetails.deletedMediums) {
          accountDetails.deletedMediums.forEach((medium) => {
            apiCalls.push(this.contactApi.deleteMedium(medium?.id, medium?.mediumType));
          });
          delete accountDetails.deletedMediums;
        }

        if (accountDetails.address) {
          const address = accountDetails.address;
          apiCalls.push(this.addressApi.updateAddress(accountDetails.legalEntityCode, address));
          delete accountDetails.address;
        }
        if (accountDetails.contact) {
          const contact = accountDetails.contact;
          apiCalls.push(this.contactApi.updateContact(accountDetails.legalEntityCode, contact));
          delete accountDetails.contact;
        }

        apiCalls = this.setShippingAddressAndContactPayload(accountDetails, apiCalls);

        if (accountDetails?.deleteSalesOrgCodes) {
          // delete sales Orgs
          accountDetails.deleteSalesOrgCodes.forEach((salesOrg) => {
            apiCalls.push(
              this.accountApi.deleteSalesOrg(
                accountDetails.legalEntityCode,
                accountDetails.accountNumber,
                salesOrg?.code
              )
            );
          });
          delete accountDetails.deleteSalesOrgCodes;
        }

        if (accountDetails?.siteDetailInfo) {
          // update site
          const site = accountDetails.siteDetailInfo;
          apiCalls.push(this.sitesApi.updateSiteDetails(site));
          delete accountDetails.siteDetailInfo;
        }
        // update obfs
        if (accountDetails.outboundFeeds) {
          accountDetails.outboundFeeds.forEach((obf) => {
            apiCalls.push(this.obfApi.updateOutboundFeed(obf));
          });
          delete accountDetails.outboundFeeds;
        }

        return forkJoin(apiCalls).pipe(
          switchMap((apiCallsResponse: any[]) => {
            delete accountDetails.legalEntityCode;
            let payload: any = {};
            apiCallsResponse.forEach((response) => {
              if (response && response.addressId) {
                payload = {
                  ...payload,
                  billingAddress: response.addressId
                };
              }
              if (response && response.contactId) {
                payload = {
                  ...payload,
                  billingContact: response.contactId
                };
              }
            });
            return [
              AccountActions.updateAccountDetails(
                {
                  ...accountDetails,
                  ...payload
                },
                false,
                needAddOBFsMessage
              )
            ];
          })
        );
      })
    )
  );

  public loadSiblingAccounts$ = createLoadTask(
    ({ query, postingAccountNumber }) => this.accountApi.getChildAccounts(query, postingAccountNumber),
    {
      actions: this.actions$,
      ofType: AccountActions.loadSiblingAccounts,
      onSuccess: AccountActions.loadSiblingAccountsSuccess
    }
  );

  public loadParentAccount$ = createLoadTask(
    ({ parentAccountNumber }) => this.accountApi.getAccountDetails(parentAccountNumber),
    {
      actions: this.actions$,
      ofType: AccountActions.loadParentAccount,
      onSuccess: AccountActions.loadParentAccountSuccess
    }
  );

  public openChildAccountMoveModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountActions.openChildAccountMoveModal),
        tap(() => {
          return this.modalService.show(ChildAccountMoveModalComponent, {
            ...MODAL_CONFIGURATION,
            class: DIALOG_MODAL_CLASS,
            initialState: {}
          });
        })
      ),
    { dispatch: false }
  );

  public loadNewAccount$ = createLoadTask(({ query }) => this.accountApi.getParentAccountList(query), {
    actions: this.actions$,
    ofType: AccountActions.loadParentAccounts,
    onSuccess: AccountActions.loadParentAccountsSuccess
  });

  public loadNewAccountUnderSameCLE$ = createLoadTask(
    ({ query }) => this.accountApi.getParentAccountListUnderSameCle(query),
    {
      actions: this.actions$,
      ofType: AccountActions.loadParentAccountsUnderSameCLE,
      onSuccess: AccountActions.loadParentAccountsUnderSameCLESuccess
    }
  );

  public loadActiveChildAccounts$ = createLoadTask(
    ({ query, parentAccountNumber }) => this.accountApi.getChildAccounts(query, parentAccountNumber),
    {
      actions: this.actions$,
      ofType: AccountActions.loadActiveChildAccounts,
      onSuccess: AccountActions.loadActiveChildAccountSuccess
    }
  );

  public deleteAccount$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.deleteAccount),
      withLatestFrom(this.store.select(getAccountDetails), this.store.select(getSiteDetailsByAccount)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, currentAccountDetails, currentSiteDetails]) => {
        const apiCalls = [];
        const currentAccountNumber = currentAccountDetails.accountNumber;
        const parentAccountNumber =
          !currentAccountDetails.post && currentAccountDetails.salesOrgs && currentAccountDetails.salesOrgs[0]
            ? currentAccountDetails.salesOrgs[0].postingAccount
            : null;
        const addressId = currentAccountDetails.billingAddress;
        const contactId = currentAccountDetails.billingContact;
        const siteId = currentSiteDetails && currentSiteDetails[0] ? currentSiteDetails[0].code : null;
        if (addressId) {
          apiCalls.push(this.addressApi.deleteAddress(addressId));
        }
        if (contactId) {
          apiCalls.push(this.contactApi.deleteContact(contactId));
        }
        apiCalls.push(this.accountApi.deleteAccount(currentAccountNumber));
        if (parentAccountNumber && currentAccountDetails.primaryBilling) {
          apiCalls.push(this.accountApi.deleteAccount(parentAccountNumber));
        }
        if (siteId) {
          apiCalls.push(this.sitesApi.deleteSite(siteId));
        }
        return merge(
          withSpinner(
            AccountActions.deleteAccount.type,
            forkJoin(apiCalls).pipe(
              mergeMap(() => {
                return [
                  AccountActions.deleteAccountSuccess(),
                  addNotificationItem(
                    createSuccessNotification(
                      getStaticContent('accounts.notification.edit_status_inactivated', staticContent)
                    )
                  ),
                  AccountActions.detailLoad(currentAccountDetails.accountNumber, currentAccountDetails.legalEntityCode)
                ];
              })
            )
          )
        );
      })
    )
  );

  public openPostingAccountMoveModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountActions.openPostingAccountMoveModal),
        tap(() => {
          return this.modalService.show(MovePostingAccountModalComponent, {
            ...MODAL_CONFIGURATION,
            class: DIALOG_MODAL_CLASS,
            initialState: {}
          });
        })
      ),
    { dispatch: false }
  );

  public movePostingAccount$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.movePostingAccount),
      concatSpinner(({ payload, dealerPayload, newObfs, inheritance }: any) => {
        const apiCalls: any = [this.accountApi.movePostingAccount(payload)];

        if (dealerPayload) {
          apiCalls.push(this.accountApi.updateAccountDetails(dealerPayload));
        }

        if (newObfs) {
          inheritance
            ? newObfs.map((obf) => apiCalls.push(this.obfApi.createOutboundFeedWithInheritanceInARAccount(obf)))
            : newObfs.map((obf) => apiCalls.push(this.obfApi.createOutboundFeed(obf)));
        }

        void this.router.navigate(['/', ROUTES.ACCOUNTS, payload.accountNumber], {
          queryParams: { parentId: payload.newLegalEntityCode }
        });

        return forkJoin(apiCalls).pipe(
          switchMap((apiCallsResponse: any[]) => {
            return [
              AccountActions.movePostingAccountSuccess(apiCallsResponse[0]),
              OutboundFeedActions.loadOutboundFeed({
                accountNumber: payload.accountNumber,
                legalEntityCode: payload.newLegalEntityCode,
                status: OutboundFeedStatus.ACTIVE,
                offset: 0,
                limit: DEFAULT_SUB_TABLE_PAGE_SIZE
              }),
              AccountActions.detailLoad(payload.accountNumber, payload.newLegalEntityCode)
            ];
          })
        );
      })
    )
  );

  public prohibitAccount$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.prohibitAccount),
      concatSpinner(({ accountNumber, isProhibited }: any) =>
        this.accountApi.prohibitAccount(accountNumber, isProhibited).pipe(
          withLatestFrom(this.store.select(getAccountDetails)),
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          mergeMap(([_, accountDetails]) => [
            addNotificationItem(
              createSuccessNotification(getStaticContent('accounts.notification.compliance_updated', staticContent))
            ),
            AccountActions.detailLoad(accountNumber, accountDetails.legalEntityCode)
          ])
        )
      )
    )
  );

  public syncAccount$ = createLoadTask(({ accountNumber }) => this.accountApi.syncAccount(accountNumber), {
    actions: this.actions$,
    ofType: AccountActions.syncAccount,
    onSuccess: () =>
      addNotificationItem(
        createSuccessNotification(getStaticContent('accounts.notification.sync_cle_success', staticContent))
      )
  });

  public voidAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.voidAccount),
      concatSpinner(({ accountNumber }) =>
        this.accountApi.voidAccount(accountNumber).pipe(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          mergeMap((_) => [
            addNotificationItem(
              createSuccessNotification(
                getStaticContent('accounts.details.void_account.success_notification', staticContent)
              )
            ),
            AccountActions.detailLoad(accountNumber, undefined)
          ])
        )
      )
    )
  );

  public moveBPAccountSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountActions.moveBPAccountSuccess),
        tap(() => {
          return this.modalService.hide();
        })
      ),
    { dispatch: false }
  );

  public goToChildAccountCreation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountActions.goToChildAccountCreation),
        tap(() => {
          return from(
            this.router.navigate(['/accounts/create-account'], {
              state: { shouldByPassRouteGuard: true }
            })
          );
        })
      ),
    { dispatch: false }
  );

  public moveBPAccountDiffCLE$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.moveBPAccount),
      concatSpinner(({ payload, newOBFs, inheritancePayload, partnerPayload }: any) => {
        let apiCalls;
        if (partnerPayload) {
          apiCalls = [
            this.accountApi.moveBPAccountDiffCLE(payload),
            this.accountApi.updateAccountDetails(partnerPayload)
          ];
        } else {
          apiCalls = [this.accountApi.moveBPAccountDiffCLE(payload)];
        }

        if (newOBFs) {
          newOBFs.map((obf) => apiCalls.push(this.obfApi.createOutboundFeed(obf)));
        }

        if (inheritancePayload?.inheritanceIds?.length) {
          apiCalls.push(this.obfApi.createOutboundFeedWithInheritanceInBPAccount(inheritancePayload));
        }

        void this.router.navigate(['/', ROUTES.ACCOUNTS, payload.accountCode], {
          queryParams: { parentId: payload.newCLE }
        });

        return forkJoin(apiCalls).pipe(
          switchMap((apiCallsResponse: any[]) => {
            return [
              AccountActions.moveBPAccountSuccess(apiCallsResponse[0]),
              OutboundFeedActions.loadOutboundFeed({
                accountNumber: payload.accountCode,
                legalEntityCode: payload.newCLE,
                status: OutboundFeedStatus.ACTIVE,
                offset: 0,
                limit: DEFAULT_SUB_TABLE_PAGE_SIZE
              }),
              addNotificationItem(
                createSuccessNotification(getStaticContent('accounts.notification.move_child_account', staticContent))
              ),
              AccountActions.detailLoad(payload.accountCode, payload.newCLE)
            ];
          })
        );
      })
    )
  );

  public loadAssociatedSiteContact$ = createLoadTask(
    ({ associatedSiteContactId }) => this.contactApi.getContact(associatedSiteContactId),
    {
      actions: this.actions$,
      ofType: AccountActions.loadSiteContact,
      onSuccess: AccountActions.loadSiteContactSuccess
    }
  );

  public loadAssociatedSiteAddreaa$ = createLoadTask(({ addressId }) => this.addressApi.getAddress(addressId), {
    actions: this.actions$,
    ofType: AccountActions.loadSiteAddress,
    onSuccess: AccountActions.loadSiteAddressSuccess
  });

  public createOutboundFeed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.createOBFDuringAccountCreation),
      concatSpinner(({ obfs, inheritancePayload }) => {
        const apiCalls = obfs.map((obf) => this.obfApi.createOutboundFeed(obf));
        if (inheritancePayload?.inheritanceIds?.length) {
          apiCalls.push(this.obfApi.createOutboundFeedWithInheritanceInBPAccount(inheritancePayload));
        }

        return forkJoin(apiCalls).pipe(
          concatMap((apiCallsResponse: any[]) => {
            return [
              AccountActions.createOBFDuringAccountCreationSuccess(apiCallsResponse),
              StepperActions.accountCreateIncrement()
            ];
          })
        );
      })
    )
  );

  public cloneAccount$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.cloneAccountInfo),
      concatSpinner((action: any) =>
        this.accountApi.createAccount(action.payload).pipe(
          withLatestFrom(
            this.store.select(getCleBaseDetail),
            this.store.select(getCloneAccountState),
            this.store.select(getCloneSiteInfoState),
            this.store.select(getCloneAccountNumberState)
          ),
          mergeMap(([accountNumber, cleBaseDetails, cloneAccountFields, cloneSiteFields, copiedAccountNumber]) => {
            let actions: any[] = [
              AccountActions.cloneAccountInfoSuccess(accountNumber),
              AccountActions.updateCLEPersona(cleBaseDetails, accountNumber)
            ];
            if (cloneAccountFields && cloneAccountFields?.associatedSalesOrg?.salesOrgs) {
              actions.push(
                AccountActions.associateSalesOrganisation(
                  getSalesOrgsPayload(accountNumber, cloneAccountFields?.associatedSalesOrg?.salesOrgs),
                  cleBaseDetails.legalEntityCode,
                  accountNumber
                )
              );
            }
            actions = this.getCloneAccountAction(
              cloneAccountFields,
              actions,
              copiedAccountNumber,
              accountNumber,
              cloneSiteFields,
              cleBaseDetails
            );
            return actions;
          })
        )
      )
    )
  );

  public pullARAccountOBFS$ = createLoadTask(({ query }) => this.obfApi.getOutboundFeedByAccount(query), {
    actions: this.actions$,
    ofType: AccountActions.pullARAccountOBFS,
    onSuccess: AccountActions.pullARAccountOBFSSuccess
  });

  public loadApprovedMovedToCLEOrAccount$ = createLoadTask(
    ({ query }) => this.changeRequestApi.getChangeRequestList(query),
    {
      actions: this.actions$,
      ofType: AccountActions.loadApprovedMovedToCLEOrAccount,
      onSuccess: AccountActions.loadApprovedMovedToCLEOrAccountSuccess
    }
  );

  public sendChangeRequestOnINMB$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.sendChangeRequestOnINMB),
      concatSpinner((action) => {
        const apiCalls: any = [this.accountApi.getChildAccounts(action.payload, action.accountNumber)];
        return merge(
          withSpinner(
            AccountActions.sendChangeRequestOnINMB.type,
            forkJoin(apiCalls).pipe(
              concatMap((apiCallsResponse: any[]) => {
                if (apiCallsResponse[0] && apiCallsResponse[0].count) {
                  const query: IChangeRequestQuery = {
                    entityId: apiCallsResponse[0].assets[0].accountNumber,
                    limit: 1,
                    offset: 0
                  };
                  return this.changeRequestApi.getChangeRequestList(query).pipe(
                    mergeMap((sendRequest: any) => {
                      const associatedINMBChangeRequest = !!sendRequest.count;
                      return [
                        AccountActions.sendChangeRequestOnINMAOrINMBSuccess(
                          !associatedINMBChangeRequest,
                          apiCallsResponse[0].assets[0]
                        )
                      ];
                    })
                  );
                } else {
                  return EMPTY;
                }
              })
            )
          )
        );
      })
    )
  );

  public loadShippingAddressContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.loadShippingAddressAndContactById),
      mergeMap(({ accountCode, shippingId, addressId, contactId }) => {
        const apiLoads = {
          accountNumberForShippingId: of(accountCode),
          shippingInfoId: of(shippingId),
          shippingAddress: addressId === undefined ? of(null) : this.addressApi.getAddress(addressId),
          shippingContact: contactId === undefined ? of(null) : this.contactApi.getContact(contactId)
        };

        return forkJoin(apiLoads).pipe(
          map(({ accountNumberForShippingId, shippingInfoId, shippingAddress, shippingContact }) =>
            AccountActions.loadShippingAddressAndContactByIdSuccess(
              accountNumberForShippingId,
              shippingInfoId,
              shippingAddress,
              shippingContact
            )
          )
        );
      })
    )
  );

  public deleteShippingAddressAndContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.deleteShippingAddressAndContact),
      concatSpinner(({ shippingInfo, accountNumber, CLENumber }) => {
        const apiCalls = [];
        const updateShippingInfoStatus = { id: shippingInfo.id, status: IShippingAddressAndContactStatus.INACTIVE };
        apiCalls.push(this.addressApi.deleteAddress(shippingInfo.address));
        if (shippingInfo.contact) {
          apiCalls.push(this.contactApi.deleteContact(shippingInfo.contact));
        }
        apiCalls.push(this.accountApi.updateShippingInfo(updateShippingInfoStatus, accountNumber));

        return forkJoin(apiCalls).pipe(
          concatMap(() => {
            return [
              addNotificationItem(
                createSuccessNotification(
                  getStaticContent('accounts.shipping-address-contact.notifications.delete_address', staticContent)
                )
              ),
              AccountActions.detailLoad(accountNumber, CLENumber)
            ];
          })
        );
      })
    )
  );

  public openCreateShippingAddressModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountActions.openCreateShippingAddressModal),
        tap(() => {
          return this.modalService.show(AccountCreateShippingAddressComponent, {
            ...MODAL_CONFIGURATION,
            class: DIALOG_MODAL_CLASS,
            initialState: {}
          });
        })
      ),
    { dispatch: false }
  );

  public createShippingAddress$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.createShippingAddress),
      concatSpinner(({ payload, accountNumber, cleNumber }) =>
        this.addressApi.createAddress(payload, cleNumber).pipe(
          mergeMap((response) => {
            const createShippingInfo: IShippingDetails = {
              address: response.mainAddressId
            };
            const actions: any[] = [
              AccountActions.createShippingInfo(createShippingInfo, accountNumber, cleNumber),
              AccountActions.createShippingAddressSuccess(),
              addNotificationItem(
                createSuccessNotification(
                  getStaticContent('accounts.shipping-address-contact.notifications.create_address', staticContent)
                )
              )
            ];
            return actions;
          })
        )
      )
    )
  );

  public createShippingAddressSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountActions.createShippingAddressSuccess),
        tap(() => {
          return this.modalService.hide();
        })
      ),
    { dispatch: false }
  );

  public updateShippingAddressAndContact$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.updateShippingAddressAndContact),
      concatSpinner(({ shippingInfo, accountNumber, legalEntityCode, shippingId }: any) => {
        const apiCalls = [];

        if (shippingInfo.deletedMediums) {
          shippingInfo.deletedMediums.forEach((medium) => {
            apiCalls.push(this.contactApi.deleteMedium(medium?.id, medium?.mediumType));
          });
          delete shippingInfo.deletedMediums;
        }

        if (shippingInfo.address) {
          const address = shippingInfo.address;
          apiCalls.push(this.addressApi.updateAddress(shippingInfo.legalEntityCode, address));
          delete shippingInfo.address;
        }
        if (shippingInfo.contact) {
          const contact = shippingInfo.contact;
          apiCalls.push(this.contactApi.updateContact(shippingInfo.legalEntityCode, contact));
          delete shippingInfo.contact;
        }

        return merge(
          withSpinner(
            AccountActions.updateShippingAddressAndContact.type,
            forkJoin(apiCalls).pipe(
              mergeMap(() => {
                return [
                  AccountActions.editShippingInfoSuccess(accountNumber, shippingId),
                  addNotificationItem(
                    createSuccessNotification(
                      getStaticContent('accounts.shipping-address-contact.notifications.edit_shipping', staticContent)
                    )
                  ),
                  AccountActions.detailLoad(accountNumber, legalEntityCode)
                ];
              })
            )
          )
        );
      })
    )
  );

  public createShippingContact$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.createShippingContact),
      concatSpinner(({ payload, shippingInfoId, accountNumber, cleNumber }) =>
        this.contactApi.createContact(payload, cleNumber).pipe(
          mergeMap((response) => {
            const updateShippingInfo: IShippingDetails = {
              id: shippingInfoId.id,
              contact: response.mainContactId
            };
            const actions: any[] = [
              AccountActions.updateShippingInfo(updateShippingInfo, accountNumber, cleNumber),
              //  AccountActions.detailLoad(accountNumber, cleNumber),....
              addNotificationItem(
                createSuccessNotification(
                  getStaticContent('accounts.shipping-address-contact.notifications.create_contact', staticContent)
                )
              ),
              AccountActions.loadShippingAddressAndContactById(
                accountNumber,
                shippingInfoId.id,
                shippingInfoId.address,
                response.mainContactId
              )
            ];
            return actions;
          })
        )
      )
    )
  );

  public sendChangeRequestOnINMA$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.sendChangeRequestOnINMA),
      concatSpinner((action: any) =>
        this.changeRequestApi.getChangeRequestList(action.payload).pipe(
          mergeMap((response) => {
            const associatedINMAChangeRequest = !!response.count;
            return [AccountActions.sendChangeRequestOnINMAOrINMBSuccess(!associatedINMAChangeRequest, action.INMAInfo)];
          })
        )
      )
    )
  );

  public updateShippingInfo$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(AccountActions.updateShippingInfo),
      concatSpinner(({ updateShippingInfopayload, accountNumber, cleNumber }: any) => {
        return this.accountApi
          .updateShippingInfo(updateShippingInfopayload, accountNumber)
          .pipe(concatMap(() => [AccountActions.detailLoad(accountNumber, cleNumber)]));
      })
    )
  );

  constructor(
    private readonly router: Router,
    private readonly store: Store<IState>,
    private readonly actions$: Actions,
    private readonly accountApi: AccountApiService,
    private readonly obfApi: OutboundFeedApiService,
    private readonly cleApi: CLEApiService,
    private readonly sitesApi: SiteApiService,
    protected modalService: BsModalService,
    private readonly contactApi: ContactApiService,
    private readonly dialog: DialogService,
    private readonly pricingAgreementApi: PricingAgreementApiService,
    private readonly addressApi: AddressApiService,
    private readonly changeRequestApi: ChangeRequestApiService
  ) {}

  private getChildAccountGroup(parentAccountGroup: string): string {
    let childAccountGroup: string;
    switch (parentAccountGroup) {
      case AccountGroup.INM1: {
        childAccountGroup = AccountGroup.INM1;
        break;
      }
      case AccountGroup.INM7: {
        childAccountGroup = AccountGroup.INM6;
        break;
      }
      default:
        break;
    }
    return childAccountGroup;
  }

  private getEmergencySitePayload(siteDetails: ISiteConfig, contactId: string) {
    return {
      code: siteDetails.code,
      emergencyContact: contactId
    };
  }

  private getEmergencyAddressSitePayload(siteDetails: ISiteConfig, addressId: string) {
    return {
      code: siteDetails.code,
      emergencyAddress: addressId
    };
  }

  private getBillingPayload(action, createdAccount, parentAccount, data, actions): any {
    let payload;
    if (action.contact.type === ContactType.BILLING) {
      payload = {
        accountNumber: createdAccount.accountNumber,
        billingAddress: createdAccount.billingAddress.id,
        billingContact: data.mainContactId
      };
      payload = this.getBillingPayloadBasedOnAccountGroup(payload, createdAccount, actions, parentAccount);

      return payload;
    } else if (action.contact.type === ContactType.SHIPPING) {
      payload = {
        accountNumber: createdAccount.accountNumber,
        address: createdAccount.shippingAddress.id,
        contact: data.mainContactId
      };
      return payload;
    }
  }

  private getBillingPayloadBasedOnAccountGroup(payload, createdAccount, actions, parentAccount) {
    if (createdAccount.addAccountDetails.accountGroup === AccountGroup.INMB) {
      payload = {
        ...payload,
        status: AccountStatus.DRAFT
      };
      if (parentAccount.accountGroup === AccountGroup.INMA && parentAccount.status === AccountStatus.INCOMPLETE) {
        const parentAccountPayload: any = {
          accountNumber: parentAccount.accountNumber,
          status: AccountStatus.DRAFT
        };
        actions.push(AccountActions.updateCreatedAccountDetails(parentAccountPayload));
      }
    }
    if (createdAccount.addAccountDetails.accountGroup === AccountGroup.INM1 && !createdAccount.addAccountDetails.post) {
      payload = {
        ...payload,
        status: AccountStatus.DRAFT
      };
      actions.push(StepperActions.accountUpdateStep(4));
    } else {
      createdAccount.addAccountDetails.accountGroup === AccountGroup.INM7 ||
      createdAccount.addAccountDetails.accountGroup === AccountGroup.INDE
        ? actions.push(StepperActions.accountUpdateStep(3))
        : actions.push(StepperActions.accountCreateIncrement());
    }
    return payload;
  }

  private getSiteAction(code, type, payload, createdAccount, accountDetails, accountContainsValidOBFs) {
    if (type === SiteCategory.Sea) {
      return [AccountActions.createSiteForAccountSuccess(code), StepperActions.accountCreateIncrement()];
    }
    if (type === SiteCategory.Land) {
      return [AccountActions.createSiteForAccountSuccess(code), StepperActions.accountCreateIncrement()];
    }
    if (type === SiteCategory.Air) {
      let accountpayload: any = {
        accountNumber: createdAccount.accountNumber ? createdAccount.accountNumber : payload?.systemSiteIds?.sap
      };
      if (this.checkChangeAccountStatus(accountDetails, createdAccount, accountContainsValidOBFs)) {
        accountpayload = { ...accountpayload, status: AccountStatus.DRAFT };
      }
      if (accountpayload) {
        return [
          AccountActions.updateCreatedAccountDetails(accountpayload), // Updating current INM6 status to draft
          AccountActions.createSiteForAccountSuccess(code),
          createdAccount.addAccountDetails
            ? StepperActions.accountUpdateStep(3)
            : AccountActions.navigateToDetailsPageAfterupdatedAccountDetailsWithoutFinalReview(accountpayload)
        ];
      }
      return [AccountActions.createSiteForAccountSuccess(code), StepperActions.accountUpdateStep(3)];
    }
  }

  private getClonedContactAndAddress(accountGroup, accountNumber, cloneAccountFields): any {
    switch (accountGroup) {
      case AccountGroup.INMA:
      case AccountGroup.INM1:
      case AccountGroup.INMB:
      case AccountGroup.INM7: {
        return {
          accountGroup: accountGroup,
          accountNumber: accountNumber,
          ...cloneAccountFields.contactAndAddressPayload
        };
      }
      case AccountGroup.INDE: {
        return {
          accountGroup: accountGroup,
          accountNumber: accountNumber,
          ...cloneAccountFields.contactAndAddressPayload,
          status: AccountStatus.DRAFT
        };
      }
      default:
        return null;
    }
  }

  private getAfterFinalReviewActions(createdAccountState, cloneAccount) {
    if (
      createdAccountState.addAccountDetails.accountGroup === AccountGroup.INDE ||
      createdAccountState.addAccountDetails.accountGroup === AccountGroup.INMB ||
      createdAccountState.addAccountDetails.accountGroup === AccountGroup.INM6
    ) {
      return [AccountActions.goToAccountDetails()];
    }
    if (createdAccountState.addAccountDetails.accountGroup === AccountGroup.INMA) {
      const payload = {
        ...createdAccountState.addAccountDetails,
        ...createdAccountState.assignSalesTeamMembers,
        address: createdAccountState.billingAddress,
        contact: createdAccountState.billingContact,
        salesOrgs: createdAccountState.associatedSalesOrg
      };
      const emergedActions = [];
      if (cloneAccount === null) {
        emergedActions.push(
          StepperActions.accountCreateReset(),
          AccountActions.initiateChildAccountCreation(AccountGroup.INMB, payload)
        );
      } else {
        emergedActions.push(AccountActions.pastePrimaryINMBAAccount(cloneAccount.copyINMAAccountInfo));
        emergedActions.push(AccountActions.cloneAccount(true));
      }

      return emergedActions;
    }
  }

  private getCreteSiteWithContactAndAddressPayload(sitePayload, response, sitype) {
    if (response && typeof response === 'number') {
      sitePayload = {
        ...sitePayload,
        code: response
      };
    }
    if (response && response.addressId) {
      if (sitype === SiteCategory.Sea) {
        sitePayload = {
          ...sitePayload,
          emergencyAddress: response.addressId
        };
      } else {
        sitePayload = {
          ...sitePayload,
          address: response.addressId
        };
      }
    }
    if (response && response.contactId) {
      sitePayload = {
        ...sitePayload,
        emergencyContact: response.contactId
      };
    }
    return sitePayload;
  }

  private getCloneAccountAction(
    cloneAccountFields,
    actions,
    copiedAccountNumber,
    accountNumber,
    cloneSiteFields,
    cleBaseDetails
  ) {
    if (
      cloneAccountFields.accountGroup !== AccountGroup.INM6 &&
      cloneAccountFields.accountGroup !== AccountGroup.IM17
    ) {
      if (cloneAccountFields.accountGroup === AccountGroup.INMB && cloneAccountFields.primaryINMBAccount) {
        actions.push(
          AccountActions.updateCreatedAccountDetails({
            accountNumber: copiedAccountNumber
          })
        );
      }
      if (cloneAccountFields && cloneAccountFields?.contactAndAddressPayload) {
        const updateContactAndAddressPayload = this.getClonedContactAndAddress(
          cloneAccountFields.accountGroup,
          accountNumber,
          cloneAccountFields
        );
        actions.push(AccountActions.updateAccountAddressAndContact(updateContactAndAddressPayload));
      }
    } else {
      if (cloneAccountFields && cloneAccountFields?.contactAndAddressPayload) {
        cloneSiteFields = {
          ...cloneSiteFields,
          systemSiteIds: { ...cloneSiteFields.systemSiteIds, sap: parseInt(accountNumber, 10) }
        };
        actions.push(
          AccountActions.creteSiteWithContactAndAddress(
            cloneSiteFields,
            accountNumber,
            cleBaseDetails.legalEntityCode,
            cloneAccountFields.contactAndAddressPayload
          )
        );
      }
    }
    return actions;
  }

  private getUpdateAccountNotificationMessage(isStatusUpdate, updatedAccountDetails, cloneAccount, needsOBFs) {
    if (cloneAccount) {
      return updatedAccountDetails.accountGroup === AccountGroup.INDE
        ? addNotificationItem(
            createSuccessNotification(getStaticContent('accounts.create.clone_INDE_notification', staticContent))
          )
        : addNotificationItem(
            createSuccessNotification(getStaticContent('accounts.create.clone_none_INDE_notification', staticContent))
          );
    }
    return isStatusUpdate
      ? updatedAccountDetails.status === AccountStatus.ACTIVE
        ? addNotificationItem(
            createSuccessNotification(getStaticContent('accounts.notification.edit_status_activated', staticContent))
          )
        : addNotificationItem(
            createSuccessNotification(getStaticContent('accounts.notification.edit_status_inactivated', staticContent))
          )
      : needsOBFs
      ? addNotificationItem(
          createSuccessNotification(
            getStaticContent('accounts.notification.edit_account_details_add_obf', staticContent)
          )
        )
      : addNotificationItem(
          createSuccessNotification(getStaticContent('accounts.notification.edit_account_details', staticContent))
        );
  }

  private checkChangeAccountStatus(accountDetails, createdAccount, accountContainsValidOBFs): boolean {
    return (
      (accountDetails?.contractParty &&
        accountDetails?.dealerCode &&
        accountDetails?.managementParty &&
        (createdAccount?.outboundFeed?.outboundFeed?.length || accountContainsValidOBFs) &&
        !createdAccount.accountNumber) ||
      createdAccount.accountNumber
    );
  }

  private basicUpdateAccountAction(
    actions,
    currentAccount,
    updatedAccountDetails,
    isStatusUpdate,
    cloneAccount,
    needAddOBFsMessage,
    editAccount
  ) {
    actions.push(
      currentAccount
        ? AccountActions.detailLoad(updatedAccountDetails.accountNumber, currentAccount.legalEntityCode)
        : AccountActions.detailLoad(updatedAccountDetails.accountNumber, updatedAccountDetails.legalEntityCode),
      AccountActions.updateAccountDetailsSuccess()
    );
    actions.push(
      this.getUpdateAccountNotificationMessage(isStatusUpdate, updatedAccountDetails, cloneAccount, needAddOBFsMessage)
    );
    if (!editAccount) {
      actions.push(AccountActions.finalReviewAccount());
    }
    return actions;
  }

  private reviseAccountChangeRequestActions(actions, updatedAccountDetails) {
    updatedAccountDetails?.changeRequest?.action === IChangeRequestActions.RESUBMIT
      ? actions.push(ChangeRequestActions.sendResponseOfChangeRequest(updatedAccountDetails.changeRequest))
      : actions.push(ChangeRequestActions.createChangeRequest(updatedAccountDetails.changeRequest));
    return actions;
  }

  private postAccountChangeRequest(actions, ChangeRequest) {
    ChangeRequest.changeRequest.requestType === IChangeRequestOptions.CREATE
      ? actions.push(ChangeRequestActions.createChangeRequest(ChangeRequest.changeRequest))
      : actions.push(ChangeRequestActions.sendResponseOfChangeRequest(ChangeRequest.changeRequest));
    return actions;
  }

  private updateSiteAssociatdWithAccountActions(
    actions,
    currentAccountDetails,
    updatedAccountDetailsPayload,
    siteByAccount
  ) {
    if (
      (currentAccountDetails?.accountGroup === AccountGroup.INM6 ||
        currentAccountDetails?.accountGroup === AccountGroup.IM17) &&
      updatedAccountDetailsPayload?.name &&
      siteByAccount &&
      siteByAccount.length &&
      siteByAccount[0].code
    ) {
      const updateSiteNamePayload = {
        code: siteByAccount[0].code,
        name: updatedAccountDetailsPayload.name
      };
      actions.push(SiteActions.updateSiteDetails(updateSiteNamePayload) as any);
    }
    return actions;
  }

  private setShippingAddressAndContactPayload(accountDetails, apiCalls) {
    if (accountDetails.shippingDeletedMediums) {
      accountDetails.shippingDeletedMediums.forEach((medium) => {
        apiCalls.push(this.contactApi.deleteMedium(medium?.id, medium?.mediumType));
      });
      delete accountDetails.shippingDeletedMediums;
    }
    if (accountDetails.shippingAddress) {
      const address = accountDetails.shippingAddress;
      apiCalls.push(this.addressApi.updateAddress(accountDetails.legalEntityCode, address));
      delete accountDetails.shippingAddress;
    }
    if (accountDetails.shippingContact) {
      const contact = accountDetails.shippingContact;
      apiCalls.push(this.contactApi.updateContact(accountDetails.legalEntityCode, contact));
      delete accountDetails.shippingContact;
    }
    return apiCalls;
  }
}
