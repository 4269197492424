import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  DynamicControlType,
  getStaticContent,
  IDynamicControl,
  IRadioOption,
  IReferenceOption,
  loadReferenceByRefType,
  getAircraftClassOptions,
  getAirlineOptions,
  LegalEntityRefDataType,
  getLandTypeOptions,
  getVesselSubTypeOptions,
  getFullCountryOptions,
  getVesselMarketOptions
} from '@inmarsat-itcloudservices/ui';
import { Store } from '@ngrx/store';
import { pick } from 'ramda';
import { Observable, of } from 'rxjs';
import { staticContent } from '@app/app.constants';
import { IState } from '@app/shared-store';
import { IAircraftDetails, ILandDetails, ISeaDetails, ISiteDetail, SiteCategory } from '@shared/models/site.model';

const YES_NO: IRadioOption[] = [
  {
    label: getStaticContent('common.yes', staticContent),
    value: 'Y'
  },
  {
    label: getStaticContent('common.no', staticContent),
    value: 'N'
  }
];

type SeaFormFields =
  | 'homePort'
  | 'portOfRegistry'
  | 'personsOnBoard'
  | 'seaGoing'
  | 'callSign'
  | 'selfPropelled'
  | 'grossTonnage'
  | 'yearOfManufacture'
  | 'imoNumber'
  | 'mmsi'
  | 'country';

export type SeaFormModel = Pick<ISeaDetails, SeaFormFields>;

const SITES_DETAILS = 'accounts.create.site';

export interface IQuestionSection {
  title: string;
  staticContext?: string;
  questions: IDynamicControl[];
}

export const VESSEL_CATEGORIES: Record<string, string[] | null> = {
  'Aid/NGO': null,
  'Civil Government': null,
  'Health/Education Government': null,
  'Large Yacht and Passenger': ['Larger Yacht', 'Passenger'],
  'Local Government': null,
  'Media/Event': null,
  Merchant: ['Bulk', 'Container Ship', 'Tanker', 'Other Merchant/Miscellaneous'],
  'Military Government': null,
  'National Government': null,
  'Offshore and High-End Fishing': ['High-End Fishing', 'Offshore Vessel', 'Rigs & Platform'],
  'Small Boat': ['Fishing', 'Leisure', 'Workboat']
};

@Injectable({
  providedIn: 'root'
})
export class SiteFormService {
  public isEditingSite: boolean;

  constructor(private readonly store: Store<IState>) {}

  public getVesselCategories(): Observable<IReferenceOption[]> {
    return of([...Object.keys(VESSEL_CATEGORIES)].map((value) => ({ value, label: value })));
  }

  public getVesselSubcategories(parent: string): Observable<IReferenceOption[]> {
    const category = VESSEL_CATEGORIES[parent];
    return of(category ? category.map((value) => ({ value, label: value })) : null);
  }

  public getAircraftCategories(): Observable<IReferenceOption[]> {
    return this.store.select(getAircraftClassOptions);
  }

  public getVesselMarketCategories(): Observable<IReferenceOption[]> {
    return this.store.select(getVesselMarketOptions);
  }

  public getSeaQuestions(): any {
    this.store.dispatch(loadReferenceByRefType(LegalEntityRefDataType.VesselSubType));
    return this.mapSections(SITES_DETAILS, [
      {
        title: 'title',
        staticContext: 'vessel_information',
        questions: [
          {
            key: 'country',
            label: 'country_of_registration',
            placeholder: 'select_country',
            type: DynamicControlType.Typeahead,
            validators: [Validators.required],
            suppressOptionalLabel: true,
            multi: false,
            options: this.store.select(getFullCountryOptions),
            columns: this.isEditingSite ? '4' : '6'
          },
          {
            key: 'subType',
            label: 'subType',
            placeholder: 'select_subType',
            type: DynamicControlType.Typeahead,
            validators: [Validators.required],
            suppressOptionalLabel: true,
            multi: false,
            options: this.store.select(getVesselSubTypeOptions),
            columns: this.isEditingSite ? '4' : '6'
          },
          {
            key: 'homePort',
            label: 'home_port',
            placeholder: 'enter_home_port',
            type: DynamicControlType.Text,
            validators: [Validators.required, Validators.maxLength(50)],
            suppressOptionalLabel: true,
            columns: this.isEditingSite ? '4' : '6'
          },
          {
            key: 'portOfRegistry',
            label: 'port_of_registry',
            placeholder: 'enter_port_of_registry',
            type: DynamicControlType.Text,
            validators: [Validators.required, Validators.maxLength(50)],
            suppressOptionalLabel: true,
            columns: this.isEditingSite ? '4' : '6'
          },
          {
            key: 'callSign',
            label: 'call_sign',
            placeholder: 'enter_call_sign',
            type: DynamicControlType.Text,
            validators: [Validators.required, Validators.pattern('^[A-Za-z+0-9]*$'), Validators.maxLength(10)],
            suppressOptionalLabel: true,
            columns: this.isEditingSite ? '4' : '6'
          },
          {
            key: 'grossTonnage',
            label: 'gross_tonnage',
            placeholder: 'enter_number',
            type: DynamicControlType.Number,
            validators: [Validators.required, Validators.pattern('^[+0-9]*$'), Validators.maxLength(10)],
            suppressOptionalLabel: true,
            isIntegerValue: true,
            columns: this.isEditingSite ? '4' : '6'
          },
          {
            key: 'imoNumber',
            label: 'imo_number',
            placeholder: 'enter_imo_number',
            type: DynamicControlType.Text,
            validators: [Validators.required, Validators.pattern('^[+0-9]{7}$')], // 7 digits
            suppressOptionalLabel: true,
            columns: this.isEditingSite ? '4' : '6'
          },
          {
            key: 'mmsi',
            label: 'mmsi',
            placeholder: 'enter_mmsi_number',
            type: DynamicControlType.Text,
            validators: [Validators.required, Validators.pattern('^[+0-9]{9}$')], // 9 digits
            suppressOptionalLabel: true,
            columns: this.isEditingSite ? '4' : '6'
          },
          {
            key: 'selfPropelled',
            label: 'self_propelled_flag',
            type: DynamicControlType.Radio,
            validators: [Validators.required],
            default: YES_NO[1].value,
            placeholder: 'YES_NO',
            options: YES_NO,
            columns: this.isEditingSite ? '4' : '6'
          },
          {
            key: 'yearOfManufacture',
            label: 'year_of_manufacture',
            placeholder: 'e_g_1975',
            type: DynamicControlType.Text,
            validators: [Validators.pattern('^[+0-9]{4}$')], // 4 digits,
            columns: this.isEditingSite ? '4' : '6'
          },
          {
            key: 'seaGoing',
            label: 'sea_going_flag',
            type: DynamicControlType.Radio,
            validators: [Validators.required],
            default: YES_NO[1].value,
            placeholder: 'YES_NO',
            options: YES_NO,
            columns: this.isEditingSite ? '4' : '6'
          },
          {
            key: 'personsOnBoard',
            label: 'persons_on_board',
            placeholder: 'enter_number',
            type: DynamicControlType.Number,
            validators: [Validators.max(9999)],
            isIntegerValue: true,
            columns: this.isEditingSite ? '4' : '6'
          }
        ]
      }
    ]);
  }

  public getAirQuestions(): any {
    return this.mapSections(SITES_DETAILS, [
      {
        title: 'title',
        staticContext: 'aircraft_information',
        questions: [
          {
            key: 'country',
            label: 'country_of_registration',
            placeholder: 'select_country',
            type: DynamicControlType.Typeahead,
            validators: [Validators.required],
            multi: false,
            options: this.store.select(getFullCountryOptions),
            columns: this.isEditingSite ? '4' : '6'
          },
          {
            key: 'aircraftModel',
            label: 'aircraft_model',
            placeholder: 'enter_aircraft_model',
            type: DynamicControlType.Text,
            validators: [],
            columns: this.isEditingSite ? '4' : '6'
          },
          {
            key: 'airlineName',
            label: 'airline_name',
            placeholder: 'enter_airline_name',
            type: DynamicControlType.Typeahead,
            multi: false,
            options: this.store.select(getAirlineOptions),
            validators: [],
            columns: this.isEditingSite ? '4' : '6'
          },
          {
            key: 'icaoNumber',
            label: 'icao_number',
            placeholder: 'enter_number_max_24',
            type: DynamicControlType.Text,
            validators: [Validators.max(24)],
            columns: this.isEditingSite ? '4' : '6'
          },
          {
            key: 'tailNumber',
            label: 'tail_number',
            placeholder: 'enter_tail_number',
            type: DynamicControlType.Text,
            validators: [],
            columns: this.isEditingSite ? '4' : '6'
          },
          {
            key: 'yearOfManufacture',
            label: 'year_of_manufacture',
            placeholder: 'e_g_1975',
            type: DynamicControlType.Text,
            validators: [Validators.pattern('^[+0-9]{4}$')], // 4 digits
            columns: this.isEditingSite ? '4' : '6'
          }
        ]
      }
    ]);
  }

  public getLandQuestions(): IQuestionSection[] {
    this.store.dispatch(loadReferenceByRefType(LegalEntityRefDataType.LandType));
    return this.mapSections(SITES_DETAILS, [
      {
        title: 'title',
        staticContext: 'land_information',
        questions: [
          {
            key: 'country',
            label: 'country_of_installation',
            placeholder: 'select_country',
            type: DynamicControlType.Typeahead,
            validators: [Validators.required],
            multi: false,
            options: this.store.select(getFullCountryOptions),
            columns: this.isEditingSite ? '4' : '6'
          },
          {
            key: 'landCategory',
            label: 'land_category',
            placeholder: 'select_land_category',
            type: DynamicControlType.Dropdown,
            validators: [],
            multi: false,
            options: this.store.select(getLandTypeOptions),
            columns: this.isEditingSite ? '4' : '6'
          }
        ]
      }
    ]);
  }

  public mapSiteToFormModel(site: ISiteDetail): any {
    if (site) {
      let secondary: SeaFormModel | IAircraftDetails | ILandDetails;
      let category: string = null;
      let subcategory: string = null;
      const { aircraftModel, icaoNumber, tailNumber, airlineName, country, yearOfManufacture } = site;
      switch (site.type) {
        case SiteCategory.Sea:
          category = site.vesselMarket;
          subcategory = site.vesselSector;

          secondary = pick(
            [
              'homePort',
              'portOfRegistry',
              'personsOnBoard',
              'seaGoing',
              'callSign',
              'selfPropelled',
              'grossTonnage',
              'yearOfManufacture',
              'imoNumber',
              'mmsi',
              'country',
              'subType'
            ],
            site
          );
          break;
        case SiteCategory.Air:
          category = site.aircraftClass;

          secondary = {
            tailNumber,
            country,
            aircraftModel,
            airlineName,
            icaoNumber,
            yearOfManufacture: yearOfManufacture || null
          };

          break;
        case SiteCategory.Land:
          secondary = {
            country: site.country,
            landCategory: site.landCategory
          };
          break;
        default:
          // Something's gone wrong.
          break;
      }

      return {
        secondary,
        primary: {
          category,
          subcategory,
          name: site.name,
          type: site.type,
          status: site.status
        }
      };
    }

    return {
      primary: {
        siteName: null,
        type: null,
        category: null,
        subcategory: null
      }
    };
  }

  private mapSections(rootContext: string, sections: IQuestionSection[]): any {
    return sections.map(({ title, questions, staticContext }) => ({
      title: getStaticContent(`${rootContext}.${staticContext}.${title}`, staticContent),
      questions: questions.map((q) => ({
        ...q,
        label: getStaticContent(`${rootContext}.${staticContext}.${q.label}`, staticContent),
        placeholder: getStaticContent(`${rootContext}.${staticContext}.${q.placeholder}`, staticContent)
      }))
    }));
  }
}
