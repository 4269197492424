import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthConfigService, authReducer, CallbackComponent } from '@inmarsat-itcloudservices/ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { environment } from '@env/environment';
import { AuthEffects } from '@shared-store/auth/auth.effects';
import { HttpsRequestInterceptor } from '../services/http-interceptor/http-interceptor.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('auth', authReducer),
    EffectsModule.forFeature([AuthEffects]),
    RouterModule.forChild([
      {
        path: 'implicit/callback',
        component: CallbackComponent
      }
    ]),
    OktaAuthModule
  ],
  providers: [
    {
      provide: AuthConfigService,
      useValue: {
        okta: environment.okta
      }
    },
    { provide: OKTA_CONFIG, useValue: environment.okta },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true
    }
  ]
})
export class AuthModule {}
