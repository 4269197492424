import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuardService, AuthStoreModule, TitleServiceModule } from '@inmarsat-itcloudservices/ui';
import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@env/environment';
import { AccountsEffects } from '@shared-store/account/account.effects';
import { AppEffects } from '@shared-store/app.effects';
import { AttachmentEffects } from '@shared-store/attachment/attachment.effects';
import { AuthEffects } from '@shared-store/auth/auth.effects';
import { CLEsEffects } from '@shared-store/cle/cle.effects';
import { CorporateGroupsEffects } from '@shared-store/corporate-groups/corporate-groups.effects';
import { NotificationsEffects } from '@shared-store/notifications/notifications.effects';
import { ReferenceDataEffects } from '@shared-store/reference-data/reference-data.effects';
import { SalesEffects } from '@shared-store/sales/sales.effects';
import { SiteEffects } from '@shared-store/site/site.effects';
import { SystemStatusEffects } from '@shared-store/system-status/system-status.effects';
import { TerminalGroupsEffects } from '@shared-store/terminal-groups/terminal-groups.effects';
import { UploadEffects } from '@shared-store/upload/upload-file.effects';
import { AuthResource } from '@shared/models/auth.model';
import { CustomRouterStateSerializer } from '@shared/routing/custom-serializer';
import { SharedModule } from '@shared/shared.module';
import { OutboundFeedEffects } from '@shared-store/outbound-feed/outbound-feed.effects';
import { HierarchyEffects } from '@shared-store/hierarchy-tree/hierarchy-tree.effect';
import { AuthModule } from './auth/auth.module';
import { reducers } from './shared-store';
import { HttpRequestInteceptorError } from './services/http-interceptor-error/http-request-inteceptor-error.service';
import { CoreModule } from './core/core.module';
import { AppComponent } from './core/containers/app.component';
import { staticContent } from './app.constants';
import { AppRoutingModule } from './app-routing.module';
import { ApiModule } from './api/api.module';
import { ChangeRequestEffects } from './shared-store/change-request/change-request.effects';

const INM_STORE_EFFECTS = [
  AppEffects,
  AuthEffects,
  CLEsEffects,
  CorporateGroupsEffects,
  NotificationsEffects,
  ReferenceDataEffects,
  SalesEffects,
  AccountsEffects,
  AttachmentEffects,
  OutboundFeedEffects,
  SiteEffects,
  HierarchyEffects,
  TerminalGroupsEffects,
  SystemStatusEffects,
  UploadEffects,
  OutboundFeedEffects,
  ChangeRequestEffects
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    /**
     * Angular
     */
    AuthModule,
    AuthStoreModule.forRoot({
      authResources: AuthResource,
      config: {
        environment: {
          production: environment.production,
          postLogoutRedirectUri: environment.okta.postLogoutRedirectUri
        }
      }
    }),
    CommonModule,
    BrowserModule,
    NoopAnimationsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ApiModule.forRoot(),
    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        // These error in IE, so we need to disable them:
        // https://github.com/ngrx/platform/issues/1991
        strictStateImmutability: !environment.disableNgRxChecks,
        strictActionImmutability: !environment.disableNgRxChecks,
        strictStateSerializability: !environment.disableNgRxChecks,
        strictActionSerializability: !environment.disableNgRxChecks
      }
    }),
    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot({
      /*
        They stateKey defines the name of the state used by the router-store reducer.
        This matches the key defined in the map of reducers
      */
      stateKey: 'router',
      routerState: RouterState.Minimal,
      serializer: CustomRouterStateSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation
    }),
    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    StoreDevtoolsModule.instrument({
      name: 'CMD Store DevTools',
      logOnly: environment.production,
      serialize: environment.production ? undefined : true
    }),
    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     *
     * See: https://github.com/ngrx/platform/blob/master/docs/effects/api.md#forroot
     */
    EffectsModule.forRoot([...INM_STORE_EFFECTS]),
    TitleServiceModule.forRoot({ content: staticContent }),
    /**
     * Shared Module
     * Includes UI common components
     * and 3rd party libraries
     */
    SharedModule,
    /**
     * App routing
     */
    AppRoutingModule,
    /**
     * CoreModule.forRoot() is imported once in the root module and
     * is the actual entry point of the application.
     */
    CoreModule.forRoot()
  ],
  providers: [
    AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInteceptorError,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
