import { Action, createReducer, on } from '@ngrx/store';
import { IOutboundFeed } from '@shared/models/outbound-feed.model';
import {
  IAccountDetails,
  IAccountPartnerInfo,
  IAccountQuery,
  IAddAccountPayload,
  IAddSalesTeamMemberPayload,
  IMoveAccountResponse,
  ISalesOrg,
  AccountGroup
} from '@shared/models/account.model';
import { IAddress, AddressType } from '@shared/models/address.model';
import { IContact, ContactType } from '@shared/models/contact.model';
import { IPricingAgreement } from '@shared/models/pricing-agreement.model';
import { ISiteConfig, ISiteDetail } from '@shared/models/site.model';
import { flatten } from 'ramda';
import * as AccountActions from './account.actions';

/**
 * Account state
 */
export interface IAccountState {
  allAccountlist: {
    loaded: boolean;
    items: IAccountDetails[];
    totalCount: number;
    query: IAccountQuery;
  };
  postingAccountList: {
    loaded: boolean;
    items: IAccountDetails[];
    totalCount: number;
    query: IAccountQuery;
  };
  parentAccount: IAccountDetails;
  parentAccountList: {
    loaded: boolean;
    items: IAccountDetails[];
  };
  createAccount: {
    accountGroup: string;
    addAccountDetails: IAddAccountPayload;
    assignSalesTeamMembers: IAddSalesTeamMemberPayload;
    associatedSalesOrg: ISalesOrg[];
    accountNumber: string;
    outboundFeed: any;
    childAccountGroup: string;
    billingContact: IContact;
    billingAddress: IAddress;
    siteAddress: IAddress;
    siteEmergencyContact: IContact;
    siteEmergencyAddress: IAddress;
    shippingContact: IContact;
    shippingAddress: IAddress;
    site: {
      siteDetails: ISiteConfig;
    };
    additionalInfo?: IAccountPartnerInfo;
  };
  accountDetails: IAccountDetails;
  siteDetails: ISiteDetail[];
  partnerInfo: IAccountPartnerInfo;
  postingAccounts: {
    loaded: boolean;
    items: IAccountDetails[];
    totalCount: number;
  };
  childAccounts: {
    loaded: boolean;
    items: IAccountDetails[];
    totalCount: number;
  };
  siblingAccounts: {
    loaded: boolean;
    items: IAccountDetails[];
    totalCount: number;
  };
  pricingLinks: {
    loaded: boolean;
    items: IPricingAgreement[];
    totalCount: number;
  };
  dealers: {
    loaded: boolean;
    items: IAccountDetails[];
  };
  shipOwners: {
    loaded: boolean;
    items: IAccountDetails[];
  };
  contractPartnerInfo: {
    loaded: boolean;
    items: IAccountDetails[];
  };
  managementCompanyInfo: {
    loaded: boolean;
    items: IAccountDetails[];
  };
  editAccount: {
    isEditing: boolean;
    editPanelName: string;
  };
  activeChildAccounts: {
    loaded: boolean;
    items: IAccountDetails[];
    totalCount: number;
  };
  moveAccount: {
    moveAccountResponse: IMoveAccountResponse;
    moveAccountChangeRequest: any;
  };
  cloneAccount: {
    copyCurrentAccount: any;
    isAccountCopied: boolean;
    createPrimaryINMB: boolean;
    copyPostingAccountNumber: string;
    copyINMAAccountInfo: any;
    accountNumber: string;
    contactAndAddressPayload: any;
    associatedSalesOrg: any;
    accountGroup: string;
    siteContact: IContact;
    siteAddress: IAddress;
    primaryINMBAccount: any;
    siteInfo: any;
  };
  arAccountOBFs: {
    loaded: boolean;
    items: IOutboundFeed[];
    totalCount: number;
  };
  shippingAddressAndContact: {
    loading: boolean;
    isEditing: boolean;
    shippingAddress: IAddress;
    shippingContact: IContact;
  };
  sendINMAINMB: {
    allowSend: boolean;
    INMAOrINMBInfo: IAccountDetails;
  };
}

/**
 * getInitialState returns the default initial state
 * for the generated entity state. Initial state
 * additional properties can also be defined.
 */
export const initialState: IAccountState = {
  allAccountlist: {
    loaded: false,
    items: [],
    totalCount: 0,
    query: null
  },
  postingAccountList: {
    loaded: false,
    items: [],
    totalCount: 0,
    query: null
  },
  parentAccount: null,
  parentAccountList: {
    loaded: false,
    items: null
  },
  createAccount: {
    accountGroup: null,
    addAccountDetails: null,
    assignSalesTeamMembers: null,
    associatedSalesOrg: null,
    outboundFeed: null,
    accountNumber: null,
    childAccountGroup: null,
    billingContact: null,
    billingAddress: null,
    shippingContact: null,
    shippingAddress: null,
    siteAddress: null,
    siteEmergencyContact: null,
    siteEmergencyAddress: null,
    site: {
      siteDetails: null
    },
    additionalInfo: null
  },
  accountDetails: null,
  siteDetails: null,
  partnerInfo: null,
  editAccount: {
    isEditing: false,
    editPanelName: null
  },
  postingAccounts: {
    loaded: false,
    items: [],
    totalCount: 0
  },
  childAccounts: {
    loaded: false,
    items: [],
    totalCount: 0
  },
  siblingAccounts: {
    loaded: false,
    items: [],
    totalCount: 0
  },
  pricingLinks: {
    loaded: false,
    items: [],
    totalCount: 0
  },
  dealers: {
    loaded: false,
    items: []
  },
  shipOwners: {
    loaded: false,
    items: []
  },
  contractPartnerInfo: {
    loaded: false,
    items: []
  },
  managementCompanyInfo: {
    loaded: false,
    items: []
  },
  activeChildAccounts: {
    loaded: false,
    items: [],
    totalCount: 0
  },
  arAccountOBFs: {
    loaded: false,
    items: [],
    totalCount: 0
  },
  moveAccount: null,
  cloneAccount: null,
  shippingAddressAndContact: {
    isEditing: false,
    loading: false,
    shippingAddress: null,
    shippingContact: null
  },
  sendINMAINMB: {
    allowSend: false,
    INMAOrINMBInfo: null
  }
};

export function getSalesOrgsPayload(response: any, salesOrgPayload: any): ISalesOrg[] {
  return salesOrgPayload.map((salesOrg) =>
    salesOrg.postingAccount === undefined ? { ...salesOrg, postingAccount: response } : salesOrg
  );
}

export function setIdIntoOBF(respones: any, obfs: any): IOutboundFeed {
  // When user create BP obf, the OBFs list can be mixed with inheritance and non-inheritance
  // inheirtance response is object, non-inheirtance response is string

  const flattedResponse = flatten(respones);
  return obfs.outboundFeed.map((cur, i) => {
    cur._id = flattedResponse[i];
    return cur;
  });
}

export function deleteShippingId(state: any, accountNumber: string, shippingInfoId: string): any {
  delete state.shippingAddressAndContact[`${accountNumber}.${shippingInfoId}`]; //Reload the shippingInfo by id after user updates shipping info
  state.shippingAddressAndContact.isEditing = false;
  return state.shippingAddressAndContact;
}

export const accountReducer = createReducer(
  initialState,
  on(AccountActions.reset, () => initialState),
  on(AccountActions.updateAccountGroup, (state, { payload }) => ({
    ...state,
    createAccount: {
      ...state.createAccount,
      accountGroup: payload
    }
  })),
  on(AccountActions.loadAllAccount, (state) => ({
    ...state,
    allAccountlist: {
      loaded: false,
      items: [],
      totalCount: state.allAccountlist.totalCount,
      query: null
    }
  })),
  on(AccountActions.loadAllAccountSuccess, (state, { response }) => ({
    ...state,
    allAccountlist: {
      loaded: true,
      items: response ? response.assets : null,
      totalCount: response ? response.count : null,
      query: response ? response.query : null
    }
  })),
  on(AccountActions.loadPostingAccount, (state) => ({
    ...state,
    postingAccountList: {
      loaded: false,
      items: [],
      totalCount: state.postingAccountList.totalCount,
      query: null
    }
  })),
  on(AccountActions.loadPostingAccountSuccess, (state, { response }) => ({
    ...state,
    postingAccountList: {
      loaded: true,
      items: response ? response.assets : null,
      totalCount: response ? response.count : null,
      query: response ? response.query : null
    }
  })),
  on(AccountActions.detailLoad, (state) => ({
    ...state,
    editAccount: initialState.editAccount,
    accountDetails: initialState.accountDetails
  })),
  on(AccountActions.detailLoadSuccess, (state, { response, cleDetails }) => ({
    ...state,
    accountDetails:
      response && cleDetails
        ? {
            ...response,
            corporateGroupCode: cleDetails.parentCode,
            corporateGroupName: cleDetails.parentName
          }
        : response && !cleDetails
        ? response
        : null
  })),
  on(AccountActions.addressLoadSuccess, (state, { response }) => ({
    ...state,
    accountDetails: {
      ...state.accountDetails,
      address: response ? response : null
    }
  })),
  on(AccountActions.loadShippingAddressAndContactById, (state, { accountCode, shippingId }) => ({
    ...state,
    shippingAddressAndContact: {
      ...state.shippingAddressAndContact,
      isEditing: false,
      loading: true,
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      [`${accountCode}.${shippingId}`]: {
        shippingAddress: null,
        shippingContact: null
      }
    }
  })),
  on(
    AccountActions.loadShippingAddressAndContactByIdSuccess,
    (state, { accountCode, shippingInfoId, shippingAddress, shippingContact }) => ({
      ...state,
      shippingAddressAndContact: {
        ...state.shippingAddressAndContact,
        isEditing: false,
        loading: false,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        [`${accountCode}.${shippingInfoId}`]: {
          shippingAddress: shippingAddress,
          shippingContact: shippingContact
        }
      }
    })
  ),
  on(AccountActions.editShippingInfo, (state) => ({
    ...state,
    shippingAddressAndContact: {
      ...state.shippingAddressAndContact,
      isEditing: true
    }
  })),
  //RESET ADDRESS CONTACT BY ID
  on(AccountActions.editShippingInfoSuccess, (state, { accountNumber, shippingInfoId }) => ({
    ...state,
    shippingAddressAndContact: deleteShippingId(state, accountNumber, shippingInfoId)
  })),
  on(AccountActions.cancelEditShippingInfo, (state) => ({
    ...state,
    shippingAddressAndContact: {
      ...state.shippingAddressAndContact,
      isEditing: false
    }
  })),
  on(AccountActions.accountNotFound, (state) => ({
    ...state,
    allAccountlist: {
      ...state.allAccountlist,
      loaded: true,
      items: []
    }
  })),
  on(AccountActions.editAccountDetails, (state, { editPanelName }) => ({
    ...state,
    editAccount: {
      editPanelName,
      isEditing: true
    }
  })),
  on(AccountActions.cancelEditAccountDetails, (state) => ({
    ...state,
    editAccount: initialState.editAccount
  })),
  on(AccountActions.updateAccountDetailsSuccess, (state) => ({
    ...state,
    editAccount: initialState.editAccount
  })),
  on(AccountActions.childAccountsLoad, (state) => ({
    ...state,
    childAccounts: {
      loaded: false,
      items: [],
      totalCount: 0
    }
  })),
  on(AccountActions.childAccountsLoadSuccess, (state, { response }) => ({
    ...state,
    childAccounts: {
      loaded: true,
      items: response ? response.assets : null,
      totalCount: response ? response.count : null
    }
  })),
  on(AccountActions.accountBillingContactLoadSuccess, (state, { response }) => ({
    ...state,
    accountDetails: {
      ...state.accountDetails,
      contact: response ? response : null
    }
  })),
  on(AccountActions.updateAccountAddressAndContact, (state, { accountDetails }) => ({
    ...state,
    accountDetails,
    createAccount: {
      ...state.createAccount,
      addAccountDetails: { ...state.createAccount.addAccountDetails, ...accountDetails },
      associatedSalesOrg: accountDetails.salesOrgs ? accountDetails.salesOrgs : state.createAccount.associatedSalesOrg,
      billingContact: accountDetails.contact ? accountDetails.contact : state.createAccount.billingContact,
      billingAddress: accountDetails.address ? accountDetails.address : state.createAccount.billingAddress
    }
  })),
  on(AccountActions.loadPartnerByAccount, (state) => ({
    ...state,
    partnerInfo: state.partnerInfo
  })),
  on(AccountActions.loadPartnerByAccountSuccess, (state, { response }) => ({
    ...state,
    partnerInfo: response ? response : null
  })),
  on(AccountActions.loadSiteDetailsByAccount, (state) => ({
    ...state,
    siteDetails: initialState.siteDetails
  })),
  on(AccountActions.loadSiteDetailsByAccountSuccess, (state, { response }) => ({
    ...state,
    siteDetails: response ? response.assets : null
  })),
  on(AccountActions.loadPricingLinksByAccount, (state) => ({
    ...state,
    pricingLinks: {
      loaded: false,
      items: [],
      totalCount: 0
    }
  })),
  on(AccountActions.loadPricingLinksByAccountSuccess, (state, { response }) => ({
    ...state,
    pricingLinks: {
      loaded: true,
      items: response ? response.assets : null,
      totalCount: response ? response.count : null
    }
  })),
  on(AccountActions.createAccount, (state, { payload, salesOrgPayload }) => ({
    ...state,
    createAccount: {
      ...initialState.createAccount,
      associatedSalesOrg: salesOrgPayload,
      addAccountDetails: payload,
      accountNumber: null
    },
    cloneAccount: initialState.cloneAccount
  })),
  on(AccountActions.createAccountSuccess, (state, { response }) => ({
    ...state,
    createAccount: {
      ...state.createAccount,
      accountNumber: response ? response : null,
      associatedSalesOrg:
        state.createAccount.associatedSalesOrg && state.createAccount.associatedSalesOrg[0].postingAccount === undefined
          ? getSalesOrgsPayload(response, state.createAccount.associatedSalesOrg)
          : state.createAccount.associatedSalesOrg
    }
  })),
  on(AccountActions.associateSalesOrganisation, (state, { payload }) => ({
    ...state,
    createAccount: {
      ...state.createAccount,
      associatedSalesOrg: payload
    }
  })),
  on(AccountActions.createAccountAddress, (state, { address }) => ({
    ...state,
    createAccount: {
      ...state.createAccount,
      billingAddress: address.type === AddressType.BILLING ? address : state.createAccount.billingAddress,
      shippingAddress: address.type === AddressType.SHIPPING ? address : state.createAccount.shippingAddress,
      siteAddress: address.type === AddressType.SITE ? address : state.createAccount.siteAddress,
      siteEmergencyAddress: address.type === AddressType.EMERGENCY ? address : state.createAccount.siteEmergencyAddress
    }
  })),
  on(AccountActions.createAccountAddressSuccess, (state, { addressId, addressType }) => ({
    ...state,
    createAccount: {
      ...state.createAccount,
      billingAddress:
        addressType === AddressType.BILLING
          ? {
              ...state.createAccount.billingAddress,
              id: addressId
            }
          : state.createAccount.billingAddress,
      shippingAddress:
        addressType === AddressType.SHIPPING
          ? {
              ...state.createAccount.shippingAddress,
              id: addressId
            }
          : state.createAccount.shippingAddress,
      siteAddress:
        addressType === AddressType.SITE
          ? {
              ...state.createAccount.siteAddress,
              id: addressId
            }
          : state.createAccount.siteAddress,
      siteEmergencyAddress:
        addressType === AddressType.EMERGENCY
          ? {
              ...state.createAccount.siteEmergencyAddress,
              id: addressId
            }
          : state.createAccount.siteEmergencyAddress
    }
  })),
  on(AccountActions.createAccountContact, (state, { contact }) => ({
    ...state,
    createAccount: {
      ...state.createAccount,
      billingContact: contact.type === ContactType.BILLING ? contact : state.createAccount.billingContact,
      shippingContact: contact.type === ContactType.SHIPPING ? contact : state.createAccount.shippingContact,
      siteEmergencyContact: contact.type === ContactType.EMERGENCY ? contact : state.createAccount.siteEmergencyContact
    }
  })),
  on(AccountActions.createAccountContactSuccess, (state, { contactId, contactType }) => ({
    ...state,
    createAccount: {
      ...state.createAccount,
      billingContact:
        contactType === ContactType.BILLING
          ? {
              ...state.createAccount.billingContact,
              id: contactId
            }
          : state.createAccount.billingContact,
      shippingContact:
        contactType === ContactType.SHIPPING
          ? {
              ...state.createAccount.shippingContact,
              id: contactId
            }
          : state.createAccount.shippingContact,
      siteEmergencyContact:
        contactType === ContactType.EMERGENCY
          ? {
              ...state.createAccount.siteEmergencyContact,
              id: contactId
            }
          : state.createAccount.siteEmergencyContact
    }
  })),
  on(AccountActions.assignSalesTeamMember, (state, { payload }) => ({
    ...state,
    createAccount: {
      ...state.createAccount,
      assignSalesTeamMembers: payload
    }
  })),
  on(AccountActions.assignAccountDealer, (state, { payload }) => ({
    ...state,
    createAccount: {
      ...state.createAccount,
      additionalInfo: {
        dealerCode: payload.dealerCode
      }
    }
  })),
  on(AccountActions.saveAdditionalInformation, (state, { payload }) => ({
    ...state,
    createAccount: {
      ...state.createAccount,
      additionalInfo: {
        contractParty: payload.contractParty,
        contractPartyName: payload.contractPartyName,
        managementParty: payload.managementParty,
        managementPartyName: payload.managementPartyName,
        shipOwner: payload.shipOwner,
        shipOwnerName: payload.shipOwnerName,
        dealerCode: payload.dealerCode,
        dealerCodeName: payload.dealerCodeName
      }
    }
  })),
  on(AccountActions.loadDealerList, (state) => ({
    ...state,
    dealers: {
      items: [],
      loaded: false
    }
  })),
  on(AccountActions.loadDealerListSuccess, (state, { response }) => ({
    ...state,
    dealers: {
      loaded: true,
      items: response ? response.assets : null
    }
  })),
  on(AccountActions.initiateChildAccountCreation, (state, { childAccountGroup, parentAccount }) => ({
    ...state,
    parentAccount: {
      ...parentAccount
    },
    createAccount: {
      ...initialState.createAccount,
      childAccountGroup
    },
    cloneAccount: initialState.cloneAccount
  })),
  on(AccountActions.loadContractPartnerInfo, (state) => ({
    ...state,
    contractPartnerInfo: {
      items: [],
      loaded: false
    }
  })),
  on(AccountActions.loadContractPartnerInfoSuccess, (state, { response }) => ({
    ...state,
    contractPartnerInfo: {
      loaded: true,
      items: response ? response.assets : []
    }
  })),
  on(AccountActions.loadManagementCompanyInfo, (state) => ({
    ...state,
    managementCompanyInfo: {
      items: [],
      loaded: false
    }
  })),
  on(AccountActions.loadManagementCompanyInfoSuccess, (state, { response }) => ({
    ...state,
    managementCompanyInfo: {
      loaded: true,
      items: response ? response.assets : []
    }
  })),
  on(AccountActions.loadShipOwnerList, (state) => ({
    ...state,
    shipOwners: {
      items: [],
      loaded: false
    }
  })),
  on(AccountActions.loadShipOwnerListSuccess, (state, { response }) => ({
    ...state,
    shipOwners: {
      loaded: true,
      items: response ? response.assets : null
    }
  })),
  on(AccountActions.createSiteForAccount, (state, { payload }) => ({
    ...state,
    createAccount: {
      ...state.createAccount,
      site: {
        ...state.createAccount.site,
        siteDetails: payload
      }
    }
  })),
  on(AccountActions.createSiteForAccountSuccess, (state, { response }) => ({
    ...state,
    createAccount: {
      ...state.createAccount,
      site: {
        ...state.createAccount.site,
        siteDetails: {
          ...state.createAccount.site.siteDetails,
          code: response
        }
      }
    }
  })),
  on(AccountActions.resetCreateAccount, (state) => ({
    ...state,
    createAccount: initialState.createAccount,
    parentAccount: initialState.parentAccount
  })),
  on(AccountActions.resetParentAccount, (state) => ({
    ...state,
    parentAccount: initialState.parentAccount
  })),
  on(AccountActions.resetCurrentAccount, (state) => ({
    ...state,
    accountDetails: initialState.accountDetails
  })),
  on(AccountActions.loadSiblingAccounts, (state) => ({
    ...state,
    siblingAccounts: {
      loaded: false,
      items: [],
      totalCount: 0
    }
  })),
  on(AccountActions.loadSiblingAccountsSuccess, (state, { response }) => ({
    ...state,
    siblingAccounts: {
      loaded: true,
      items: response ? response.assets : null,
      totalCount: response ? response.count : null
    }
  })),
  on(AccountActions.loadParentAccount, (state) => ({
    ...state,
    parentAccount: initialState.parentAccount
  })),
  on(AccountActions.loadParentAccountSuccess, (state, { parentAccount }) => ({
    ...state,
    parentAccount
  })),
  on(AccountActions.loadParentAccounts, (state) => ({
    ...state,
    parentAccountList: {
      loaded: false,
      items: []
    }
  })),
  on(AccountActions.loadParentAccountsSuccess, (state, { response }) => ({
    ...state,
    parentAccountList: {
      loaded: true,
      items: response ? response.assets : null
    }
  })),
  /* eslint-disable sonarjs/no-identical-functions */
  on(AccountActions.loadParentAccountsUnderSameCLE, (state) => ({
    ...state,
    parentAccountList: {
      loaded: false,
      items: []
    }
  })),
  /* eslint-disable sonarjs/no-identical-functions */
  on(AccountActions.loadParentAccountsUnderSameCLESuccess, (state, { response }) => ({
    ...state,
    parentAccountList: {
      loaded: true,
      items: response ? response.assets : null
    }
  })),

  on(AccountActions.loadActiveChildAccounts, (state) => ({
    ...state,
    activeChildAccounts: {
      loaded: false,
      items: [],
      totalCount: 0
    }
  })),
  on(AccountActions.loadActiveChildAccountSuccess, (state, { response }) => ({
    ...state,
    activeChildAccounts: {
      loaded: true,
      items: response ? response.assets : null,
      totalCount: response ? response.count : null
    }
  })),
  // eslint-disable-next-line sonarjs/no-identical-functions
  on(AccountActions.movePostingAccount, (state) => ({
    ...state,
    activeChildAccounts: {
      loaded: false,
      items: [],
      totalCount: 0
    }
  })),
  on(AccountActions.resetAccountMoveDetails, (state) => ({
    ...state,
    moveAccount: null
  })),
  on(AccountActions.movePostingAccountSuccess, (state, { response }) => ({
    ...state,
    moveAccount: {
      moveAccountResponse: response,
      moveAccountChangeRequest: null
    }
  })),
  on(AccountActions.loadApprovedMovedToCLEOrAccount, (state) => ({
    ...state,
    moveAccount: {
      ...state.moveAccount,
      moveAccountChangeRequest: null
    }
  })),
  on(AccountActions.loadApprovedMovedToCLEOrAccountSuccess, (state, { response }) => ({
    ...state,
    moveAccount: {
      ...state.moveAccount,
      moveAccountChangeRequest: response
    }
  })),
  on(AccountActions.moveBPAccountSuccess, (state) => ({
    ...state,
    moveAccount: null
  })),
  on(AccountActions.cloneAccount, (state, { createPrimaryINMB }) => ({
    ...state,
    cloneAccount: {
      ...state.cloneAccount,
      copyCurrentAccount: state.accountDetails,
      copyPostingAccountNumber:
        state.cloneAccount?.copyPostingAccountNumber && state.cloneAccount?.accountGroup === AccountGroup.INMA
          ? state.cloneAccount?.copyPostingAccountNumber
          : state.accountDetails?.salesOrgs[0].postingAccount,
      isAccountCopied: true,
      createPrimaryINMB: createPrimaryINMB ? createPrimaryINMB : false
    }
  })),
  on(AccountActions.cloneAccountInfo, (state, { payload, salesOrgPayload, contactAndAddressPayload, siteInfo }) => ({
    ...state,
    cloneAccount: {
      ...state.cloneAccount,
      siteInfo: siteInfo,
      associatedSalesOrg: salesOrgPayload,
      copyINMAAccountInfo: payload,
      contactAndAddressPayload: contactAndAddressPayload,
      accountGroup: payload.accountGroup
    }
  })),
  on(AccountActions.cloneAccountInfoSuccess, (state, { response }) => ({
    ...state,
    cloneAccount: {
      ...state.cloneAccount,
      accountNumber: response ? response : null,
      associatedSalesOrg:
        state.cloneAccount.associatedSalesOrg &&
        state.cloneAccount.associatedSalesOrg.salesOrgs[0].postingAccount === undefined
          ? getSalesOrgsPayload(response, state.cloneAccount.associatedSalesOrg.salesOrgs)
          : state.cloneAccount.associatedSalesOrg.salesOrgs
    },
    createAccount: {
      ...state.createAccount,
      accountNumber: response ? response : null,
      associatedSalesOrg:
        state.cloneAccount.associatedSalesOrg &&
        state.cloneAccount.associatedSalesOrg.salesOrgs[0].postingAccount === undefined
          ? getSalesOrgsPayload(response, state.cloneAccount.associatedSalesOrg.salesOrgs)
          : state.cloneAccount.associatedSalesOrg.salesOrgs,
      addAccountDetails: state.cloneAccount.copyINMAAccountInfo
    }
  })),
  on(AccountActions.loadSiteContactSuccess, (state, { contactResponse }) => ({
    ...state,
    cloneAccount: {
      ...state.cloneAccount,
      siteContact: contactResponse ? contactResponse : null
    }
  })),
  on(AccountActions.loadSiteAddressSuccess, (state, { response }) => ({
    ...state,
    cloneAccount: {
      ...state.cloneAccount,
      siteAddress: response ? response : null
    }
  })),
  on(AccountActions.cloneINMBAccountSuccess, (state, { response }) => ({
    ...state,
    cloneAccount: {
      ...state.cloneAccount,
      primaryINMBAccount: response && response.assets ? response.assets[0] : null
    }
  })),
  on(AccountActions.pastePrimaryINMBAAccount, (state, { parentAccountInfo }) => ({
    ...state,
    cloneAccount: {
      ...state.cloneAccount,
      copyINMAAccountInfo: parentAccountInfo,
      copyPostingAccountNumber: state.cloneAccount.accountNumber
    },
    accountDetails: state.cloneAccount.primaryINMBAccount
  })),
  on(AccountActions.createOBFDuringAccountCreation, (state, { fullOBFs }) => ({
    ...state,
    createAccount: {
      ...state.createAccount,
      outboundFeed: {
        ...state.createAccount.outboundFeed,
        outboundFeed: fullOBFs
      }
    }
  })),
  on(AccountActions.createOBFDuringAccountCreationSuccess, (state, { response }) => ({
    ...state,
    createAccount: {
      ...state.createAccount,
      outboundFeed: { outboundFeed: setIdIntoOBF(response, state.createAccount.outboundFeed) }
    }
  })),
  on(AccountActions.pullARAccountOBFS, (state) => ({
    ...state,
    arAccountOBFs: {
      loaded: false,
      items: [],
      totalCount: 0
    }
  })),
  on(AccountActions.pullARAccountOBFSSuccess, (state, { response }) => ({
    ...state,
    arAccountOBFs: {
      loaded: true,
      items: response ? response.assets : null,
      totalCount: response ? response.count : null
    }
  })),
  on(AccountActions.resetSendChangeRequestOnINMAOrINMB, (state) => ({
    ...state,
    sendINMAINMB: initialState.sendINMAINMB
  })),
  on(AccountActions.sendChangeRequestOnINMAOrINMBSuccess, (state, { sendRequest, accountInfo }) => ({
    ...state,
    sendINMAINMB: {
      allowSend: sendRequest,
      INMAOrINMBInfo: accountInfo
    }
  })),
  on(AccountActions.resetARAccountOBFs, (state) => ({
    ...state,
    arAccountOBFs: {
      loaded: false,
      items: [],
      totalCount: 0
    }
  }))
);
export function reducer(state: IAccountState, action: Action): IAccountState {
  return accountReducer(state, action);
}
