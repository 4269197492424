import { Component, OnInit, OnDestroy, Optional, Self, Input } from '@angular/core';
import {
  STATIC_CONTENT_CONTEXT,
  STATIC_CONTENT_PAYLOAD,
  IReferenceOption,
  getHasPermission,
  AuthAction,
  LegalEntityRefDataType,
  loadReferenceByRefType,
  getOBFFeedTypeOptions,
  getRefDataByCodeOptions
} from '@inmarsat-itcloudservices/ui';
import {
  FormGroup,
  FormControl,
  Validators,
  NgControl,
  ValidationErrors,
  ValidatorFn,
  AbstractControl,
  ControlContainer
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { getFeedFrequency, getDelivery } from '@app/outbound-feed/outbound-feed-helpers';
import { IState } from '@app/shared-store';
import { CONTACT_PATTERN_CONSTANTS, staticContent } from '@app/app.constants';
import {
  OutboundFeedFormStatus,
  OutboundFeedDelivery,
  OutboundFeedFrequency
} from '@app/shared/models/outbound-feed.model';
import { AuthResource } from '../../models/auth.model';

@Component({
  selector: 'inm-outbound-feed-form',
  templateUrl: './outbound-feed-form.component.html',
  styleUrls: ['./outbound-feed-form.component.scss'],
  providers: [
    {
      provide: STATIC_CONTENT_CONTEXT,
      useValue: 'obf_form'
    },
    {
      provide: STATIC_CONTENT_PAYLOAD,
      useValue: staticContent
    }
  ]
})
export class OutboundFeedFormComponent implements OnInit, OnDestroy {
  public feedTypeCtrl = new FormControl(null, Validators.required);

  public sendEmptyFileCtrl = new FormControl(null);

  public feedFormatCtrl = new FormControl(null, Validators.required);

  public feedFrequencyCtrl = new FormControl({ value: null, disabled: true }, Validators.required);

  public deliveryCtrl = new FormControl({ value: null, disabled: true }, Validators.required);

  public directoryCtrl = new FormControl(null);

  public zipPasswordCtrl = new FormControl(null);

  public notesCtrl = new FormControl(null);

  public zipCtrl = new FormControl(false);

  public emailAddressCtrl = new FormControl(null);

  public ccEmailAddressCtrl = new FormControl(null);

  public feedType$: Observable<IReferenceOption[]>;

  public feedFrequencyOptions$: Observable<IReferenceOption[]>;

  public subscription = new Subscription();

  public feedType: IReferenceOption[];

  public feedFormat: any[];

  public feedFrequency: any[];

  public delivery: any[];

  public onTouched: () => void;

  public hasCreateOBFPermission: boolean;

  public hasCreateSFTPPermission: boolean;

  public checkEmailValidation = false;

  public checkDirectoryValidation = false;

  @Input()
  public isUpdateOBF = false;

  public outboundFeedForm = new FormGroup({
    feedType: this.feedTypeCtrl,
    feedFormat: this.feedFormatCtrl,
    sendEmptyFile: this.sendEmptyFileCtrl,
    feedFrequency: this.feedFrequencyCtrl,
    zip: this.zipCtrl,
    delivery: this.deliveryCtrl,
    directory: this.directoryCtrl,
    zipPassword: this.zipPasswordCtrl,
    notes: this.notesCtrl,
    emailAddress: this.emailAddressCtrl,
    ccEmailAddress: this.ccEmailAddressCtrl
  });

  @Input('outboundFeedFormGroup')
  public outboundFeedFormGroup: FormGroup = this.outboundFeedForm;

  @Input('outboundFeedsValueLoaded')
  public outboundFeedsValueLoaded = false;

  public outboundFeedDeliveryValue = OutboundFeedDelivery;

  constructor(
    public controlContainer: ControlContainer,
    public readonly store: Store<IState>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.feedFormat = null;
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.store
        .pipe(getHasPermission(true, [`${AuthResource.CMD_OUTBOUNDFEED}:${AuthAction.Create}`]))
        .subscribe((allowed: boolean) => (this.hasCreateOBFPermission = allowed))
    );
    this.subscription.add(
      this.store
        .pipe(getHasPermission(true, [`${AuthResource.CMD_OUTBOUNDFEED_SFTP}:${AuthAction.Create}`]))
        .subscribe((allowed: boolean) => (this.hasCreateSFTPPermission = allowed))
    );
    if (this.ngControl) {
      const control = this.ngControl.control;
      control.setValidators(() => this.validate());
      control.updateValueAndValidity();

      const original = control.markAsTouched;

      control.markAsTouched = (...args: any[]) => {
        this.outboundFeedFormGroup.markAllAsTouched();
        original.call(args);
      };
    }

    this.getFeedType();
    this.subscribeToFeedType();
    this.subscribeToFeedFormat();
    this.subscribeToFeedFrequency();
    this.subscribeToZip();
    this.subscribeToDelivery();
    if (this.outboundFeedsValueLoaded && this.outboundFeedFormGroup) {
      this.subscribeFeedTypeValueChangeFromParent(this.outboundFeedFormGroup.getRawValue(), this.outboundFeedFormGroup);
    }
  }

  public getFeedType(): void {
    this.subscription.add(
      this.store.select(getOBFFeedTypeOptions).subscribe((feedTypeList) => {
        if (feedTypeList) {
          this.feedType = feedTypeList;
        }
      })
    );
  }

  public writeValue(obj: any): void {
    if (obj) {
      this.outboundFeedFormGroup.setValue(obj, { emitEvent: false });
    }
  }

  public registerOnChange(fn: any): void {
    this.outboundFeedFormGroup.valueChanges.subscribe(fn);
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public validate(): ValidationErrors {
    return this.outboundFeedFormGroup.valid ? null : { editFolder: false };
  }

  public registerOnValidatorChange?(fn: () => void): void {
    this.outboundFeedFormGroup.statusChanges.subscribe(fn);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public subscribeToFeedType(): void {
    this.subscription.add(
      this.outboundFeedFormGroup.controls.feedType.valueChanges.subscribe((feedType) => {
        this.feedFormatCtrl.reset();
        this.feedFrequencyCtrl.reset();
        this.outboundFeedFormGroup.controls.feedFrequency.reset();
        this.outboundFeedFormGroup.controls.delivery.reset();
        if (feedType) {
          const selectedFeedType = this.feedType.find((type) => type.value === feedType);
          if (selectedFeedType) {
            this.loadFeedFormat(selectedFeedType.value);
            if (
              selectedFeedType.value !== 'FT2' &&
              this.outboundFeedForm.contains('sendEmptyFile') &&
              this.outboundFeedFormGroup.controls?.sendEmptyFile !== undefined
            ) {
              this.outboundFeedFormGroup.controls?.sendEmptyFile.patchValue(null);
              this.outboundFeedFormGroup.removeControl('sendEmptyFile');
            } else {
              this.outboundFeedFormGroup.addControl('sendEmptyFile', this.sendEmptyFileCtrl);
              this.outboundFeedFormGroup.controls?.sendEmptyFile.patchValue(false);
            }
          }
        }
      })
    );
  }

  public loadFeedFormat(selectedFeedTypeValue: string): void {
    this.store.dispatch(loadReferenceByRefType(LegalEntityRefDataType.OBFFeedFormat, selectedFeedTypeValue));
    this.subscription.add(
      this.store
        .select(getRefDataByCodeOptions(LegalEntityRefDataType.OBFFeedFormat, selectedFeedTypeValue))
        .subscribe((feedFormatList) => {
          this.feedFormat = feedFormatList;
        })
    );
  }

  public subscribeToFeedFormat(): void {
    this.subscription.add(
      this.outboundFeedFormGroup.controls.feedFormat.valueChanges.subscribe((feedFormat) => {
        if (feedFormat) {
          this.feedFrequency = getFeedFrequency(feedFormat, this.hasCreateSFTPPermission);
          if (this.outboundFeedFormGroup.controls.feedFrequency.value !== null) {
            const availableFeedFormatValue = this.outboundFeedFormGroup.controls.feedFrequency.value.filter((item) =>
              this.feedFrequency.map(({ value }) => value).includes(item)
            );
            this.outboundFeedFormGroup.controls.feedFrequency.patchValue(availableFeedFormatValue);
            this.feedFrequencyCtrl.patchValue(availableFeedFormatValue);
          }
        }
      })
    );
  }

  public subscribeToZip(): void {
    // Set password validation error if Zip = true;
    this.subscription.add(
      this.outboundFeedFormGroup.controls.zip.valueChanges.subscribe((zip) => {
        if (zip) {
          this.outboundFeedFormGroup.addControl('zipPassword', this.zipPasswordCtrl);
          this.outboundFeedFormGroup.controls.zipPassword.updateValueAndValidity();
        } else {
          this.outboundFeedFormGroup.controls.zipPassword.updateValueAndValidity();
          if (this.outboundFeedFormGroup.contains('zipPassword')) {
            this.outboundFeedFormGroup.controls.zipPassword.patchValue(null);
            this.outboundFeedFormGroup.controls.zipPassword.markAsTouched();
          }
        }
      })
    );
  }

  public subscribeToFeedFrequency(): void {
    this.subscription.add(
      this.outboundFeedFormGroup.controls.feedFrequency.valueChanges.subscribe((feedFrequency) => {
        this.GetDeliveryValueFromFrequencyList(feedFrequency);
        this.outboundFeedFormGroup.controls.delivery.setValidators([
          Validators.required,
          this.sftpDeliveryValidate(feedFrequency)
        ]);
        if (this.outboundFeedFormGroup.controls.delivery.value !== null) {
          const availableDeliveryValue = this.outboundFeedFormGroup.controls.delivery.value.filter((item) =>
            this.delivery.map(({ value }) => value).includes(item)
          );
          this.outboundFeedFormGroup.controls.delivery.patchValue(availableDeliveryValue);
          this.deliveryCtrl.patchValue(availableDeliveryValue);
        }
      })
    );
  }

  public setEmailValidationInUpdateMode(): void {
    this.checkEmailValidation = true;
    this.outboundFeedFormGroup.controls.emailAddress.setValidators([Validators.required, this.emailValidateForm]);
    this.outboundFeedFormGroup.controls.emailAddress.updateValueAndValidity();
    this.outboundFeedFormGroup.controls.ccEmailAddress.setValidators([this.emailValidateForm]);
    this.outboundFeedFormGroup.controls.ccEmailAddress.updateValueAndValidity();
  }

  public setDirectoryValidationInUpdateMode(): void {
    this.checkDirectoryValidation = true;
    this.outboundFeedFormGroup.controls.directory.setValidators([Validators.required]);
    this.outboundFeedFormGroup.controls.directory.updateValueAndValidity();
  }

  public subscribeToDelivery(): void {
    this.subscription.add(
      this.outboundFeedFormGroup.controls.delivery.valueChanges.subscribe((delivery) => {
        if (delivery) {
          if (delivery.indexOf(OutboundFeedDelivery.EMAIL) === -1) {
            this.outboundFeedFormGroup.controls.emailAddress.clearValidators();
            this.outboundFeedFormGroup.controls.emailAddress.updateValueAndValidity();
            if (this.checkEmailValidation) {
              this.outboundFeedFormGroup.controls.emailAddress.markAsTouched();
              this.outboundFeedFormGroup.controls.ccEmailAddress.markAsTouched();
            }
          } else {
            this.outboundFeedFormGroup.controls.emailAddress.setValidators([
              Validators.required,
              this.emailValidateForm
            ]);
            this.outboundFeedFormGroup.controls.ccEmailAddress.updateValueAndValidity();
            this.outboundFeedFormGroup.controls.ccEmailAddress.setValidators([this.emailValidateForm]);
            this.outboundFeedFormGroup.controls.emailAddress.updateValueAndValidity();
          }
          if (delivery.indexOf(OutboundFeedDelivery.SFTP) === -1) {
            this.outboundFeedFormGroup.controls.directory.clearValidators();
            this.outboundFeedFormGroup.controls.directory.updateValueAndValidity();
            if (this.checkDirectoryValidation) {
              this.outboundFeedFormGroup.controls.directory.markAsTouched();
            }
          } else {
            this.outboundFeedFormGroup.controls.directory.setValidators([Validators.required]);
            this.outboundFeedFormGroup.controls.directory.updateValueAndValidity();
          }
        }
      })
    );
  }

  private GetDeliveryValueFromFrequencyList(feedFrequency) {
    if (feedFrequency) {
      const feedFrequencyAndDelivery = feedFrequency.map(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        (feedFrequencyValue: string) => `${this.outboundFeedFormGroup.controls.feedFormat.value}.${feedFrequencyValue}`
      );
      this.delivery = getDelivery(feedFrequencyAndDelivery, this.hasCreateSFTPPermission);
    }
  }

  public emailValidateForm: ValidatorFn = (control: AbstractControl): { [key: string]: any } | null => {
    if (control?.value) {
      // Allow user to provide mutliple email address, and selarated by commas
      const emails = control?.value?.split(',').map((e) => e.trim());
      const forbidden = emails.some((email) =>
        Validators.pattern(CONTACT_PATTERN_CONSTANTS.EMAIL_PATTERN)(new FormControl(email))
      );
      return forbidden
        ? { pattern: { requiredPattern: CONTACT_PATTERN_CONSTANTS.EMAIL_PATTERN, actualValue: control.value } }
        : null;
    }
    return null;
  };

  // SFTP delivery method is required if frequency contains hourly
  public sftpDeliveryValidate(feedFrequency: string): ValidatorFn {
    return (control: FormControl) => {
      let validation = null;
      if (control.value && feedFrequency) {
        const controlValue = control.value;
        if (
          Object.values(feedFrequency).indexOf(OutboundFeedFrequency.HOURLY) !== -1 &&
          Object.values(control.value).indexOf(OutboundFeedDelivery.SFTP) === -1
        ) {
          validation = {
            pattern: controlValue
          };
        }
      }
      return validation;
    };
  }

  public subscribeFeedTypeValueChangeFromParent(outboundFeedForm: any, outboundFeedFormGroup: any): void {
    if (outboundFeedForm?.feedType && this.feedFormat === null && this.outboundFeedsValueLoaded) {
      this.loadFeedFormat(outboundFeedForm.feedType);
      if (outboundFeedForm?.feedFormat) {
        this.feedFrequency = getFeedFrequency(outboundFeedForm?.feedFormat, this.hasCreateSFTPPermission);
      }
      if (outboundFeedForm?.feedFormat && outboundFeedForm?.feedFrequency) {
        this.GetDeliveryValueFromFrequencyList(outboundFeedForm.feedFrequency);
      }
      // Enable email validation when Update Outbound Feed email without changing devliery method
      if (
        outboundFeedForm?.delivery?.indexOf(OutboundFeedDelivery.EMAIL) > -1 &&
        outboundFeedFormGroup.status !== OutboundFeedFormStatus.DISABLED
      ) {
        this.setEmailValidationInUpdateMode();
      }

      // Enable delivery validation when Update Outbound Feed direcotry without changing devliery method
      if (
        outboundFeedForm?.delivery?.indexOf(OutboundFeedDelivery.SFTP) > -1 &&
        outboundFeedFormGroup.status !== OutboundFeedFormStatus.DISABLED
      ) {
        this.setDirectoryValidationInUpdateMode();
      }
    }
  }

  public showDeliveryFields(deliveryValue: string): boolean {
    return (
      this.outboundFeedFormGroup.controls.delivery?.value &&
      this.outboundFeedFormGroup.controls.delivery?.value?.indexOf(deliveryValue) > -1 &&
      this.delivery.length &&
      this.delivery.map(({ value }) => value).includes(deliveryValue)
    );
  }
}
