import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { STATIC_CONTENT_CONTEXT, STATIC_CONTENT_PAYLOAD } from '@inmarsat-itcloudservices/ui';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { staticContent } from '@app/app.constants';
import { IState } from '@app/shared-store';
import { getAccountDetails } from '@shared-store/account/account.selectors';
import { AddressFormComponent } from '@shared/components/address-form/address-form.component';
import * as AccountActions from '@shared-store/account/account.actions';
import { AddressType } from '@app/shared/models/address.model';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'inm-account-create-shipping-address',
  templateUrl: './account-create-shipping-address.component.html',
  styleUrls: ['./account-create-shipping-address.component.scss'],
  providers: [
    {
      provide: STATIC_CONTENT_CONTEXT,
      useValue: 'accounts.shipping-address-contact'
    },
    {
      provide: STATIC_CONTENT_PAYLOAD,
      useValue: staticContent
    }
  ]
})
export class AccountCreateShippingAddressComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('addressForm')
  public addressForm: AddressFormComponent;

  public legalEntityCode: string;

  public currentAccountNumber: string;

  public form = new FormGroup({
    commonAddress: new FormControl(null)
  });

  private readonly subscription = new Subscription();

  constructor(public bsModalRef: BsModalRef, private readonly store: Store<IState>) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.store.select(getAccountDetails).subscribe((accountDetails) => {
        if (accountDetails) {
          this.legalEntityCode = accountDetails.legalEntityCode;
          this.currentAccountNumber = accountDetails.accountNumber;
        }
      })
    );
  }

  public ngAfterViewInit(): void {
    this.addressForm.form.patchValue({
      type: AddressType.SHIPPING
    });
    this.addressForm.form.updateValueAndValidity();
  }

  public addAddress(): void {
    let payload: any;
    if (this.form.valid) {
      payload = this.form.value.commonAddress;
      payload = { ...payload, accountNumber: this.currentAccountNumber };
      this.store.dispatch(
        AccountActions.createShippingAddress(payload, this.currentAccountNumber, this.legalEntityCode)
      );
    } else {
      this.form.markAllAsTouched();
    }
  }

  public cancel(): void {
    this.bsModalRef.hide();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
