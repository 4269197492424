<form name="form" role="form" novalidate>
  <div class="site-details">
    <section [formGroup]="primaryForm">
      <h4>{{ '.title' | staticContent }}</h4>
      <div class="row">
        <inm-input-container [label]="'.name.label' | staticContent" [ngClass]="isEditing ? 'col-4' : 'col-6'">
          <input
            type="text"
            [placeholder]="'.name.placeholder' | staticContent"
            formControlName="name"
            class="inm-textinput__input"
            [attr.disabled]="disableAccoutName || null"
            [ngClass]="{ 'inm-disabled': disableAccoutName }"
          />
        </inm-input-container>

        <inm-input-container [label]="'.type.label' | staticContent" [ngClass]="isEditing ? 'col-4' : 'col-6'">
          <inm-tree-select
            [items]="siteTypes"
            [placeholder]="'.type.placeholder' | staticContent"
            formControlName="type"
          >
          </inm-tree-select>
          <inm-validation-message
            *ngIf="typeCtrl.invalid && (typeCtrl.dirty || typeCtrl.touched)"
            [control]="typeCtrl"
            [label]="'.type.label' | staticContent"
          >
          </inm-validation-message>
        </inm-input-container>

        <inm-input-container
          *ngIf="categoryOptions$ | async as categories"
          [label]="categoryLabel"
          [ngClass]="isEditing ? 'col-4' : 'col-6'"
        >
          <inm-tree-select
            [items]="categories"
            labelKey="label"
            valueKey="value"
            [placeholder]="categoryPlaceholder"
            formControlName="category"
          ></inm-tree-select>

          <inm-validation-message
            *ngIf="categoryCtrl.invalid && (categoryCtrl.dirty || categoryCtrl.touched)"
            [control]="categoryCtrl"
            [label]="categoryLabel"
          >
          </inm-validation-message>
        </inm-input-container>

        <inm-input-container
          *ngIf="subcategoryOptions$ | async as subcategories"
          [label]="subcategoryLabel"
          [ngClass]="isEditing ? 'col-4' : 'col-6'"
        >
          <inm-tree-select
            [items]="subcategories"
            labelKey="label"
            valueKey="value"
            [placeholder]="subcategoryPlaceholder"
            formControlName="subcategory"
          ></inm-tree-select>

          <inm-validation-message
            *ngIf="subcategoryCtrl.invalid && (subcategoryCtrl.dirty || subcategoryCtrl.touched)"
            [control]="subcategoryCtrl"
            [label]="subcategoryLabel"
          >
          </inm-validation-message>
        </inm-input-container>
      </div>
    </section>
    <ng-container *ngIf="primaryForm.valid">
      <section class="inm-secondary-qs" *ngFor="let section of secondaryQuestions">
        <h4>{{ section.title }}</h4>
        <div class="row">
          <inm-dynamic-control
            *ngFor="let question of section.questions"
            [question]="question"
            [formGroup]="secondaryForm"
          ></inm-dynamic-control>
        </div>
      </section>
    </ng-container>
  </div>
</form>
