import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FormBuilder, FormArray, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  STATIC_CONTENT_CONTEXT,
  STATIC_CONTENT_PAYLOAD,
  IReferenceOption,
  getStaticContent,
  IconName,
  IconColor,
  getOBFFeedTypeOptions
} from '@inmarsat-itcloudservices/ui';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  setupOutboundFeedPayload,
  convertOutboundFeedDataStructure,
  combineOutboundFeedWithSameFeedFormatAndType
} from '@app/outbound-feed/outbound-feed-helpers';
import { OutboundFeedFormStatus, MandatoryOutboundFeed } from '@app/shared/models/outbound-feed.model';
import { withLatestFrom } from 'rxjs/operators';
import { reject, difference } from 'ramda';
import { getOutboundFeedList } from '@app/shared-store/outbound-feed/outbound-feed.selectors';
import { ROUTES, staticContent } from '../../../app.constants';
import { IState } from '../../../shared-store';
import {
  getAccountDetails,
  getCreatedAccountState,
  getParentAccount,
  getOutboundFeedsByARAccount
} from '../../../shared-store/account/account.selectors';
import { getCleBaseDetail } from '../../../shared-store/cle/cle.selectors';
import { IAccountDetails, AccountGroup } from '../../../shared/models/account.model';
import * as AccountActions from '../../../shared-store/account/account.actions';

const ACCOUNT_CODE = getStaticContent('accounts.create.obf.account_code', staticContent);
const ACCOUNT_NAME = getStaticContent('accounts.create.obf.account_name', staticContent);
const CLE_CODE = getStaticContent('accounts.create.obf.cle_code', staticContent);
const CLE_NAME = getStaticContent('accounts.create.obf.cle_name', staticContent);
const TOOLIP_MESSAGE = getStaticContent('accounts.create.obf.tooltip_message', staticContent);

@Component({
  selector: 'inm-account-add-outbound-feed-step',
  templateUrl: './account-add-outbound-feed-step.component.html',
  styleUrls: ['./account-add-outbound-feed-step.component.scss'],
  providers: [
    {
      provide: STATIC_CONTENT_CONTEXT,
      useValue: 'accounts.create.obf'
    },
    {
      provide: STATIC_CONTENT_PAYLOAD,
      useValue: staticContent
    }
  ]
})
export class AccountAddOutboundFeedStepComponent implements OnInit, OnDestroy {
  public legalEntityCode: string;

  public legalEntityName: string;

  public arAccountNumber: string;

  public arAccountName: string;

  public accountName: string;

  public currentAcountNumber: string;

  public accountGroup: string;

  public parentAccountDetails: IAccountDetails;

  public outboundFeedFormStatus = OutboundFeedFormStatus;

  public accountCodeAndName = {
    isPostingAccount: false,
    accountInfo: {
      name: {
        label: null,
        key: null
      },
      code: {
        label: null,
        key: null
      }
    }
  };

  public form = this.fb.group({
    obfs: this.fb.array([])
  });

  public feedType: IReferenceOption[];

  private readonly subscription = new Subscription();

  public expand: boolean[] = [];

  public showOutboundFeedsByARAccount = false;

  public readonly iconNames = IconName;

  public readonly iconColors = IconColor;

  @Input()
  public outboundFeedValueForm;

  @Input('outboundFeedsValueLoaded')
  public outboundFeedsValueLoaded = false; // patch Outbound Feed form if obfs value loaded in reivew step

  @Input('allowDeselectAndAddMore')
  public allowDeselectAndAddMore = true; // Not allow deselect/add more obf in review step

  @Input('allowCancelAndNext')
  public allowCancelAndNext = true; // Not allow cancel/next obf in move account and add more bp account

  @Input('pullCurrentAccountOBF')
  public pullCurrentAccountOBF = false; // Only needed when user create Outbound Feed in BP account details page to remove duplicate record

  @Input('initialCheckAROBF')
  public initialCheckAROBF = true; // Only needed when user create Outbound Feed in BP account details page to remove duplicate record

  public allowedTypesExistingOutboundFeeds;

  public allowedTypesNewOutboundFeeds;

  public showErrorMessage = false;

  constructor(
    private readonly store: Store<IState>,
    private readonly router: Router,
    private readonly fb: FormBuilder
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.store.select(getAccountDetails).subscribe((accountDetail) => {
        if (accountDetail) {
          this.arAccountNumber = accountDetail.accountNumber;
          this.accountGroup = accountDetail.accountGroup;
          this.arAccountName = accountDetail.name;
          this.accountName = accountDetail.name;
          this.legalEntityCode = accountDetail.legalEntityCode;
        }
      })
    );

    this.subscription.add(
      this.store.select(getParentAccount).subscribe((parentAccount) => {
        if (parentAccount) {
          this.parentAccountDetails = parentAccount;
          this.arAccountName = this.parentAccountDetails.name;
          this.arAccountNumber = this.parentAccountDetails.accountNumber;
        }
      })
    );

    this.getCurrentAccountNumber();
    this.getCLE();
    this.getFeedTypeList();
    if (this.outboundFeedsValueLoaded && this.outboundFeedValueForm) {
      this.patchFormValue(this.outboundFeedValueForm);
    } else {
      this.addMoreOutboundFeed();
      this.pullCurrentAccountOBF ? this.getFilteredAROBFs() : this.getParentOBFs();
    }
    if (!this.initialCheckAROBF) {
      this.loadAROBFInAccountMove();
    }
  }

  public createOutboundFeed(): void {
    if (this.isFormValid()) {
      const obfARAccountNumber = this.getAccountCodeAndName().isPostingAccount
        ? this.currentAcountNumber
        : this.arAccountNumber;
      const payload = setupOutboundFeedPayload(
        this.form.getRawValue().obfs,
        this.feedType,
        this.currentAcountNumber,
        this.accountName,
        obfARAccountNumber,
        this.legalEntityCode
      );

      const inheritanceIds: string[] = Object.values(
        payload.filter((item) => item.inheritanceId).map((x) => x.inheritanceId as string)
      );
      const fullOBFs = payload
        .filter((item) => !item.inheritanceId)
        .concat(payload.filter((item) => item.inheritanceId)); // Order matters, create non-inheritacne obf first
      const inheritancePayload = {
        inheritanceIds: inheritanceIds,
        billingAccount: this.currentAcountNumber,
        accountName: this.accountName,
        arAccount: obfARAccountNumber
      };
      this.allowedTypesNewOutboundFeeds = combineOutboundFeedWithSameFeedFormatAndType(payload);
      if (this.toFindDuplicateOutboundFeed().length) {
        this.showErrorMessage = true;

        this.form?.markAllAsTouched();
      } else {
        this.showErrorMessage = false;
        this.store.dispatch(
          AccountActions.createOBFDuringAccountCreation({
            obfs: payload.filter((item) => !item.inheritanceId),
            inheritancePayload,
            fullOBFs
          })
        );
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  public isFormValid(): boolean {
    return this.form.disabled ? true : this.form.valid;
  }

  get obfs(): FormArray {
    return this.form.controls['obfs'] as FormArray;
  }

  public getFeedTypeList(): void {
    this.subscription.add(
      this.store.select(getOBFFeedTypeOptions).subscribe((feedTypeList) => {
        if (feedTypeList) {
          this.feedType = feedTypeList;
        }
      })
    );
  }

  public getCLE(): void {
    this.subscription.add(
      this.store.select(getCleBaseDetail).subscribe((baseDetails) => {
        if (baseDetails) {
          this.legalEntityCode = baseDetails.legalEntityCode;
          this.legalEntityName = baseDetails.details?.legalName;
        }
      })
    );
  }

  public getCurrentAccountNumber(): void {
    this.subscription.add(
      this.store.select(getCreatedAccountState).subscribe((createdAccountDetails) => {
        if (createdAccountDetails && createdAccountDetails.addAccountDetails) {
          this.currentAcountNumber = createdAccountDetails.accountNumber;
          this.accountGroup = createdAccountDetails?.addAccountDetails.accountGroup
            ? createdAccountDetails?.addAccountDetails.accountGroup
            : this.accountGroup;
          this.accountName = this.accountName ?? createdAccountDetails?.addAccountDetails?.name;
        }
      })
    );
  }

  public initOBF(): FormGroup {
    return this.fb.group({
      feedType: [null, Validators.required],
      feedFormat: [null, Validators.required],
      sendEmptyFile: null,
      feedFrequency: [null, Validators.required],
      zip: null,
      delivery: [null, Validators.required],
      directory: null,
      zipPassword: null,
      notes: null,
      emailAddress: null,
      ccEmailAddress: null,
      _id: null
    });
  }

  public addMoreOutboundFeed(): void {
    this.obfs.push(this.initOBF());
  }

  public deleteOutboundFeed(obfIndex: number): void {
    this.obfs.removeAt(obfIndex);
  }

  public cancel(): void {
    if (this.parentAccountDetails && this.parentAccountDetails?.accountNumber) {
      void this.router.navigate(['/', ROUTES.ACCOUNTS, this.parentAccountDetails.accountNumber], {
        queryParams: {
          parentId: this.legalEntityCode
        }
      });
    } else {
      void this.router.navigate(['/', ROUTES.CUSTOMERS, ROUTES.CLE, this.legalEntityCode]);
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public expandPanelAction(i: number): void {
    this.expand[i] = !this.expand[i];
  }

  public getPanelTitle(i: number): string {
    return getStaticContent('accounts.create.obf.sub_title', staticContent).replace('{i}', i + 1);
  }

  public getAccountCodeAndName(): Record<string, any> {
    this.accountCodeAndName = {
      isPostingAccount: false,
      accountInfo: {
        name: {
          label: ACCOUNT_NAME,
          key: this.arAccountName
        },
        code: {
          label: ACCOUNT_CODE,
          key: this.arAccountNumber
        }
      }
    };
    if (
      this.accountGroup === AccountGroup.INMA ||
      this.accountGroup === AccountGroup.INM7 ||
      this.accountGroup === AccountGroup.INDE ||
      (this.accountGroup === AccountGroup.INM1 && this.parentAccountDetails?.post === undefined)
    ) {
      this.accountCodeAndName = {
        isPostingAccount: true,
        accountInfo: {
          name: {
            label: CLE_NAME,
            key: this.legalEntityName
          },
          code: {
            label: CLE_CODE,
            key: this.legalEntityCode
          }
        }
      };
      return this.accountCodeAndName;
    }
    return this.accountCodeAndName;
  }

  private patchFormValue(value: any[] | any): void {
    const formLength =
      (this.form?.controls?.obfs as FormArray).controls?.filter(
        (x) => x.status === OutboundFeedFormStatus.VALID && x.touched && !x.pristine
      ).length ?? 0;
    // Delete invalid form, append copied OBFs after valid new OBFs
    const invalidFormIndex = this.form.controls?.obfs['controls'].reduce(
      (list, cur, i) => (cur.status === OutboundFeedFormStatus.INVALID ? list.concat(i) : list),
      []
    );
    if (invalidFormIndex.length) {
      invalidFormIndex.reverse().map((indexNumber) => this.deleteOutboundFeed(indexNumber));
    }

    value.forEach((newValue: any, index: number) => {
      this.obfs.insert(index + formLength, this.initOBF());
      this.obfs.at(index + formLength).patchValue(newValue);
      if (newValue.inheritanceId || this.showOutboundFeedsByARAccount) {
        this.obfs.at(index + formLength).disable();
      } else {
        // allow to update newly create Outbound Feed, but not formatType and feedFormat
        (this.obfs.at(index + formLength) as FormGroup).controls.feedType.disable();
        (this.obfs.at(index + formLength) as FormGroup).controls.feedFormat.disable({ emitEvent: false });
      }
    });
  }

  // Account must have credit note and invoice OBFs.
  public checkMandatoryOutboundFeedsValue(): boolean {
    return (
      this.form.getRawValue().obfs.some((vendor) => vendor['feedFormat'] === MandatoryOutboundFeed.INVOICE) &&
      this.form.getRawValue().obfs.some((vendor) => vendor['feedFormat'] === MandatoryOutboundFeed.CREDIT_NOTE)
    );
  }

  public getTooltipText(): string {
    if (
      (this.form.valid || this.form.status === OutboundFeedFormStatus.DISABLED) &&
      !this.checkMandatoryOutboundFeedsValue()
    ) {
      return TOOLIP_MESSAGE;
    }
  }

  // Inheritance function
  public getParentOBFs(): any {
    this.subscription.add(
      this.store.select(getOutboundFeedsByARAccount).subscribe((ARAccountOBFs) => {
        if (ARAccountOBFs.length && !this.getAccountCodeAndName().isPostingAccount) {
          this.showOutboundFeedsByARAccount = true;
          this.outboundFeedValueForm = convertOutboundFeedDataStructure(ARAccountOBFs, this.feedType);
          // load AR OBF during BP account creation as default
          if (!this.outboundFeedsValueLoaded) {
            this.obfs.clear();
            this.patchFormValue(this.outboundFeedValueForm);
          }
        }
      })
    );
  }

  // Filter out available AR level's Outbound Feed
  public getFilteredAROBFs(): any {
    this.subscription.add(
      // GetCurrentAccountOBFs
      this.store
        .select(getOutboundFeedList)
        .pipe(withLatestFrom(this.store.select(getOutboundFeedsByARAccount)))
        .subscribe(([currentAccountOBFS, ARAccountOBFs]) => {
          if (ARAccountOBFs.length) {
            /* Remove Outbound Feed already exist in the BP level
            if current bp account doesn't have OBFs and AR Account has OBFs, allow to copy
            if current bp account has BP, and AR account has OBFfs, needs filter out availale OBFS.

            if current BP account doesn't have obs and AR account doesn't have obfs, not allow to copy
            if current BP account has BP and AR account doesn't have OBFS, not allow to copy 
            */

            if (currentAccountOBFS.items.length === 0) {
              this.showOutboundFeedsByARAccount = true;
              this.outboundFeedValueForm = convertOutboundFeedDataStructure(ARAccountOBFs, this.feedType);
            } else {
              const getInheritanceIdList = currentAccountOBFS.items
                .filter((x) => x.inheritanceId)
                .map((x) => x.inheritanceId);
              const removeDuplicateOBF = (AROBF) => getInheritanceIdList.indexOf(AROBF._id) >= 0;
              const result = reject(removeDuplicateOBF, ARAccountOBFs);
              if (result.length) {
                this.showOutboundFeedsByARAccount = true;
                this.outboundFeedValueForm = convertOutboundFeedDataStructure(result, this.feedType);
              } else {
                this.showOutboundFeedsByARAccount = false;
              }
            }
          }
        })
    );

    // load AR OBF in BP account outbound feed creation as default
    if (this.outboundFeedsValueLoaded && this.outboundFeedValueForm) {
      this.obfs.clear();
      this.patchFormValue(this.outboundFeedValueForm);
    }
  }

  public RemoveElementFromObjectArray(key: number, objectArray: any): void {
    objectArray.forEach((value, index) => {
      if (value.id == key) objectArray.splice(index, 1);
    });
  }

  public isCopyParentOutboundFeeds(disableForm: boolean): void {
    if (!disableForm) {
      const indexArray = this.obfs.controls
        .reduce(function (prev, cur, index) {
          if (cur.status === OutboundFeedFormStatus.DISABLED || cur.status === OutboundFeedFormStatus.INVALID) {
            prev.push(index);
          }
          return prev;
        }, [])
        .reverse();
      indexArray.forEach((index) => this.deleteOutboundFeed(index));
      this.addMoreOutboundFeed();
    } else {
      this.showOutboundFeedsByARAccount = true;
      this.patchFormValue(this.outboundFeedValueForm);
    }
  }

  public loadAROBFInAccountMove(): void {
    this.subscription.add(
      this.store.select(getOutboundFeedsByARAccount).subscribe((ARAccountOBFs) => {
        if (ARAccountOBFs?.length) {
          this.showOutboundFeedsByARAccount = true;
          this.allowedTypesExistingOutboundFeeds = combineOutboundFeedWithSameFeedFormatAndType(ARAccountOBFs);
          // initial load new AR OBF to BP when user move the BP to new AR
          this.patchFormValue(this.outboundFeedValueForm);
        } else {
          this.showOutboundFeedsByARAccount = false;
          // Reset OBF form array, when user change the AR acccount number
          this.outboundFeedValueForm = null;
          this.obfs.clear();
          this.addMoreOutboundFeed();
        }
      })
    );
  }

  public toFindDuplicateOutboundFeed(): any[] {
    let duplicates = [];
    // check if user creates same outboundFeed
    Object.keys(this.allowedTypesNewOutboundFeeds).forEach((key) => {
      if (this.allowedTypesNewOutboundFeeds[key].length > 1) {
        const combineDuplicateOutboundFeed = (
          Array.from(new Set(this.allowedTypesNewOutboundFeeds[key].map(JSON.stringify))) as any
        ).map(JSON.parse);

        duplicates =
          !duplicates?.length && combineDuplicateOutboundFeed.length === this.allowedTypesNewOutboundFeeds[key].length
            ? []
            : combineDuplicateOutboundFeed;
      }
      // check if the outboundFeed already exist in the system
      if (
        !duplicates?.length &&
        this.allowedTypesExistingOutboundFeeds &&
        this.allowedTypesExistingOutboundFeeds[key] !== undefined
      ) {
        duplicates =
          !duplicates?.length &&
          difference(this.allowedTypesNewOutboundFeeds[key], this.allowedTypesExistingOutboundFeeds[key]).length ===
            this.allowedTypesNewOutboundFeeds[key].length
            ? []
            : this.allowedTypesNewOutboundFeeds[key];
      }
    });
    return duplicates;
  }
}
