import { Action, createReducer, on } from '@ngrx/store';
import { IChangeRequest, IChangeRequestEntityType } from '@app/shared/models/change-request.model';
import { IChangeRequestQuery } from '../../shared/models/change-request.model';
import { reset } from './change-request.actions';
import * as ChangeRequestActions from './change-request.actions';

export interface IChangeRequestState {
  changeRequestDetails: any;
  list: {
    loaded: boolean;
    items: IChangeRequest[];
    totalCount: number;
    query: IChangeRequestQuery;
  };
  requestTypeAndValue: {
    requestOptionValue: string;
    requestEntityType: IChangeRequestEntityType;
    requestId: string;
    reSubmitPayload: any;
  };
  actionItems: {
    requester: number;
    pendingApprovalAssignedCR: number;
    myIncompleteCount: number;
    myDraftCount: number;
  };
}

export const initialState: IChangeRequestState = {
  changeRequestDetails: null,
  list: {
    loaded: true,
    items: [],
    totalCount: 0,
    query: null
  },
  requestTypeAndValue: null,
  actionItems: {
    requester: 0,
    pendingApprovalAssignedCR: 0,
    myIncompleteCount: 0,
    myDraftCount: 0
  }
};

export const changeRequestReducer = createReducer(
  initialState,
  on(ChangeRequestActions.loadChangeRequest, (state) => ({
    ...state,
    list: {
      loaded: false,
      items: [],
      totalCount: 0,
      query: null
    }
  })),
  on(ChangeRequestActions.loadChangeRequestSuccess, (state, { response }) => ({
    ...state,
    list: {
      loaded: true,
      items: response ? response.assets : null,
      totalCount: response ? response.count : null,
      query: response ? response.query : null
    }
  })),
  on(ChangeRequestActions.detailLoad, (state) => ({
    ...state,
    changeRequestDetails: initialState.changeRequestDetails
  })),
  on(ChangeRequestActions.detailLoadSuccess, (state, { response, getAssociatedCLE, getAssociatedAccount }) => ({
    ...state,
    changeRequestDetails: {
      changeRequest: response,
      associatedCLE: getAssociatedCLE ? getAssociatedCLE : null,
      associatedAccount: getAssociatedAccount ? getAssociatedAccount : null
    }
  })),
  on(
    ChangeRequestActions.openChangeRequestModal,
    (
      state,
      { option, requestEntityType, reSubmitPayload, changeRequestId, accountNeedAddOBFsMessage, AccountAction }
    ) => ({
      ...state,
      requestTypeAndValue: {
        requestOptionValue: option,
        requestEntityType: requestEntityType,
        reSubmitPayload: reSubmitPayload,
        requestId: changeRequestId,
        accountNeedAddOBFsMessage: accountNeedAddOBFsMessage,
        AccountAction: AccountAction
      }
    })
  ),

  on(ChangeRequestActions.resetChangeRequestModal, (state) => ({
    ...state,
    requestTypeAndValue: initialState.requestTypeAndValue
  })),
  on(reset, () => initialState),
  on(ChangeRequestActions.loadActionItems, (state) => ({
    ...state,
    actionItems: initialState.actionItems
  })),
  on(ChangeRequestActions.loadActionItemsSuccess, (state, { response }) => ({
    ...state,
    actionItems: {
      requester: response.requester,
      pendingApprovalAssignedCR: response.pendingApprovalAssignedCR,
      myIncompleteCount: response.myIncompleteCount,
      myDraftCount: response.myDraftCount
    }
  })),
  on(ChangeRequestActions.getAcitiveChildAccountsSuccess, (state, { response }) => ({
    ...state,
    changeRequestDetails: {
      ...state.changeRequestDetails,
      associatedActiveChildAccounts: response
    }
  })),
  on(ChangeRequestActions.resetChangeRequestDetail, (state) => ({
    ...state,
    changeRequestDetails: initialState.changeRequestDetails
  }))
);

export function reducer(state: IChangeRequestState, action: Action): IChangeRequestState {
  return changeRequestReducer(state, action);
}
