import { getStaticContent, LegalEntityRefDataType, GenericRefDataType } from '@inmarsat-itcloudservices/ui';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import enJson from '../assets/i18n/en.json';

export const UI_DATE_FORMAT = 'DD-MMM-YYYY';
export const DEFAULT_ISO_COUNTRY_CODE = 'GB';
export const DEFAULT_PAGE_SIZE = 15;
export const OKTA_TOKEN_KEY = 'cmd-okta-token';
export const SMALL_SCREEN_MINIMUM_WIDTH = 768;
export const DIALOG_MODAL_CLASS = 'inm-modal-fill';

export const staticContent = enJson;

/**
 * Bs Datepicker config
 */
export const DATE_PICKER_CONFIG: Partial<BsDatepickerConfig> = {
  rangeInputFormat: UI_DATE_FORMAT,
  dateInputFormat: UI_DATE_FORMAT,
  containerClass: 'theme-blue',
  displayMonths: 1
};

export const ROUTES = {
  // HOME
  LOGOUT: 'logout',
  ROOT: '',

  // CHILDREN
  CHILDREN: 'children',
  CHILD1: 'child1',
  CHILD2: 'child2',

  // PROFILE
  PROFILE: 'profile',

  // CUSTOMERS
  CUSTOMERS: 'customers',
  CORPORATE_GROUPS: 'corporate-groups',
  CORPORATE_GROUPS_DETAILS: ':legalEntityCode',
  CREATE_CORPORATE_GROUP: 'add-corporate-group',
  CLE: 'cle',
  CLE_DETAILS: ':legalEntityCode',
  CREATE_CLE: 'add-customer-legal-entity',

  // SALES
  SALES: 'sales',
  CREATE_SALES_TEAM: 'add-sales-team',
  SALES_TEAM_DETAIL: ':id',

  // ACCOUNT
  ACCOUNTS: 'accounts',
  ACCOUNT_DETAILS: ':accountNumber',
  CREATE_ACCOUNT: 'create-account',
  CLONE_ACCOUNT: 'clone-account',

  // SITE
  SITES: 'sites',
  SITE_DETAILS: ':siteId',
  CREATE_SITE: 'create-site',

  // HELPER ROUTES
  EXTERNAL: 'external-link/:destination',

  // ERROR
  ERROR: 'error',
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  NOT_FOUND: `error?code=page_not_found&description=${getStaticContent('errors.page_not_found', staticContent)}.`,

  // TERMINAL GROUPS
  TERMINAL_GROUPS: 'terminal-groups',
  TERMINAL_GROUP_DETAILS: ':terminalGroupId',

  // BULK UPDATES
  BULK_UPDATE: 'bulk-update',
  BULK_UPDATE_AUDIT: 'audit',

  // CHANGE REQUEST
  CHANGE_REQUEST: 'change-request',
  CHANGE_REQUEST_DETAILS: ':requestId',

  // REFERENCE DATA
  REFERENCE_DATA: 'reference-data',

  // OutboundFeed
  OUTBOUND_FEED: 'outbound-feeds',
  OUTBOUNDFEED_DETAILS: ':obfId',

  HIERARCHY: 'hierarchy'
};

export const MODAL_CONFIGURATION = {
  ignoreBackdropClick: true,
  class: 'modal-dialog-centered inm-modal-auto',
  animated: false
};

export const DEFAULT_SUB_TABLE_PAGE_SIZE = 5;

export const SITE_PAGE = 'sitePage';

export const CONTRACT_MANAGEMENT_PAGE = 'contractPage';

export const ACCOUNT_PAGE = 'accountPage';

export const CHILD_ACCOUNT_PAGE = 'childAccountPage';

export const OUTBOUND_FEED_PAGE = 'outboundFeedPage';

export const CHANGE_REQUEST_PAGE = 'changeRequestPage';

export const SALES_ORG = 'salesOrgPage';

export const PRICING_PAGE = 'pricingPage';

export const CLE_PAGE = 'clePage';

export const CONTACT_PATTERN_CONSTANTS = {
  EMAIL_PATTERN: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  PHONE_PATTERN: /^(?=.{3,15}$)[+]?[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
  NAME_PATTERN: /^[a-zA-Z \u2019\u0027\u2010\u002D]*$/
};

export const VAT_NUMBER_PATTERN =
  /^(ATU[0-9]{8}|BE[01][0-9]{9}|BG[0-9]{9,10}|HR[0-9]{11}|CY[A-Z0-9]{9}|CZ[0-9]{8,10}|DK[0-9]{8}|EE[0-9]{9}|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|DE[0-9]{9}|EL[0-9]{9}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LV[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SK[0-9]{10}|SI[0-9]{8}|ES[A-Z]([0-9]{8}|[0-9]{7}[A-Z])|SE[0-9]{12}|GB([0-9]{9}|[0-9]{12}|GD[0-4][0-9]{2}|HA[5-9][0-9]{2}))$/;

export const CREDIT_RATING_PATTERN = /^(?=.?[A-Z])(?=.?[0-9]).{2,4}$/;

export const APP_REF_DATA_TYPES_CUSTOMER = [
  LegalEntityRefDataType.CreditRegion,
  LegalEntityRefDataType.Tier,
  LegalEntityRefDataType.CreditStatus,
  LegalEntityRefDataType.PricingTier,
  LegalEntityRefDataType.PricingRule,
  LegalEntityRefDataType.ServiceLevelAgreement,
  LegalEntityRefDataType.CustomerType,
  LegalEntityRefDataType.CollectorCode,
  LegalEntityRefDataType.SalesOrg,
  LegalEntityRefDataType.AircraftClass,
  LegalEntityRefDataType.VesselMarket,
  LegalEntityRefDataType.PaymentTerms,
  LegalEntityRefDataType.PaymentMethod,
  LegalEntityRefDataType.OBFFeedType,
  LegalEntityRefDataType.InvoiceReportLevel,
  LegalEntityRefDataType.InvoicePreference,
  LegalEntityRefDataType.DigitalCollection
];

export const APP_REF_DATA_TYPES_CORP = [
  GenericRefDataType.Currency,
  GenericRefDataType.Prefix,
  GenericRefDataType.Suffix,
  GenericRefDataType.Medium,
  GenericRefDataType.Airlines
];

export const APP_REF_DATA_TYPES_FULL = [GenericRefDataType.Country, GenericRefDataType.AirportCode];

export const APP_REF_DATA_TYPES_SITE = [LegalEntityRefDataType.VesselSubType, LegalEntityRefDataType.LandType];
